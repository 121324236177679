import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CurrencyPipe } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { AppInfoService } from 'src/app/shared/services';
import { ContributionsService } from 'src/app/Contributions/employers/service/contributions.service';

import { InvoiceModel } from '../../database/models/invoiceModel';
import { InvoiceState} from '../../database/state/InvoiceState';
import {select, Store} from '@ngrx/store';
import {InvoiceService} from '../../Contributions/employers/service/invoice.service';
import { ContributionStorageService } from 'src/app/Contributions/employers/service/contribution-storage.service';
import { Http } from '@angular/http';
import { EncryptionService } from 'src/app/shared/services/encryption.service';
import { SmsService } from 'src/app/shared/services/sms.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-shared-class',
  templateUrl: './shared-class.component.html',
  styleUrls: ['./shared-class.component.scss']
})
export class SharedClassComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  privilegesWithoutAuth = [];
  // varibale declaration
  caih = '0';
  verificationForm;
  registrationTypesWithoutAuth = [];
  securityQuestions: any;
  randomSecurityQuestions = [];
  registrationNeedsApproval = false;
  waitForApproval = [];
  noWaitForApproval = [];
  waitForApprovalRoutes = [];
  noWaitForApprovalRoutes = [];
  phoneTypeIds: any;
  tabIndex = 0; // This Variable is very important in controlling tabs on homeComponent.html
  currentData: any;
  disableEnableTextSwitcher: string;
  hideSaveButton = false;
  hideButton = false;
  dialogHeight = 350;
  clearance_request_id: any;
  today = new Date();
  payment_detailscol: string = 'col-lg-6';
  is_showdemoninationdetails: any = false;

  tabPanelHeight = 69;
  datagridHeight = 67;
  pageSize = 5;
  allowedPageSises = [];

  onBillTransactionWidth: number = 65;
  hideprintBtn: boolean = false;
  control_number: any;
  denominationData: any;
  amountBalanceChange: any;
  total_amountdenominations: number = 0;
  me = this;
  total_amount: number = 0;
  gateway_code: any;
  currency: any;
  gateway_receipt_number: any;
  payment_option: any;
  paymentCode: number;
  receipt_number: any;
  psp_reference_number: any;
  amount: any;
  description: any;
  sp_account_number: any;
  date: any;
  created_by: any;
  sp_code: any;
  sp_name: any;
  customer_name: any;
  status_message: any;
  previewIshiddenField: boolean = true;
  title: string;
  model_name: string;
  data: any = {};
  previewBillsFrm: FormGroup;
  previewBillsWin: boolean = false;
  record_data: any;
  // variables defination
  paramswinpnl: boolean = false;
  paramwinfrm: FormGroup;
  endpointcall: string;
  paramsDataSets: any;
  param_id: any;
  params_actions: any;
  tillAllowed: any;
  authAllowed: any;
  needApproval: any;
  registrationType: any;
  status: any;
  response: any;
  userStatusDta: any;
  branchesDta: any;
  accountTypesData: any;
  currencyData: any;
  gatewayData: any;
  userRolesDta: any;
  rolesList: any;
  showNextButton = true;
  securityQnInputReadOnly = false;

  constructor(
    public store: Store<{ invoice: InvoiceState }>,
    public utilities: UtilitiesService,
    public spinner: SpinnerVisibilityService,
    public toastr: ToastrService,
    public viewRef: ViewContainerRef,
    public router: Router,
    public route: ActivatedRoute,
    public authService: AuthService,
    public observer: BreakpointObserver,
    public appInfo: AppInfoService,
    public contributionService: ContributionsService,
    public invoiceService: InvoiceService,
    public invoiceListStorageService: ContributionStorageService,
    public http: Http,
     public fb: FormBuilder,
    public securityService: EncryptionService,
    public smsService: SmsService,
    public storageService: StorageService
  ) {
    this.tillAllowed = [
      {
        id: 1,
        text: 'Yes'
      },
      {
        id: 2,
        text: 'No'
      }
    ];
    this.authAllowed = [
      {
        id: 1,
        text: 'Yes'
      },
      {
        id: 0,
        text: 'No'
      }
    ];
    this.registrationType = [
      {
        id: 1,
        text: 'Local Registration'
      },
      {
        id: 2,
        text: 'Self Registration'
      },
      {
        id: 3,
        text: 'Local or Self Registration'
      }
    ];
    this.needApproval = [
      {
        id: 1,
        text: 'Yes'
      },
      {
        id: 0,
        text: 'No'
      }
    ];
    this.status = [
      {
        id: 1,
        text: 'Enabled'
      },
      {
        id: 0,
        text: 'Disabled'
      }
    ];
    this.params_actions = [
      {
        text: 'Actions',
        icon: 'bulletlist',
        type: 'default',
        items: [
          {
            text: 'Edit Record',
            icon: 'edit',
            action: 'edit'
          },
          {
            text: 'Disable/Enabled Record',
            icon: 'repeat',
            action: 'disabled'
          },
          {
            text: 'Delete Record',
            icon: 'clear',
            action: 'delete'
          }
        ]
      }
    ];

    this.billsprams_actions = [
      {
        text: 'Actions',
        icon: 'bulletlist',
        type: 'default',
        items: [
          {
            text: 'Receive Payment',
            icon: 'repeat',
            action: 'receive_payment'
          }
        ]
      }
    ];



    // reactive forms
    this.paramwinfrm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([])),
      is_enabled: new FormControl('', Validators.compose([])),
      id: new FormControl('', Validators.compose([]))
    });
    this.billValidationFrm = new FormGroup({
      control_number: new FormControl(
        '',
        Validators.compose([Validators.required])
      )
    });
    this.billValidationDetailsFrm = new FormGroup({
      description: new FormControl('', Validators.compose([])),
      gateway_ref_number: new FormControl('', Validators.compose([])),
      channel_ref_number: new FormControl('', Validators.compose([])),
      control_number: new FormControl('', Validators.compose([])),
      credit_account: new FormControl('', Validators.compose([])),
      amount: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([])),
      payer_name: new FormControl('', Validators.compose([])),
      payer_mobile: new FormControl('', Validators.compose([])),
      payer_email: new FormControl('', Validators.compose([])),
      gateway_name: new FormControl('', Validators.compose([])),
      sp_name: new FormControl('', Validators.compose([])),
      sp_code: new FormControl('', Validators.compose([])),
      due_date: new FormControl('', Validators.compose([])),
      bankCode: new FormControl('', Validators.compose([])),
      tin: new FormControl('', Validators.compose([])),
      service_code: new FormControl('', Validators.compose([])),
      psp_request_id: new FormControl('', Validators.compose([])),
      gateway_request_id: new FormControl('', Validators.compose([])),
      payment_option: new FormControl('', Validators.compose([])),
      invoice_number: new FormControl('', Validators.compose([])),
      invoice_date: new FormControl('', Validators.compose([])),
      receipt_number: new FormControl('', Validators.compose([])),
      receipt_date: new FormControl('', Validators.compose([])),
      id: new FormControl('', Validators.compose([]))
    });

    this.billClearanceCashTransaction = new FormGroup({
      control_number: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      validation_response_id: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      id: new FormControl('', Validators.compose([Validators.required])),
      amount: new FormControl('', Validators.compose([Validators.required])),
      mobile: new FormControl('', Validators.compose([Validators.required])),
      payment_mode: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      email: new FormControl('', Validators.compose([Validators.required])),
      debit_account: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([Validators.required])),
      depositor_name: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      timestamp: new FormControl('', Validators.compose([])),
      is_showdemoninationdetails: new FormControl('', Validators.compose([])),
      denominations: new FormControl('', Validators.compose([]))
    });

    this.accountValidationFrm = new FormGroup({
      account_no: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      account_name: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([])),
      balance: new FormControl('', Validators.compose([]))
    });
    this.previewBillsFrm = new FormGroup({
      clearance_request_id: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      date: new FormControl('', Validators.compose([Validators.required])),
      gateway_code: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      sp_account_number: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      sp_code: new FormControl('', Validators.compose([Validators.required])),
      sp_name: new FormControl('', Validators.compose([Validators.required])),
      customer_name: new FormControl('', Validators.compose([])),
      control_number: new FormControl('', Validators.compose([])),
      currency: new FormControl('', Validators.compose([])),
      amount: new FormControl('', Validators.compose([])),
      receipt_number: new FormControl('', Validators.compose([])),
      gateway_receipt_number: new FormControl('', Validators.compose([])),
      description: new FormControl('', Validators.compose([])),
      payment_option: new FormControl('', Validators.compose([])),
      created_by: new FormControl('', Validators.compose([]))
    });
  }
  billValidationFrm: FormGroup;
  accountValidationFrm: FormGroup;
  billInformationData: any;
  billValidationDetailsFrm: FormGroup;
  onCashtrnsactionWin: boolean;

  rejected_billsctr: string = '0';
  cleared_billsctr: string = '0';
  pending_billsctr: string = '0';

  rejected_bills: any;
  cleared_bills: any;
  pending_bills: any;
  all_bills: any;
  failedcbstransaction_bills: any;
  cbstransaction_bills: any;

  billsprams_actions: any;
  billClearanceCashTransaction: FormGroup;
  isFullAmount: boolean;
  payment_transactiondta: any;
  onBankTransfertrnsactionWin: any;
  isAccountVerified: boolean = true;
  paymentOptions: string;
  systemNavigationDta: any;
  userAccessLevelDta: any;
  timeTillNextRefreshInSeconds: number = 5000;
  modelName = 'settings/get';

  cash = 301;
  transfer = 302;
  tiss = 303;
  settingData = {
    setting_id: '1021'
  };
  ngOnInit() {}

  onGetCaihData() {
    this.caih = sessionStorage.getItem(AppSettings.CAIH);
  }

  //  set automatic refresh of data in interval of time
   setDataGridRefreshTimeInterval() {
  }
  // method called to close the pop-up dialog when close button is clicked
onPopUpClose() {
    this.previewBillsWin = false;
  }
  // method called when data-table row is clicked
onBillDoubleClickDetails(e, is_hidden) {
    this.previewIshiddenField = true;
    this.hideprintBtn = is_hidden;
    const bill_data = e.data;
    this.previewBillsWin = true;
    this.previewBillsFrm.patchValue(bill_data);
    this.clearance_request_id = bill_data.clearance_request_id;
    this.gateway_code = bill_data.gateway_code;
    this.currency = bill_data.currency;
    this.control_number = bill_data.control_number;
    this.gateway_receipt_number = bill_data.gateway_receipt_number;
    this.payment_option = bill_data.payment_option;
    this.receipt_number = bill_data.receipt_number;
    this.psp_reference_number = bill_data.psp_reference_number;
    this.amount = bill_data.amount;
    this.description = bill_data.description;
    this.sp_account_number = bill_data.sp_account_number;
    this.date = bill_data.created_at;
    this.created_by = bill_data.created_by;
    this.sp_code = bill_data.sp_code;
    this.sp_name = bill_data.sp_name;
    this.customer_name = bill_data.customer_name;
    this.status_message = bill_data.status_message;
    this.gateway_code = bill_data.gateway_code;
  }

// datagrid header components configuration
onBillssToolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          type: 'default',
          icon: 'help',
          hoverStateEnabled: false,
          hint: 'Click on the rows to Preview & Print Bills Details'
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: refresh_action.bind(this)
        }
      }
    );
  }
sharedOnToolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: refresh_action.bind(this)
        }
      }
    );
  }
// datagrid header components configuration
onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add',
          type: 'default',
          icon: 'fa fa-plus',
          onClick: this.addNewParameter.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.refreshDataGrid.bind(this)
        }
      }
    );
  }
  // method to refresh datagrid instance
refreshDataGrid() {
    this.onGetParamsdetails();
  }

// pop up form presets for insert action
addNewParameter() {
    this.dialogHeight = 350;
    this.paramwinfrm.reset();
    this.paramswinpnl = true;
    this.showNextButton = true;
    this.securityQnInputReadOnly = false;
    this.hideSaveButton = true;
    this.hideButton = false;
  }
// customize the pop up form details
previewDetails(e, obj) {
    this.dialogHeight = 420;
    this.hideSaveButton = false;
    this.hideButton = true;
    this.showNextButton = false;
    this.securityQnInputReadOnly = true;
    this.currentData = e.data;
    this.disableEnableTextSwitcher =
      obj === 'User'
        ? e.data.status === 0
          ? `Enable ${obj}`
          : `Disable ${obj}`
        : e.data.is_enabled === 0
        ? `Enable ${obj}`
        : `Disable ${obj}`;
    if (this.model_name === 'navigations') {
      this.paramwinfrm.get('navigation_id').setValue(e.data.id);
    }
    if (this.model_name === 'settings') {
      this.paramwinfrm.get('settingId').setValue(e.data.id);
    }
    if (this.model_name === 'roles') {
      this.paramwinfrm.get('roleId').setValue(e.data.id);
    }
    if (this.model_name === 'priviledges') {
      this.paramwinfrm.get('priviledgeId').setValue(e.data.id);
    }
    if (this.model_name === 'questions') {
      this.paramwinfrm.get('questionId').setValue(e.data.id);
      this.disableEnableTextSwitcher = e.data.status === 0 ? `Enable ${obj}` : `Disable ${obj}`;
    }
    if (this.model_name === 'accounts') {
      this.paramwinfrm.get('account_id').setValue(e.data.id);
      this.disableEnableTextSwitcher = e.data.status === 0 ? `Enable ${obj}` : `Disable ${obj}`;
    }
    this.paramwinfrm.patchValue(e.data);
    this.paramswinpnl = true;
  }

funcSetCheckBoxValue(value, panel) {
    let checkbox_value = false;
    if (value == 1) {
      checkbox_value = true;
    }
    this.paramwinfrm.get(panel).setValue(checkbox_value);
  }
funcGetCheckBoxValue(value, panel) {
    let checkbox_value = 0;
    if (value) {
      checkbox_value = 1;
    }
    this.paramwinfrm.get(panel).setValue(checkbox_value);
  }
// set the widget width
funcpopWidth(percentage_width) {
    if (window.innerWidth > 800) {
      return (window.innerWidth * percentage_width) / 100;
    } else {
      return window.innerWidth - 50;
    }
  }
// set the widget height
funcpopHeigt(height) {
    return (window.innerHeight * height) / 100;
  }

// redirect to the transactions grid specific tab
funLinkTab(index, route) {
    if (route === '/home') {
      if (index === 0) {
        this.tabIndex = 0;
      } else if (index === 1) {
        this.tabIndex = 1;
      } else if (index === 2) {
        this.tabIndex = 2;
      }
    } else {
      if (index === 0) {
        this.router.navigate(['/home'], { queryParams: { tabIndex: 0 } });
        this.tabIndex = 0;
      } else if (index === 1) {
        this.router.navigate(['/home'], { queryParams: { tabIndex: 1 } });
        this.tabIndex = 1;
      } else if (index === 2) {
        this.router.navigate(['/home'], { queryParams: { tabIndex: 2 } });
        this.tabIndex = 2;
      }
    }
    return this.tabIndex;
  }
  // controls the number of rows to display
dataGridPagerController() {
    if (
      window.innerHeight < 800 &&
      !(window.innerWidth >= 1500 && window.innerWidth <= 1600)
    ) {
      return 7;
    } else if (
      window.innerWidth >= 1500 &&
      window.innerWidth <= 1600 &&
      window.innerHeight <= 800
    ) {
      return 10;
    } else if (window.innerHeight >= 800 && window.innerHeight < 900) {
      return 10;
    } else {
      return 15;
    }
  }
// controls the PageSizeSelector
allowedPageSizeController() {
    if (window.innerHeight < 800) {
      return [];
    } else if (window.innerHeight >= 800 && window.innerHeight < 900) {
      return [5, 10];
    } else {
      return [5, 10, 15];
    }
  }
// controls the number of rows to display for other datagrids apart from home's ones
otherdataGridPagerController() {
    if (
      window.innerHeight < 800 &&
      !(window.innerWidth >= 1500 && window.innerWidth <= 1600)
    ) {
      return 10;
    } else if (
      window.innerWidth >= 1500 &&
      window.innerWidth <= 1600 &&
      window.innerHeight <= 800
    ) {
      return 15;
    } else if (window.innerHeight >= 800 && window.innerHeight < 870) {
      return 15;
    } else if (window.innerHeight >= 870 && window.innerHeight < 900) {
      return 16;
    } else if (window.innerWidth >= 2000 ) {
      return 23;
    } else if (window.innerWidth >= 1900 ) {
      return 21;
    } else {
      return 19;
    }
  }

// controls the PageSizeSelector for other datagrids apart from home's ones
otherAllowedPageSizeController() {
  if (window.innerHeight < 800) {
    return [];
  } else if (window.innerHeight >= 800 && window.innerHeight < 870) {
    return [5, 10, 15];
  } else if (window.innerHeight >= 870 && window.innerHeight < 900) {
    return [5, 10, 16];
  } else if (window.innerWidth >= 1900 ) {
    return [5, 10, 15, 21];
  } else if (window.innerWidth >= 2000 ) {
    return [5, 10, 15, 23];
  } else {
    return [5, 10, 19];
  }
}

observerCall() {
     // Controls the datagrid height and width on screen size change detection
     const widthHeightController = () => {
      this.pageSize = this.otherdataGridPagerController();
      this.allowedPageSises = this.otherAllowedPageSizeController();
    };
     this.observer.observe(['(max-width: 1400px)', '(max-height: 800px)']).subscribe(result => {
      const isMatched = Object.values(result.breakpoints);
      if (isMatched[0] && isMatched[1]) {
          // this.tabPanelHeight = 56;
          this.datagridHeight = 57;
          widthHeightController();
        } else if ((window.innerWidth >= 1500 && window.innerWidth <= 1600) && window.innerHeight <= 800 ) {
          // this.tabPanelHeight = 59;
          this.datagridHeight = 54;
          widthHeightController();
        } else {
          // this.tabPanelHeight = 69;
          this.datagridHeight = 67;
          widthHeightController();
        }
    });
  }

onGetParamsdetails() {
    if (this.model_name == 'currency/denominations') {
      this.data = {
        currency_code: 'ALL'
      };
    }
    this.utilities.postServiceCall(this.data, this.model_name).subscribe(
      response => {
        const data = response.json();
        this.paramsDataSets = data.data;
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  fetchRequestTypes(requestType) {
    const data = {
      requestType: requestType
    };
    this.spinner.show();
    this.utilities.postServiceCall(data, this.modelName).subscribe(
      response => {
        const data = response.json();
        if (data.code == 2000) {
          this.paramsDataSets = data.data;
        } else {
          this.toastr.error(data.message);
        }
        this.spinner.hide();
      },
      error => {
        this.toastr.error('Error Occured while processing the request');
        this.spinner.hide();
      }
    );
  }

onGetStatusesParamsdetails(model, dataSet) {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.userStatusDta = res.data.records;
      },
      error => {

      }
    );
  }
onGetSystemNavParamsdetails() {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, 'navigations').subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.systemNavigationDta = res.data;
      },
      error => {
      }
    );
  }
onGetuserAccessLevelParamsdetails() {
    const data = {};

    this.spinner.show();
    this.utilities.postServiceCall(data, 'access/levels').subscribe(
      response => {
        this.spinner.hide();
        const res = response.json();
        this.userAccessLevelDta = res.data.records;
      },
      error => {
      }
    );
  }
  onGetAdditionalSupportingParamsDetails(endPoint) {

   const data = {};
   this.utilities.postServiceCall(data, endPoint).subscribe(
    response => {
      this.spinner.hide();
      const res = response.json();
      if (this.model_name == 'registration' || this.model_name == 'users') {
       this.securityQuestions = res.data;
       for (let index = 0; index < this.securityQuestions.length; index++) {
        // stops the iteration when index reachs 6
        if (index === 6 ) {
        break;
        }
        // get six random security questions
        const randomIndex = Math.floor(Math.random() * this.securityQuestions.length) + 0;
        this.randomSecurityQuestions.push(this.securityQuestions[randomIndex]);
      }
      }
      },
    error => {
    }
  );
  }
  //
onGetOtheRolesdetails(model, dataSet) {
    const data = {};
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        const res = response.json();
        this.userRolesDta = res.data;
        for (var i = 0; i<res.data.length; i++){
          if (res.data[i].auth == 0) {
            this.privilegesWithoutAuth.push(res.data[i]);
          }
          }
      },
      error => {
      }
    );
  }
onGetRolesList(model, dataSet) {
    const data = {};
    this.utilities.postServiceCall(data, model).subscribe(
      response => {
        const res = response.json();
        this.rolesList = res.data;
        for (var i = 0; i<res.data.length; i++){
          if (res.data[i].registrationType == 2 || res.data[i].registrationType == 3) {
            this.registrationTypesWithoutAuth.push(res.data[i]);
            if (res.data[i].needApproval) {
              this.waitForApprovalRoutes.push(res.data[i].routeName);
              this.waitForApproval.push(res.data[i]);
            } else {
              this.noWaitForApproval.push(res.data[i]);
              this.noWaitForApprovalRoutes.push(res.data[i].routeName);
            }
          }
          }
      },
      error => {
      }
    );
  }
onGetPhoneTypeIDs(model) {
  const data = {};
  this.utilities.postServiceCall(data, model).subscribe(
    response => {
      const res = response.json();
      this.phoneTypeIds = res.data;
    },
    error => {
    }
  );
}

onUserLogOutRequest(message) {
    const result = confirm(
      'Do you want to log-Out(' + message + ') ?',
      'User Log-Out'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.authService.logOut();
      }
    });
  }

onChangeParamCheckbox(e, checkbox) {
    if (e.value) {
      this.paramwinfrm.get(checkbox).setValue(1);
    } else {
      this.paramwinfrm.get(checkbox).setValue(0);
    }
}



logServerErrors(currentRoute, errorLog, action ?) {
  const mockUser = {
    userId: '501',
    token: 'bC-6pxhT%a_PgYYnhPRCs~PmS_EaKpf@HSzY$HqL',
    channelCode: AppSettings.channelCode,
    channelSecurityCode: AppSettings.channelSecurityCode
  };
  const userDetails = this.router.url == '/registration' ? mockUser : this.authService.getUserDetails();
  const data = {
    currentRoute,
    errorLog,
    action,
    userDetails: JSON.stringify(userDetails)
  };
  this.utilities.errorLogApiCall(data).subscribe(res => {
  // console.log(res);
  }, err => {
    console.log(`Failed to send error log ${err}`);
  });
}

}
