import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-employer-create",
  templateUrl: "./employer-create.component.html",
  styleUrls: ["./employer-create.component.scss"],
})
export class EmployerCreateComponent
  extends SharedClassComponent
  implements OnInit
{
  fumisEmployerCreateForm: FormGroup;
  sectorList: any = [];
  districtList: any = [];
  natureBusinessList: any = [];
  branchList = [];
  districtListNew: any;
  regionList: any;

  // constructor() { }

  ngOnInit() {
    this.fumisEmployerCreateForm = new FormGroup({
      emName: new FormControl("", Validators.compose([Validators.required])),
      altName: new FormControl("", Validators.compose([])),
      isCompensation: new FormControl(""),
      acronomy: new FormControl("", Validators.compose([])),
      regNo: new FormControl("", Validators.compose([Validators.required])),
      district: new FormControl("", Validators.compose([Validators.required])),
      sector: new FormControl("", Validators.compose([Validators.required])),
      natureBusines: new FormControl("", Validators.compose([])),
      postalAddress: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      physicalAddress: new FormControl("", Validators.compose([])),

      regDate: new FormControl("", Validators.compose([Validators.required])),
      opDate: new FormControl("", Validators.compose([Validators.required])),

      emailAddress: new FormControl("", Validators.compose([Validators.email])),
      mobile: new FormControl(
        "",
        Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      telephone: new FormControl(
        "",
        Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      faxnumber: new FormControl("", Validators.compose([])),
      contactPerson: new FormControl("", Validators.compose([])),
      branchCode: new FormControl("", Validators.compose([])),
      website: new FormControl("", Validators.compose([])),
    });

    this.getDistricts();
    this.getSectors();
    this.businessNature();
    this.branch();
    this.getRegion();
  }

  createEmployer() {
    const errorMessages = {
      emName: "Employer Name is required",
      altName: "",
      acronomy: "",
      regNo: "Registration Number is required",
      district: "District is required",
      sector: "Sector is required",
      natureBusines: "",
      postalAddress: "Postal Address is required",
      physicalAddress: "",
      regDate: "Registration Date is required",
      opDate: "Operation Date is required",
      emailAddress: "Invalid Email Address",
      mobile: "Mobile No  must be 10 digits",
      telephone: "Telephone No  must be 10 digits",
      faxnumber: "",
      contactPerson: "",
      branchCode: "",
      website: "",
    };

    for (const controlName in this.fumisEmployerCreateForm.controls) {
      const control = this.fumisEmployerCreateForm.get(controlName);
      if (control.invalid) {
        let errorMessage = errorMessages[controlName];

        // Handle email validation
        if (
          controlName === "emailAddress" &&
          control.hasError("emailAddress")
        ) {
          errorMessage = errorMessages.emailAddress;
        }

        // Handle mobile number validation
        if (controlName === "mobile" && control.hasError("mobile")) {
          errorMessage = errorMessages.mobile;
        }

        // Handle telephone number validation
        if (controlName === "telephone" && control.hasError("telephone")) {
          errorMessage = errorMessages.telephone;
        }

        this.toastr.error(errorMessage);
        return;
      }
    }

    if (this.fumisEmployerCreateForm.get("emName").invalid) {
      this.toastr.error("Employer name required");
      return;
    }
    if (this.fumisEmployerCreateForm.get("regNo").invalid) {
      this.toastr.error("Registration number required");
      return;
    }

    if (this.fumisEmployerCreateForm.get("district").invalid) {
      this.toastr.error("District required");
      return;
    }

    // if(this.fumisEmployerCreateForm.get("sector").invalid)
    // {
    //   this.toastr.error("Sector required")
    //   return;
    // }

    // if(this.fumisEmployerCreateForm.get("postalAddress").invalid)
    // {
    //   this.toastr.error("Postal address required")
    //   return;
    // }

    // if(this.fumisEmployerCreateForm.get("regDate").invalid)
    // {
    //   this.toastr.error("Registration date required")
    //   return;
    // }

    // if(this.fumisEmployerCreateForm.get("opDate").invalid)
    // {
    //   this.toastr.error("First operation date required")
    //   return;
    // }

    if (this.fumisEmployerCreateForm.get("regDate").value == null) {
      this.fumisEmployerCreateForm.get("regDate").patchValue(this.today);
    }

    if (this.fumisEmployerCreateForm.get("opDate").value == null) {
      this.fumisEmployerCreateForm.get("opDate").patchValue(this.today);
    }

    const registrationtDate =
      new Date(
        this.fumisEmployerCreateForm.get("regDate").value
      ).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(
          this.fumisEmployerCreateForm.get("regDate").value
        ).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(this.fumisEmployerCreateForm.get("regDate").value).getDate()
      ).slice(-2);
    const operationDate =
      new Date(this.fumisEmployerCreateForm.get("opDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.fumisEmployerCreateForm.get("opDate").value).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(this.fumisEmployerCreateForm.get("opDate").value).getDate()
      ).slice(-2);

    const data = {
      requestType: "EMPLOYER_CREATE",
    
      isCompensation:this.fumisEmployerCreateForm.get("isCompensation").value ? this.fumisEmployerCreateForm.get("isCompensation").value === true 
      ? 1
      : 0 : 0,
      employerName: this.fumisEmployerCreateForm.get("emName").value,
      alternateName: this.fumisEmployerCreateForm.get("altName").value,
      acronym: this.fumisEmployerCreateForm.get("acronomy").value,
      registrationNumber: this.fumisEmployerCreateForm.get("regNo").value,
      districtId: this.fumisEmployerCreateForm.get("district").value,
      sectorId: this.fumisEmployerCreateForm.get("sector").value,
      natureOfBusiness: this.fumisEmployerCreateForm.get("natureBusines").value,
      postalAddress: this.fumisEmployerCreateForm.get("postalAddress").value,
      physicalAddress:
        this.fumisEmployerCreateForm.get("physicalAddress").value,
      registrationDate: registrationtDate,
      firstOperationDate: operationDate,
      emailAddress: this.fumisEmployerCreateForm.get("emailAddress").value,
      website: this.fumisEmployerCreateForm.get("website").value,
      telephoneOffice: this.fumisEmployerCreateForm.get("telephone").value,
      telephoneMobile: this.fumisEmployerCreateForm.get("mobile").value,
      faxNumber: this.fumisEmployerCreateForm.get("faxnumber").value,
      contactPerson: this.fumisEmployerCreateForm.get("contactPerson").value,
      isActive: "1",
      branchCode: this.fumisEmployerCreateForm.get("branchCode").value,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        // this.spinner.hide();
        // this.toastr.success("Operation Successfully")
        // this.fumisEmployerCreateForm.reset()
        // this.router.navigate(['fumis-employer-info',])
        const response = res.json();
        if (response.code == 2000) {
          this.toastr.success(response.message);
          this.fumisEmployerCreateForm.reset();
          this.router.navigate([
            "fumis-employer-info",
            response.data[0].EmployerNumber,
          ]);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error("Something went wrong please try again.");
        this.spinner.hide();
      }
    );
  }

  getSectors() {
    const data = {
      requestType: "SECTOR_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.sectorList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getDistricts() {
    const data = {
      requestType: "DISTRICT_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.districtList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  businessNature() {
    const data = {
      requestType: "NATURE_OF_BUSINESS_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.natureBusinessList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  branch() {
    const data = {
      requestType: "BRANCHES",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.branchList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  filterDistricts(e) {
    this.districtListNew = this.districtList.filter(
      (res) => res.RegionID == e.value
    );
  }

  getRegion() {
    const data = {
      requestType: "REGION_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.regionList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
}
