import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends SharedClassComponent implements OnInit {
  title = 'Account Profile';
  dialogTitle = 'Add Account Operator';
  endPoint = 'portal/request';
  accountDetailsForm: FormGroup;
  passwordChangeForm: FormGroup;
  usernameChangeForm: FormGroup;
  coEmployerForm: FormGroup;
  loggedInUserCredentials;
  phones = [];
  role;
  data = {
    userId: 0,
  };
  feedbackForm: FormGroup;
  loadingVisible = false;
  showSuccessDialog = false;
  successMessage = '';
  customerName = '';
  password = '';
  passwordRegex = '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{8,}';

  alertDialogMessage = '';
  showAlertDialog = false;

  showAccountOpDialog = false;

  hideCreateUserAccount = true;
  hideCreateUserAccOnIndAcc = true;

  ngOnInit() {
    if(`${this.authService.getUserDetails().userGroup}`.match('EMPLOYER')) {
      this.hideCreateUserAccOnIndAcc = false;
    }
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    if (this.authService.getUserDetails().createdBy == '501') {
      this.hideCreateUserAccount = false;
    }

    this.accountDetailsForm = new FormGroup({
      customerNumber: new FormControl(null, Validators.compose([])),
      customerName: new FormControl(null, Validators.compose([Validators.required])),
      customerEmail: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
      customerPhone: new FormControl(null, Validators.compose([Validators.required])),
      role: new FormControl(null, Validators.compose([Validators.required])),
      email: new FormControl(null, Validators.compose([])),
      firstName: new FormControl(null, Validators.compose([Validators.required])),
      lastName: new FormControl(null, Validators.compose([Validators.required])),
      middleName: new FormControl(null, Validators.compose([Validators.required])),
      systemId: new FormControl(null, Validators.compose([Validators.required])),
      username: new FormControl(null, Validators.compose([Validators.required])),
      designation: new FormControl(null, Validators.compose([Validators.required])),
      linkId: new FormControl(null, Validators.compose([]))
    });
    this.passwordChangeForm = new FormGroup({
      oldPassword: new FormControl(null, Validators.compose([Validators.required])),
      newPassword: new FormControl(null, Validators.compose([Validators.required])),
      confirmPassword: new FormControl(null, Validators.compose([Validators.required]))
    });
    this.usernameChangeForm = new FormGroup({
      username: new FormControl(null, Validators.compose([Validators.required])),
      password: new FormControl(null, Validators.compose([Validators.required]))
    });
    this.coEmployerForm = new FormGroup({
      requestType: new FormControl('CO_EMPLOYER_CREATE', Validators.compose([])),
      firstName:  new FormControl(null, Validators.compose([Validators.required])),
      middleName: new FormControl(null, Validators.compose([Validators.required])),
      lastName: new FormControl(null, Validators.compose([Validators.required])),
      username: new FormControl(null, Validators.compose([Validators.required])),
      designation: new FormControl(null, Validators.compose([Validators.required])),
      mobileNo: new FormControl(null, Validators.compose([Validators.required])),
      telephoneNo: new FormControl(null, Validators.compose([])),
      email: new FormControl(null, Validators.compose([Validators.required, Validators.email]))
    });
    this.appInfo.setTitle('User Account Profile');
    this.customerName = this.authService.getUserDetails().accountName;
    this.loggedInUserCredentials = this.authService.getUserDetails();

    this.loadUserDetails();
  }

  passwordComparison = () => {
    return this.password;
  }

  onUpdateUserAccountDetails() {
    if (this.accountDetailsForm.invalid) {
      this.toastr.error('Please provide required information on the fields marked with asterisk', 'User account update Alert');
      return;
    }
    this.spinner.show();
    const data = {
      requestType: "PROFILE_UPDATE",
      firstName: this.accountDetailsForm.get('firstName').value,
      middleName: this.accountDetailsForm.get('middleName').value,
      lastName: this.accountDetailsForm.get('lastName').value,
      username: this.accountDetailsForm.get('username').value,
      designation: this.accountDetailsForm.get('designation').value,
      accountName: this.accountDetailsForm.get('customerName').value,
      mobileNo: this.accountDetailsForm.get('customerPhone').value,
      telephoneNo: this.accountDetailsForm.get('customerPhone').value,
      email: this.accountDetailsForm.get('customerEmail').value
    };
    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      const serverRes = res.json();
      if (serverRes.code == 2000) {
        this.loadUserDetails()
        this.toastr.success('Account Details updated successfully');
      } else {
        this.toastr.error(serverRes.message)
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.toastr.error('Something went wrong, please try again!!', 'Alert');
    });
  }

  onPasswordChange() {
    if (this.passwordChangeForm.invalid) {
      this.toastr.error('Fill in all the form details', 'Change password Alert');
      return;
    }
    // check the details
    const newPassword = this.passwordChangeForm.get('newPassword').value;
    const confirmPassword = this.passwordChangeForm.get('confirmPassword').value;
    if (newPassword !== confirmPassword) {
      this.toastr.error('Password Mismatch, please try again', 'Alert');
      this.passwordChangeForm.get('newPassword').setValue(null),
        this.passwordChangeForm.get('confirmPassword').setValue(null);
      return;
    }
    this.spinner.show();
    this.authService
      .changeUserpassword(this.passwordChangeForm.value, 'users/password/change')
      .subscribe(
        response => {
          this.response = response.json();
          // the details
          if (this.response.code == 2000) {
            this.toastr.success('Password Changed Successfully!', 'Response');
            this.passwordChangeForm.reset();
          } else {
            this.toastr.error(this.response.message, 'Alert');
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.toastr.error('Something went wrong, please try again!!', 'Alert');
        }
      );
  }

  onUsernameChange() {
    if (this.usernameChangeForm.invalid) {
      this.toastr.error('Please enter your current password along side your new username', 'Change username alert.');
      return;
    }
  }

  loadUserDetails() {
    this.spinner.show();
    this.data.userId = this.loggedInUserCredentials.id;

    const request = {
      "requestType": "USER_GET",
      "userId": this.loggedInUserCredentials.id
    }
    this.utilities.postServiceCall(request, this.endPoint).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.accountDetailsForm.get('customerNumber').patchValue(response.data.linkId);
    this.accountDetailsForm.get('customerName').patchValue(response.data.accountName);
    this.accountDetailsForm.get('customerEmail').patchValue(response.data.email);
    this.accountDetailsForm.get('customerPhone').patchValue(response.data.mobileNo);
    this.accountDetailsForm.get('role').patchValue(response.data.userGroup);
    this.accountDetailsForm.get('systemId').patchValue(response.data.systemId);
    this.accountDetailsForm.get('username').patchValue(response.data.username);
    this.accountDetailsForm.get('firstName').patchValue(response.data.firstName);
    this.accountDetailsForm.get('middleName').patchValue(response.data.middleName);
    this.accountDetailsForm.get('lastName').patchValue(response.data.lastName);
    this.accountDetailsForm.get('designation').patchValue(response.data.designation);
        } else {
          this.toastr.error(response.message, 'Error');
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Something went wrong please try again',
          'Request Failed'
        );
      }
    );
  }

  openAccOpDialog() {
    this.coEmployerForm.reset();
    this.showAccountOpDialog = true;
  }

  addAccountOperator() {
      if (this.coEmployerForm.invalid) {
        this.toastr.error('Please provide required information on the fields marked with asterisk', 'User account create Alert');
        return;
      }
      this.spinner.show();
      this.coEmployerForm.get('requestType').patchValue('CO_EMPLOYER_CREATE');
      this.utilities.postServiceCall(this.coEmployerForm.value, 'portal/request').subscribe(res => {
        const serverRes = res.json();
        if (serverRes.code == 2000) {
          this.showAccountOpDialog = false;
          this.alertDialogMessage = serverRes.message;
          this.showAlertDialog = true;
        } else {
          this.toastr.error(serverRes.message)
        }
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.toastr.error('Something went wrong, please try again!!', 'Alert');
      });
  }

  closeAlertDialog() {
    this.showAlertDialog = false;
    this.coEmployerForm.reset();
    this.coEmployerForm.get('requestType').patchValue('CO_EMPLOYER_CREATE');
  }
}
