import { Component, HostBinding } from "@angular/core";

import { AuthService, ScreenService, AppInfoService } from "./shared/services";
import { Router } from "@angular/router";
import { InactivityService } from "./shared/services/inactivity.service";
import { AppSettings } from "./app-settings";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  copyRightBanner = "";
  isRegistrationPage = false;
  @HostBinding("class") get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(" ");
  }
  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private router: Router,
    private inactivityService: InactivityService
  ) {
    this.copyRightBanner = appInfo.getCopyRightText();
  }
  isRegistrationRoute() {
    return this.router.url === "/registration";
  }
  isPasswordForgetRoute() {
    return this.router.url === "/forget-password";
  }

  isEmployerRegistrationRoute() {
    return this.router.url === "/employer-registration";
  }
  isAutorized() {
    return this.authService.isLoggedIn;
    //return true;
  }

  ngOnInit() {
    const data = this.authService.getUserDetails();
    console.log(data);
    if (data) {
      document.addEventListener("mousemove", () =>
        this.inactivityService.handleUserActivity()
      );
      document.addEventListener("keypress", () =>
        this.inactivityService.handleUserActivity()
      );
      document.addEventListener("input", () =>
        this.inactivityService.handleUserActivity()
      );
      document.addEventListener("scroll", () =>
        this.inactivityService.handleUserActivity()
      );
      window.addEventListener("focus", () =>
        this.inactivityService.handleUserActivity()
      );
      window.addEventListener("blur", () =>
        this.inactivityService.handleUserActivity()
      );
      document.addEventListener("touchstart", () =>
        this.inactivityService.handleUserActivity()
      );
      document.addEventListener("touchmove", () =>
        this.inactivityService.handleUserActivity()
      );

      this.inactivityService.inactivityAlert$.subscribe(() => {
        this.inactivityService.logout();
      });
    }
  }
}
