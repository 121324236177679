import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import notify from 'devextreme/ui/notify';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-multiple-months-selector-per-invoice',
  templateUrl: './multiple-months-selector-per-invoice.component.html',
  styleUrls: ['./multiple-months-selector-per-invoice.component.scss']
})
export class MultipleMonthsSelectorPerInvoiceComponent extends SharedClassComponent  implements OnInit {

  invoiceCreateEndPoint = 'invoices/create';

  items: any[];
  productsStore: any;
  productTypes: any[];
  backButtonOptions: any;
  refreshButtonOptions: any;
  selectBoxOptions: any;
  nextButtonOptions: any;
  saveButtonOptions: any;
  printButtonOptions: any;
  settingsButtonOptions: any;

  contributionBatch = [];
  tagboxValues = [];

  btnText = 'Next';
  isContributionDataPosted = false;


  invoiceForm: FormGroup;
  months: any;
  years: any;
  curDate = new Date();
  userDetails: any;
  currentMonth = this.curDate.getMonth();
  title = 'Initiate Monthly Contributions';
  contributionBatchResponse = false;
  contributionBatchData = [];
  hideEmployeeListDataGrid = true;
  currrentEmployerNumber: any;
  showYearAndMonthColumn = false;
  yearOfContribution: any;
  contributionNarration: any;
  retrievedContributionDataFromService: any;
  ngOnInit() {
    this.currrentEmployerNumber = this.authService.getUserDetails().linkId;
    this.route.queryParams.subscribe((params) => {
      this.contributionBatchResponse = params.frombatchservice;
    });
    if (this.contributionBatchResponse) {
      this.retrievedContributionDataFromService = this.securityService.decryptString(sessionStorage.getItem(AppSettings.contributionBatchRequestKey));
      this.contributionBatchData = this.retrievedContributionDataFromService.contributions;
      if (this.contributionBatchData.length < 0) {
        return;
      }
      this.isContributionDataPosted = true;
      this.hideEmployeeListDataGrid = false;
      this.title = 'Monthly Contributions';
      const contributionBatchInfo = this.securityService.decryptString(sessionStorage.getItem(AppSettings.contributionBatchMonthKey));
      this.contributionBatch = contributionBatchInfo.months;
      this.yearOfContribution = contributionBatchInfo.year;
      this.contributionNarration = contributionBatchInfo.narration;
      this.btnText = 'Create Invoice';
    }
    this.invoiceForm = new FormGroup({
      contributionYear: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(4), Validators.minLength(4)])),
      contributionMonths: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(250)])),
    });

    this.userDetails = this.authService.getUserDetails();
    this.months = this.contributionService.getMonthsDataset();
    this.years = this.contributionService.getYears();

    this.backButtonOptions = {
      text: 'Back',
      icon: 'chevronleft',
      onClick: () => {
        this.onBackButtonPressed();
      }
  };

    this.nextButtonOptions = {
      text: this.btnText,
      icon: 'chevronright',
      type: 'default',
      onClick: () => {
        this.createMembersContributionInvoice();
      }
  };

  }

  selectedChanged(e){}
  onSubmitInvoiceTimeScale() {
    if (this.invoiceForm.invalid) {
      this.toastr.error('Invalid form');
      return;
    }
    this.contributionBatch = this.invoiceForm.get('contributionMonths').value;
    this.yearOfContribution = this.invoiceForm.get('contributionYear').value;
    this.contributionNarration = this.invoiceForm.get('description').value;
    const contributionData = {
      months: this.contributionBatch,
      year: this.yearOfContribution,
      narration: this.contributionNarration
    };
    sessionStorage.setItem(AppSettings.contributionBatchMonthKey,  this.securityService.encryptString(contributionData));
    if (this.isContributionDataPosted) {
      this.hideEmployeeListDataGrid = false;
      const contributionBatchInfo = this.securityService.decryptString(sessionStorage.getItem(AppSettings.contributionBatchMonthKey));
      this.contributionBatch = contributionBatchInfo.months;
      this.yearOfContribution = contributionBatchInfo.year;
      this.contributionNarration = contributionBatchInfo.narration;
      return;
    }
    this.router.navigate(['/contribution/upload'], { queryParams: { batch: true },  queryParamsHandling: 'merge' });
  }

  onBackButtonPressed() {
    this.hideEmployeeListDataGrid = true;
    this.btnText = 'Next';
  }

  createMembersContributionInvoice() {
    if (this.contributionBatchData.length < 0) {
      notify('Members Contribution data not available!', 'error', 6000);
      return;
    }

    this.hideEmployeeListDataGrid = false;
    const contributionData = [];
    if (this.isContributionDataPosted) {
      this.spinner.show();
      for (let index = 0; index < this.contributionBatch.length; index++) {
        const totalAmountPerContribution = this.retrievedContributionDataFromService.contributions;
        let amount = 0;
        for (let i = 0; i < totalAmountPerContribution.length; i++) {
          amount += totalAmountPerContribution[i].amountContributed;
        }
        contributionData.push({
          contributionYear: this.yearOfContribution,
          contributionMonth: this.contributionBatch[index],
          totalAmount: `${amount}`,
          narration: `Contribution for ${this.contributionBatch[index]} month of ${this.yearOfContribution}`,
          individualContributions: this.contributionBatchData
        });
      }
      const contributionBatchData = {
        employerNumber: this.retrievedContributionDataFromService.employerNumber,
        invoiceTotalAmount: `${this.retrievedContributionDataFromService.totalAmount * this.contributionBatch.length}`,
        invoiceDescription: this.contributionNarration,
        contributions: contributionData
      };
      this.utilities.postServiceCall(contributionBatchData, this.invoiceCreateEndPoint).subscribe(res => {
        const serverResponse = res.json();
        this.spinner.hide();
        if (serverResponse.code == 2000) {
          this.toastr.success(serverResponse.message, 'Invoice Created');
          sessionStorage.removeItem(AppSettings.batchContributionInvoiceResultDataKey);
          sessionStorage.setItem(AppSettings.batchContributionInvoiceResultDataKey,  this.securityService.encryptString(serverResponse.data));
          this.router.navigate(['/multiple-contribution-invoice'], { queryParams: { generatedBatch: true },  queryParamsHandling: 'merge' });
        } else {
          this.toastr.error(serverResponse.message, 'Failed to create invoice');
          this.logServerErrors(this.router.url, serverResponse.message, 'CONTRIBUTION_CREATE_UNUSED');
        }
      }, error => {
        this.logServerErrors(this.router.url, error, 'CONTRIBUTION_CREATE_UNUSED');
             this.spinner.hide();
             this.toastr.error(error, 'Request Failed');
      });
    }
  }

  selectedItems(e) {
  }

  //  DataGrid's row Calculations & Validations
  calculateMemberContribution(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(',', '');
      return ((7 / 100) * num);
    } else {
      return rowData.memberContribution;
    }
  }
  calculateEmployerContribution(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(',', '');
      return ((13 / 100) * num);
    } else {
      return rowData.employerContribution;
    }
  }
  memberSalaryFilter(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(',', '');
      return num;
    } else {
      return rowData.memberSalary;
    }
  }
  calculateAmountContributed(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(',', '');
      // return num1 + num2;
      return (((7 / 100) * num + (13 / 100)) * num);
    } else {
      return rowData.amountContributed;
    }
  }

  validateMemberSalaryThreshold(e) {
    let num: any = e.value;
    num = +num.replace(',', '');
    return num >= 180000;
  }

  validateMemberNumber(memberNumber, endPoint) {
    const data = {
      memberNumber,
    };
    return this.utilities.postServiceCall(data, endPoint);
  }
  onRowInserting(e) {
    e.data.employerNumber = this.currrentEmployerNumber;
    e.data.contributionYear = this.contributionService.getContributionYear();
    e.data.contributionMonth = this.contributionService.getContributionMonth();
  }
  onRowInserted(e) {
    const baseSalary = e.data.memberSalary;
    // calculate member & employer contribution, as well as total amount contributed
    const memberContribution: number = ((7 / 100) * baseSalary);
    const employerContribution: number = ((13 / 100) * baseSalary);
    e.data.memberContribution = memberContribution;
    e.data.employerContribution = employerContribution;
    e.data.amountContributed = memberContribution + employerContribution;
  }

  onRowUpdated(e) {
    const baseSalary = e.data.memberSalary;
    // calculate member & employer contribution, as well as total amount contributed
    const memberContribution: number = ((7 / 100) * baseSalary);
    const employerContribution: number = ((13 / 100) * baseSalary);
    e.data.memberContribution = memberContribution;
    e.data.employerContribution = employerContribution;
    e.data.amountContributed = memberContribution + employerContribution;
  }
  onEditingStart(e) {
    // e.data.employerNumber = this.currrentEmployerNumber;
  }
  getMonthName(e) {
    // tslint:disable-next-line: prefer-for-of
    for (
      let index = 0;
      index < this.contributionService.getMonthsDataset().length;
      index++
    ) {
      if (
        this.contributionService.getMonthsDataset()[index].id ==
        e.data.contributionMonth
      ) {
        e.data.contributionMonth = this.contributionService.getMonthsDataset()[
          index
        ].text;
      }
    }
  }

}
