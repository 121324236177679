import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent extends SharedClassComponent implements OnInit {

  title = 'Control Number Verification';
  invoiceControlNumberForm: FormGroup;
  hideControlNumberSearchedDataGrid = true;
  invoiceSearchedByControlNumberDataSource = [{
    item: '',
    controlNumber: '',
    totalAmount: '',
    currency: '',
    employerNumber: '',
    employerName: '',
    billExpireDate: '',
    billDescription: ''
  }];
  billItemsDataSource = [];
  searchInvoiceByControlNumberEndpoint = 'fumis_bill/get';

  ngOnInit() {
    this.appInfo.setTitle(this.title);

    this.invoiceControlNumberForm = new FormGroup({
      controlNumber: new FormControl(null, Validators.compose([Validators.required]))
    });
  }

  onSubmitInvoiceControlNumber() {
    if (this.invoiceControlNumberForm.invalid) {
      this.toastr.error('Control number field can not be empty');
      return;
    }
    this.spinner.show();
    const data = {
      controlNumber: this.invoiceControlNumberForm.get('controlNumber').value
    };
    this.utilities.postServiceCall(data, this.searchInvoiceByControlNumberEndpoint).subscribe(res => {
      this.spinner.hide();
      const serverRes = res.json();
      if (serverRes.code == 2000) {
        this.billItemsDataSource = serverRes.data.billItems;
        this.invoiceSearchedByControlNumberDataSource[0].item = 'Item';
        this.invoiceSearchedByControlNumberDataSource[0].controlNumber = serverRes.data.controlNumber;
        this.invoiceSearchedByControlNumberDataSource[0].totalAmount = serverRes.data.totalAmount;
        this.invoiceSearchedByControlNumberDataSource[0].currency = serverRes.data.currency;
        this.invoiceSearchedByControlNumberDataSource[0].employerNumber = serverRes.data.employerNumber;
        this.invoiceSearchedByControlNumberDataSource[0].employerName = serverRes.data.employerName;
        this.invoiceSearchedByControlNumberDataSource[0].billExpireDate = serverRes.data.billExpireDate;
        this.invoiceSearchedByControlNumberDataSource[0].billDescription = serverRes.data.billDescription;

        this.hideControlNumberSearchedDataGrid = false;
      }
    }, error => {this.toastr.error(error); this.spinner.hide(); });
  }

}
