import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppSettings } from "src/app/app-settings";
import { AuthService } from "../services";

@Injectable()
export class OutgoingRequestInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService, public toastr: ToastrService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // request = request.clone({
      //   setHeaders: {
      //     Authorization: 'hi',
      //     username: 'seleman'
      //   },
      //   body: {...request.body, "requestUrl": request.url, "requestBody": request.body }
      // });
    // return next.handle(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toastr.error('Something went wrong while sending a request, please try again later.');
        return throwError(error);
      })
    );
  }

}
