import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, HeaderComponent } from './shared/components';
import {
  AuthGuardService,
  AuthGuardServiceAdminsOnly, GeneratedContributionsLeaveGuard, InvoiceLeaveGuard
} from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import {
  DxDataGridModule,
  DxFormModule,
  DxContextMenuModule,
  DxMenuModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxButtonModule,
  DxPopupModule,
  DxActionSheetModule,
  DxFileUploaderModule,
  DxNumberBoxModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxScrollViewModule,
  DxHtmlEditorModule,
  DxDropDownBoxModule,
  DxTagBoxModule,
  DxRadioGroupModule,
  DxTabPanelModule,
  DxBoxModule,
  DxTemplateModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxSpeedDialActionModule,
  DxLoadIndicatorModule,
  DxChartModule,
  DxListModule,
  DxSwitchModule,
  DxToolbarModule,
  DxAccordionModule,
  DxSliderModule,
  DxLoadPanelModule
} from 'devextreme-angular';
import { UserManagementComponent } from './pages/system-administration/user-management/user-management.component';
import { UserRolesComponent } from './configurations/user-management/user-roles/user-roles.component';
import { AccessLevelComponent } from './configurations/user-management/access-level/access-level.component';
import { NavigationTypesComponent } from './configurations/systemaccess/navigation-types/navigation-types.component';
import { NavigationLevelsComponent } from './configurations/systemaccess/navigation-levels/navigation-levels.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogueComponent } from './utilities/confirmation-dialogue/confirmation-dialogue.component';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from './shared/services/utilities.service';
import { SharedClassComponent } from './configurations/shared-class/shared-class.component';

import { NgHttpLoaderModule } from 'ng-http-loader';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { UserStatusesComponent } from './configurations/user-management/user-statuses/user-statuses.component';
import { BankBranchesComponent } from './configurations/systemaccess/bank-branches/bank-branches.component';
import { BillDetailsComponent } from './bill-management/bill-details/bill-details.component';
import { NavigationsComponent } from './configurations/systemaccess/navigations/navigations.component';
import { PermissionsComponent } from './configurations/systemaccess/permissions/permissions.component';
import { RevenueTransactionComponent } from './bill-management/reports/revenue-transaction.component';
import { CurrencyDenominationsComponent } from './configurations/currency-denominations/currency-denominations.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountTypesComponent } from './account/account-types/account-types.component';
import { AccountsComponent } from './account/accounts/accounts.component';
import { GatewayComponent } from './gateway/gateway.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { TemporaryLoginComponent } from './shared/components/temporary-login/temporary-login.component';
import { UserSelfRegistrationComponent } from './user-self-registration/user-self-registration.component';
import { SystemPrivilegesComponent } from './system-privileges/system-privileges.component';
import { PhoneTypesComponent } from './phone-types/phone-types.component';
import { ContributionListComponent } from './Contributions/employers/contribution-list/contribution-list.component';
import { ContributionUploadComponent } from './Contributions/employers/contribution-upload/contribution-upload.component';
import { PenaltyComponent } from './Contributions/employers/penalty/penalty.component';
import { QuestionsComponent } from './user-self-registration/questions/questions.component';
import { ZssfLocationComponent } from './map/zssf-location/zssf-location.component';
import { InvoiceReducer} from './database/reducer/invoiceReducer';
import { StoreModule} from '@ngrx/store';
import { UserFeedbackComponent } from './self-service/user-feedback/user-feedback.component';
import { MultipleContributionsComponent } from './Contributions/employers/multiple-contributions/multiple-contributions.component';
// tslint:disable-next-line: max-line-length
import { MultipleContibutionInvoiceComponent } from './Contributions/employers/multiple-contibution-invoice/multiple-contibution-invoice.component';
import { AnQrcodeModule } from 'an-qrcode';
import { SecurityManagerComponent } from './security-manager/security-manager.component';
import { AccessLogsComponent } from './access-logs/access-logs.component';
import { InvoiceComponent } from './Contributions/employers/invoice/invoice.component';
import { GeneratedContributionsComponent } from './generated-contributions/generated-contributions.component';
import { AssociatedMembersContributionsPerInvoiceComponent } from './batch-contributions/associated-members-contributions-per-invoice/associated-members-contributions-per-invoice.component';
import { MultipleMonthsSelectorPerInvoiceComponent } from './batch-contributions/multiple-months-selector-per-invoice/multiple-months-selector-per-invoice.component';
import { GeneratedInvoicesPenaltyListComponent } from './batch-contributions/generated-invoices-penalty-list/generated-invoices-penalty-list.component';
import { AssociatedMonthlyContributionsPerInvoiceComponent } from './batch-contributions/associated-monthly-contributions-per-invoice/associated-monthly-contributions-per-invoice.component';
import { ForgetPasswordComponent } from './shared/components/forget-password/forget-password.component';
import { DashboardComponent } from './investment-portal/dashboard/dashboard.component';
import { InvoicesComponent } from './investment-portal/invoices/invoices.component';
import { BillPaymentsComponent } from './investment-portal/bill-payments/bill-payments.component';
import { InvoiceDetailsComponent } from './investment-portal/invoice-details/invoice-details.component';
import { FeedbackReplyComponent } from './self-service/feedback-reply/feedback-reply.component';
import { ReplySectionComponent } from './self-service/reply-section/reply-section.component';
import { CompensationClaimRegisterComponent } from './COMPENSATION/compensation-claim-register/compensation-claim-register.component';

import { CompensationClaimInfoComponent } from './COMPENSATION/compensation-claim-info/compensation-claim-info.component';
import { NotificationInfoComponent } from './COMPENSATION/notification-info/notification-info.component';
import { AccidentFormComponent } from './COMPENSATION/accident-form/accident-form.component';
import { DiseaseFormComponent } from './COMPENSATION/disease-form/disease-form.component';
import { DeathFormComponent } from './COMPENSATION/death-form/death-form.component';
import { EmployerCreateComponent } from './employers/employer-create/employer-create.component';
import { EmployerSelfRegistrationComponent } from './employers/employer-self-registration/employer-self-registration.component';
import { EmployerCertificateComponent } from './employers/employer-certificate/employer-certificate.component';


const routes: Routes = [
  {
    path: 'access-logs',
    component: AccessLogsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'customers/invoices/detail',
    component: InvoiceDetailsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'user/feedback/view',
    component: ReplySectionComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'customers/dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'feedback/replies',
    component: FeedbackReplyComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: "employer-create",
    component: EmployerCreateComponent,
  
    // data: { expectedPermission: ['Employer Registration'] },
  },

  
  {
    path: 'compensation-employer-notification',
    component: CompensationClaimRegisterComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'customers/bills/payment',
    component: BillPaymentsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'bill-items',
    component: BillDetailsComponent,
    canActivate: [AuthGuardService]
  },
  //PREVIOUS COMMENTED
  {
    path: 'invoices/penalties/records',
    component: PenaltyComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'generated/invoices',
    component: GeneratedInvoicesPenaltyListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'invoice/members/contributions',
    component: AssociatedMembersContributionsPerInvoiceComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'contributions/batch/months',
    component: MultipleMonthsSelectorPerInvoiceComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'invoice/view/contributions',
    component: GeneratedContributionsComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [GeneratedContributionsLeaveGuard]
  },
  {
    path: 'security-manager',
    component: SecurityManagerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'invoice-payments',
    component: CurrencyDenominationsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-feedback',
    component: UserFeedbackComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'invoice/contributions',
    component: MultipleContributionsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'invoices-records',
    component: BankBranchesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'utilities/confirmation-dialogue',
    component: ConfirmationDialogueComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'zssf-office-locator',
    component: ZssfLocationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'employees-list',
    component: RevenueTransactionComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    component: LoginFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'published-invoices',
    component: ServiceProviderComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'control-number-verification',
    component: AccountsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'contribution-records',
    component: AccountTypesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'change-temporary-password',
    component: TemporaryLoginComponent
  },
  {
    path: 'registration',
    component: UserSelfRegistrationComponent
  },

  {
    path: 'employer-registration',
    component: EmployerSelfRegistrationComponent
  },
  {
    path: 'employer-certificate',
    component: EmployerCertificateComponent
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'contribution/upload',
    component: ContributionUploadComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'multiple-contribution-invoice',
    component: MultipleContibutionInvoiceComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [InvoiceLeaveGuard]
  },
  {
    path: 'contribution/invoice',
    component: InvoiceComponent,
    canActivate: [AuthGuardService]
  },

  // {
  //   path: 'compensation-claim-register',
  //   component: CompensationClaimRegisterComponent,
  //   canActivate: [AuthGuardService]
  // },




  {
    path: 'compensation-claim-info',
    component: CompensationClaimInfoComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'compensation-employer-notification',
    component: CompensationClaimRegisterComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'compensation-notify-accident',
    component: AccidentFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'compensation-notify-disease',
    component: DiseaseFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'compensation-notify-death',
    component: DeathFormComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: "notification-info/:id",
    component: NotificationInfoComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: '**',
    redirectTo: 'home',
    canActivate: [AuthGuardService]
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{ useHash: true }),
    DxDataGridModule,
    DxFormModule,
    DxToolbarModule,
    DxContextMenuModule,
    DxMenuModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxButtonModule,
    DxDataGridModule,
    CommonModule,
    DxPopupModule,
    DxActionSheetModule,
   
    DxFileUploaderModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxContextMenuModule,
    DxMenuModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    NgbModalModule,
    DxTabPanelModule,
    DxBoxModule,
    DxDropDownBoxModule,
    DxTagBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxTemplateModule,
    DxSpeedDialActionModule,
    DxLoadIndicatorModule,
    DxListModule,
    NgHttpLoaderModule.forRoot(),
    ModalDialogModule.forRoot(),
    StoreModule.forRoot({ invoice: InvoiceReducer }),
    DxRadioGroupModule,
    DxChartModule,
    AnQrcodeModule,
    DxSwitchModule,
    DxAccordionModule,
    DxSliderModule,
    DxLoadPanelModule
  ],
  providers: [AuthGuardService, UtilitiesService, AuthGuardServiceAdminsOnly],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    ProfileComponent,
    DisplayDataComponent,
    UserManagementComponent,
    UserRolesComponent,
    AccessLevelComponent,
    NavigationTypesComponent,
    NavigationLevelsComponent,
    SharedClassComponent,
    UserStatusesComponent,
    BankBranchesComponent,
    BillDetailsComponent,
    NavigationsComponent,
    PermissionsComponent,
    RevenueTransactionComponent,
    CurrencyDenominationsComponent,
    SettingsComponent,
    AccountTypesComponent,
    AccountsComponent,
    GatewayComponent,
    ServiceProviderComponent,
    TemporaryLoginComponent,
    UserSelfRegistrationComponent,
    EmployerSelfRegistrationComponent,
    SystemPrivilegesComponent,
    PhoneTypesComponent,
    ContributionListComponent,
    ContributionUploadComponent,
    InvoiceComponent,
    PenaltyComponent,
    QuestionsComponent,
    ZssfLocationComponent,
    UserFeedbackComponent,
    MultipleContributionsComponent,
    MultipleContibutionInvoiceComponent,
    SecurityManagerComponent,
    AccessLogsComponent,
    GeneratedContributionsComponent,
    AssociatedMembersContributionsPerInvoiceComponent,
    MultipleMonthsSelectorPerInvoiceComponent,
    GeneratedInvoicesPenaltyListComponent,
    AssociatedMonthlyContributionsPerInvoiceComponent,
    ForgetPasswordComponent,
    DashboardComponent,
    InvoicesComponent,
    BillPaymentsComponent,
    InvoiceDetailsComponent,
    FeedbackReplyComponent,
    EmployerCreateComponent,
    ReplySectionComponent,
    CompensationClaimRegisterComponent,
    EmployerCertificateComponent,

    
    CompensationClaimInfoComponent,
    NotificationInfoComponent,
    AccidentFormComponent,
    DiseaseFormComponent,
    DeathFormComponent,
    CompensationClaimInfoComponent,
   
    
   
  ]
})
export class AppRoutingModule {}
