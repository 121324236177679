import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
import { DxDataGridComponent } from "devextreme-angular";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent extends SharedClassComponent implements OnInit {
  tabPanelData: any = [
    {
      id: 1,
      icon: "fa fa-tachometer",
      name: "Dashboard",
    },
  ];
  showAlertDialogN = false;
  invoiceGeneratedByEmployerDataSource = [];
  invoiceWithControlNumberDataSource = [];
  invoiceSearchedByControlNumberDataSource = [
    {
      item: "",
      controlNumber: "",
      totalAmount: "",
      currency: "",
      employerNumber: "",
      employerName: "",
      billExpireDate: "",
      billDescription: "",
    },
  ];
  hideControlNumberSearchedDataGrid = true;
  endPointCallInvoiceGenerated = "employer/invoices";
  invoiceControlNumberEndpoint = "employer/bills";
  searchInvoiceByControlNumberEndpoint = "fumis_bill/get";
  invoiceControlNumberForm: FormGroup;
  billItemsDataSource = [];
  dialogValue = 1;
  ngOnInit() {
    // this.getListOfGeneratedInvoices(this.authService.getUserDetails().linkId);
    // this.getListOfGeneratedInvoicesWithControlNumbers(this.authService.getUserDetails().linkId);
    //sessionStorage.getItem('showDialog');
    if (sessionStorage.getItem("showDialog")) {
      this.showAlertDialogN = true;
      //sessionStorage.removeItem("showDialog");
    }

    //SET Timeout function to be executed after 3 seconds
    setTimeout(() => {
      sessionStorage.removeItem("showDialog");
    }, 3000);

    this.appInfo.setTitle("Home");
    this.invoiceControlNumberForm = new FormGroup({
      controlNumber: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
  }

  getListOfGeneratedInvoices(employerNumber) {
    const data = {
      employerNumber,
    };
    this.utilities
      .postServiceCall(data, this.endPointCallInvoiceGenerated)
      .subscribe((res) => {
        const serverResponse = res.json();
        if (serverResponse.code === 2000) {
          this.invoiceGeneratedByEmployerDataSource = serverResponse.data;
        } else {
          this.invoiceGeneratedByEmployerDataSource = [];
        }
      });
  }

  getListOfGeneratedInvoicesWithControlNumbers(employerNumber) {
    const data = {
      employerNumber,
    };
    this.utilities
      .postServiceCall(data, this.invoiceControlNumberEndpoint)
      .subscribe(
        (res) => {
          const serverRes = res.json();
          if (serverRes.code === 2000) {
            this.invoiceWithControlNumberDataSource = serverRes.data;
          } else {
            this.invoiceWithControlNumberDataSource = [];
          }
        },
        (error) => {
          // this.toastr.error(error);
        }
      );
  }

  onSubmitInvoiceControlNumber() {
    if (this.invoiceControlNumberForm.invalid) {
      this.toastr.error("Control number field can not be empty");
      return;
    }
    this.spinner.show();
    const data = {
      controlNumber: this.invoiceControlNumberForm.get("controlNumber").value,
    };
    this.utilities
      .postServiceCall(data, this.searchInvoiceByControlNumberEndpoint)
      .subscribe(
        (res) => {
          this.spinner.hide();
          const serverRes = res.json();
          if (serverRes.code === 2000) {
            this.billItemsDataSource = serverRes.data.billItems;
            this.invoiceSearchedByControlNumberDataSource[0].item = "Item";
            this.invoiceSearchedByControlNumberDataSource[0].controlNumber =
              serverRes.data.controlNumber;
            this.invoiceSearchedByControlNumberDataSource[0].totalAmount =
              serverRes.data.totalAmount;
            this.invoiceSearchedByControlNumberDataSource[0].currency =
              serverRes.data.currency;
            this.invoiceSearchedByControlNumberDataSource[0].employerNumber =
              serverRes.data.employerNumber;
            this.invoiceSearchedByControlNumberDataSource[0].employerName =
              serverRes.data.employerName;
            this.invoiceSearchedByControlNumberDataSource[0].billExpireDate =
              serverRes.data.billExpireDate;
            this.invoiceSearchedByControlNumberDataSource[0].billDescription =
              serverRes.data.billDescription;

            this.hideControlNumberSearchedDataGrid = false;
          }
        },
        (error) => {
          this.toastr.error(error);
          this.spinner.hide();
        }
      );
  }

  openZssfMap() {
    window.open(
      "https://maps.google.com/maps?q=Zanzibar%20Social%20Security%20Fund&t=k&z=17&ie=UTF8&iwloc=&output=embed",
      "_blank"
    );
  }

  newContribution() {
    this.router.navigate(["/contribution/upload"]);
  }
  multipleContibutions() {
    this.router.navigate(["/contributions/batch/months"]);
  }
  contributionRecords() {
    this.router.navigate(["/contribution-records"]);
  }

  employeesList() {
    this.router.navigate(["/employees-list"]);
  }

  publishedInvoices() {
    this.router.navigate(["/published-invoices"]);
  }

  invoicePayments() {
    this.router.navigate(["/invoice-payments"]);
  }

  penaltyRecords() {
    //PREVIOUS COMMENTED
    this.router.navigate(["/invoices/penalties/records"]);
  }

  controlNumberVerification() {
    this.router.navigate(["/control-number-verification"]);
  }

  myAccount() {
    this.router.navigate(["/profile"]);
  }

  editMyAccount() {
    this.router.navigate(["/profile"]);
    // this.toastr.info('Action not available');
  }

  securityManager() {
    this.router.navigate(["/security-manager"]);
  }

  accountAccessLogs() {
    this.toastr.info("Action not available");
  }

  feedback() {
    this.router.navigate(["/user-feedback"]);
  }

  helpDesk() {
    this.toastr.info("Action not available");
  }

  aboutUs() {
    window.open("https://zssf.or.tz/", "_blank");
  }

  onPointClick(e) {
    e.target.select();
  }

  closeDialog() {
    this.showAlertDialogN = false;
    sessionStorage.removeItem("showDialog");
  }

  closeOnOutsideClick(e) {
    this.showAlertDialogN = false;
    sessionStorage.removeItem("showDialog");
  }

  occuationAccident() {
    this.router.navigateByUrl("compensation-notify-accident");
  }

  occuationDisease() {
    this.router.navigateByUrl("compensation-notify-disease");
  }

  occuationDeath() {
    this.router.navigateByUrl("compensation-notify-death");
  }
}
