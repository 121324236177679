import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-employer-certificate",
  templateUrl: "./employer-certificate.component.html",
  styleUrls: ["./employer-certificate.component.scss"],
})
export class EmployerCertificateComponent
  extends SharedClassComponent
  implements OnInit
{

  memberData: any = [];
  fetchForm: FormGroup;
  showSSF4Form = false;
  memberPhoto: any;
  showcertificate = false;
  employerName: any;
  employerNumber: any;
  PhysicalAddress: any;
  PostalAddress: any;

  certificateDate = new Date();
  contributionDate: any;
  searchForm: FormGroup;
  employerInfoSearchResult: any[];
  showLoadEmployerDetails = false;
  employerNameSearch = false;
  employerNumberSearch = false;
  employernumber: any;
  employerButton: any;
  inputTimer: any;

  showOtherDetails: boolean = false;
  acronomy: boolean = false;
  alternativeName: boolean = false;


  ngOnInit() {  
    
    this.getDate(this.certificateDate);    
    const userDetails = this.authService.getUserDetails();

    if (userDetails.linkId && userDetails.linkId != "") {
      this.employerNoSearch(userDetails.linkId);
    }
  
  }

  getDate(dateVal) {
 
    this.contributionDate =
      ("0" + new Date(dateVal).getDate()).slice(-2) +
      "-" +
      ("0" + (new Date(dateVal).getMonth() + 1)).slice(-2) +
      "-" +
      new Date(dateVal).getFullYear();
  }



  employerNoSearch(empNo) {  
    const data = {
      requestType: "EMPLOYERS_GET",
      employerNumber: empNo,
    };

    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {

        const response = res.json();
        if (response.code == 2000) {
          this.employerName = response.data.EmployerName;
          this.employerNumber = response.data.EmployerNumber;
          this.PhysicalAddress = response.data.PhysicalAddress;
          this.PostalAddress = response.data.PostalAddress;
          this.showcertificate = true;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  printSSF4form() {
    setTimeout(() => {
      //this.isInvoicePrinted = true;
      let popUpWindow;

      // Clone the content
      const printContent = document
        .getElementById("print-ssf4")
        .cloneNode(true) as Element;

      // Remove unwanted elements
      const unwantedElements =
        printContent.querySelectorAll(".pull-to-refresh");
      unwantedElements.forEach((element) => element.remove());

      const innerContents = document.getElementById("print-ssf4").innerHTML;
      popUpWindow = window.open(
        "",
        "_blank",
        "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
      );
      // popUpWindow.document.open();
      popUpWindow.document.write(`<html lang="en">
        <head>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
        <style>
        body{
         font-size: 14px;
         /*color: #333;*/
       }
       .space{
         display: flex;
         justify-content: space-between;
         border: 1px dotted #4d88bb;
         padding: 1rem 0;
       }
       .logo__block{
       /* border-bottom: 1px dotted #4d88bb; */
       /* border-bottom: 1px dotted black; */
       }
       .logo__block p, .footer, p {
         /* color: #4d88bb; */
       }
       .invoice__head__child_contacts{
         margin: 19px;
       }
       .invoice_wrapper {
         position: absolute;
       }
       .invoice_wrapper, .invoice{
         /*background-color: rgba(0, 0, 0, 0.055);*/
         padding: 2rem;
         width: fit-content;
         height: 100%;
       }
       .invoice__head,
       .invoice__how_to_pay{
         display: flex;
         justify-content: space-between;
       }
       .invoice__body{
         display: flex;
         /* justify-content: center; */
       }
       .invoice__body table tr th{
         background: #035817;
         color:white;
         text-align:left;
         vertical-align:center;
       }
       .invoice__body table tr {
         background-color: rgba(136 ,136 ,136, .1);
         border: 1px solid #ddd;
         padding: .35em;
       }
       .logo__block, .footer{
           text-align: center;
       }
       .full_table{
         width: 100%;
       }
       .divide {
         position: relative;
         min-height: 80%;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
       }
       /* .watermark {
       position:fixed;
       font-size: 88px;
       bottom: 0;
       opacity:0.5;
       z-index:99;
       color: #35313126;
       text-align: center;
       } */
       table tbody tr td{
       padding: .5rem;
       }


       .certi-title{
         display: flex;
         justify-content: center;
         align-items: center;
             margin-left: 30px !important;
       }
     
       .certi-title p{
         font-size: 20px;
         font-weight: bold;
         text-align: center;
       }
     
      

       .content{
         padding-top: 15px !important;
       
       }
     
     

       .border-blue{
     
         border: 5px solid green;
         padding:4px;
   
         height: 98.5%;
       }
     
       .border-green{
         border: 5px solid blue;
         
         padding: 4px;
         height: 98.5%;
       }
     
       .border-yellow{
         border: 5px solid yellow;
         
         padding: 4px;
         height: 98.5%;
       }

       
     
       .red-circle{
      margin-top: 40px !important;
    margin-bottom: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #781351;
     height: 110px;
    width: 130px;
    border-radius: 50%;
    margin: auto;
       }

       .form-container{
         margin: 10px;
         background-color: white !important;
         display: flex;
         justify-content: center;
         align-items: center;
       }

       @media print {

         .pull-to-refresh {
           display: none !important;
         }
         

         .section{
          display: flex;
          justify-content: center;
          align-items: center;
        }
 
       @page {
         size: A4;
         margin: 0mm;
       }

       html, body {
         width: 1024px;
       }

       body {
         margin: 0 auto;
         line-height: 1em;
         word-spacing:2px;
         letter-spacing:0.2px;
         /* font: 14px "Times New Roman", Times, serif; */
         font-size: 17px;
         background:rgba(0, 0, 0, 0.1);
padding-top: 20px;
         color:black;
         width: 100%;
         float: none;
       }
       .invoice__body table tr th{
         padding: 5px;
       }
       .footer{
         position: absolute;
         bottom: 10px;
         width: 100%;
         text-align: center;
       }
       /* avoid page-breaks inside a listingContainer*/
       .listingContainer{
         page-break-inside: avoid;
       }
       h1 {
           font: 28px "Times New Roman", Times, serif;
         }

         h2 {
           font: 26px "Times New Roman", Times, serif;
          
         }

         h3 {
           font: 20px "Times New Roman", Times, serif;
         }

         /* Improve colour contrast of links */
         a:link, a:visited {
           color: #781351
         }

         /* URL */
         a:link, a:visited {
           background: transparent;
           color:#333;
           text-decoration:none;
         }

         a[href]:after {
           content: "" !important;
         }
         a[href^="http://"] {
           color:#000;
         }

         #header {
           height:75px;
           font-size: 24pt;
           color:black
         }
       }

        </style>
        </head>
        <body onload="window.print()">${innerContents}</html>`);
popUpWindow.document.close();
    }, 500);
  }

}
