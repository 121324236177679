import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';;

@Component({
  selector: 'app-compensation-claim-register',
  templateUrl: './compensation-claim-register.component.html',
  styleUrls: ['./compensation-claim-register.component.scss']
})
export class CompensationClaimRegisterComponent extends SharedClassComponent implements OnInit {

  ngOnInit()
  {

  }

  occuationAccident()
  {
    this.router.navigateByUrl("compensation-notify-accident");
  }

  occuationDisease()
  {
    this.router.navigateByUrl("compensation-notify-disease");
  }

  occuationDeath()
  {
    this.router.navigateByUrl("compensation-notify-death");
  }

}