import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-generated-invoices-penalty-list',
  templateUrl: './generated-invoices-penalty-list.component.html',
  styleUrls: ['./generated-invoices-penalty-list.component.scss']
})
export class GeneratedInvoicesPenaltyListComponent extends SharedClassComponent implements OnInit {
 title = 'Contribution & Penalty invoice';
  tabPanelData: any = [
    {
    id: 1,
    icon: 'fa fa-calendar-plus-o',
    name: 'Contribution Invoice',
  },
    {
    id: 2,
    icon: 'fa fa-clock-o',
    name: 'Penalty Invoice',
  }
];

contributionInvoiceDataSource = [];
invoicePenaltiesDataSourse = [];
  ngOnInit() {
     // Controls the datagrid height and max rows to display
     this.observerCall();
     // sets the current page title in index.html title tag
     this.appInfo.setTitle(this.title);
  }

}
