import { Component, OnInit, NgModule, Input } from '@angular/core';
import {
  SideNavigationMenuModule,
  HeaderModule
} from '../../shared/components';
import notify from 'devextreme/ui/notify';

import { ScreenService, AuthService } from '../../shared/services';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';

import { investmentNavigation, navigation, navigation2 } from '../../app-navigation';
import { Router, NavigationEnd } from '@angular/router';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'app-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss']
})
export class SideNavOuterToolbarComponent implements OnInit {
  menuItems: any; // navigation;
  selectedRoute = '';
  data = {
    setting_id: '1008'
  };
  getIdleTimeoutByName = {
    requestType: "SETTING_BY_NAME",
    settingName: 'IDLE_TIMEOUT_IN_SECONDS'
  };
  getSettingsByNameEndpoint = 'backend/request';
  endPoint = 'settings/get';
  idleTimeout: number;

  menuOpened: boolean;
  temporaryMenuOpened = false;

  @Input()
  title: string;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;
  user: any;
  roleId: number;
  constructor(
    private bnIdle: BnNgIdleService,
    private screen: ScreenService,
    private router: Router,
    public utilities: UtilitiesService,
    public spinner: SpinnerVisibilityService,
    public authService: AuthService
  ) {
    const isLoginForm = router.url === '/login';
    // (this.user = this.authService.getUserDetails()),
    //   (this.role_id = this.user.role_id);
    this.onUserRolesNavigationssdetails();
    this.idleTimeout = 500000;
    if (!isLoginForm) {
    this.utilities.postServiceCall(this.getIdleTimeoutByName, this.getSettingsByNameEndpoint).subscribe(res => {
      const response = res.json();
      if(response.code == 2000){
        this.idleTimeout = 500000;
      } else {
        this.idleTimeout = 500000;
      }
      if (!isLoginForm) {
        this.bnIdle.startWatching(this.idleTimeout).subscribe(result => {
          if (result) {
            this.authService.logOut();
            this.bnIdle.stopTimer();
            notify('You have been logged out due to inactivity.', 'info', 60000);
          }
        });
      }
    }, error => {
      this.logServerErrors(this.router.url, error, 'LOAD_SETTINGS');
    });
  }


  }

  ngOnInit() {
    this.menuOpened = this.screen.sizes['screen-large'];

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }
  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 0;
    this.shaderEnabled = !isLarge;
  }

  logServerErrors(currentRoute, errorLog, action ?) {
    const data = {
      currentRoute,
      errorLog,
      action,
      userDetails: this.authService.getUserDetails()
    };
    this.utilities.errorLogApiCall(data).subscribe(res => {
    console.log(res);
    }, err => {
      console.log(`Failed to send error log ${err}`);
    });
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }
  // load the navigation details

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      if (event.node.selected) {
        pointerEvent.preventDefault();
      } else {
        this.router.navigate([path]);
      }

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
  }
  onUserRolesNavigationssdetails() {
    // this.spinner.show();
    const data = {
      roleId: this.roleId
    };
    if (this.authService.getUserDetails().userGroup === 'EMPLOYER' || this.authService.getUserDetails().userGroup === 'INDIVIDUAL_CONTRIBUTOR') {
      this.menuItems = navigation;
      return;
    }
    if (this.authService.getUserDetails().userGroup !== 'EMPLOYER') {
      this.menuItems = investmentNavigation;
    }

    // const userRoleId = this.authService.getUserRoles();
    // for(let i = 0; i< userRoleId.length; i++) {
    //   // Ict officer
    //   if (userRoleId[i].roleId == 810) {
    //     this.menuItems = navigation2;
    //     break;
    //   } else if (userRoleId[i].roleId == 817) {
    //     this.menuItems = navigation;
    //     break;
    //   } else {
    //     this.menuItems = [];
    //   }
    // }
    // Temporary Navigation

    // load navigation from server

    // this.utilities.postServiceCall(data, 'role/navigations').subscribe(
    //   response => {
    //     this.spinner.hide();
    //     let data = response.json();
    //     if (data.data) {
    //       this.menuItems = data.data;
    //     } else {
    //       this.menuItems = [
    //         {
    //           text: 'Dashboard',
    //           path: 'home',
    //           icon: 'home',
    //           items: [
    //             {
    //               text: 'Bills Validation',
    //               icon: 'datafield',
    //               path: 'bill-validation'
    //             }
    //           ]
    //         }
    //       ];
    //     }
    //   },
    //   error => {
    //     this.spinner.hide();
    //   }
    // );
  }
  replaceKey($subject, $newKey, $oldKey) {}
  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [
    SideNavigationMenuModule,
    DxDrawerModule,
    HeaderModule,
    DxScrollViewModule,
    CommonModule
  ],
  exports: [SideNavOuterToolbarComponent],
  declarations: [SideNavOuterToolbarComponent]
})
export class SideNavOuterToolbarModule {}
