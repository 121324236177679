import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Headers, Http} from '@angular/http';
import {AppSettings} from 'src/app/app-settings';
import {map} from 'rxjs/operators';
import {SpinnerVisibilityService} from 'ng-http-loader';
import {ToastrService} from 'ngx-toastr';
import {InvoiceComponent} from '../../Contributions/employers/invoice/invoice.component';
import { GeneratedContributionsComponent } from 'src/app/generated-contributions/generated-contributions.component';
import { MultipleContibutionInvoiceComponent } from 'src/app/Contributions/employers/multiple-contibution-invoice/multiple-contibution-invoice.component';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthService {
  loggedIn = false;
  isTemporaryLogin = false;
  rememberMe = false;
  loginCode: any;
  billControlNumber: number;

  userId: number; // , private authService:AuthService
  token: any;
  sessionData: any;
  user: any;
  constructor(private router: Router, private http: Http, private spinner: SpinnerVisibilityService,
              public toastr: ToastrService,
              private myRoute: Router) {
              }
              md5hash(data: string): string{
                return CryptoJS.MD5(data).toString();
              }
    decryptString(data) {
      if (data === null || data === undefined) {
        return data;
      }
      const bytes  = CryptoJS.AES.decrypt(data, AppSettings.XPR_TOS_PST_TEST);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }

    decryptNumberString(data) {
      if (data === null || data === undefined) {
        return data;
      }
      const bytes  = CryptoJS.AES.decrypt(data, AppSettings.XPR_TOS_PST_TEST);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }

    get isLoggedIn() {
    this.loginCode = this.decryptNumberString(sessionStorage.getItem(AppSettings.sIsLoggedInKey));
    if (this.loginCode == 2000) {
       this.loggedIn = true;
       this.isTemporaryLogin = false;
    }
    else if (this.loginCode == 2113) {
      this.isTemporaryLogin = true;
      this.loggedIn = false;
    }
    else {
       this.loggedIn = false;
    }
    return this.loggedIn;
  }

  getUserDetails() {
    const userDetails = this.decryptString(sessionStorage.getItem(AppSettings.sUserDataKey));
    return userDetails;
  }

  getActiveSession() {
    return this.decryptString(sessionStorage.getItem(AppSettings.sTokenKey));
  }

  resetUserpassword(data: any, model: string) {


    data.channel_code = AppSettings.channelCode,
    data.channel_security_code = AppSettings.channelSecurityCode;

    const postDataClone = {
      requestUrl: `${AppSettings.apiName}`+'reset-password',
      requestBody: data
    };

    const dataHash = this.md5hash(JSON.stringify(postDataClone)+AppSettings.XPR_TOS_PST_TEST);

    const headers = new Headers({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'POST',
      'X-TPS-OCT-PLAC-7': dataHash
    });
    return this.http.post(AppSettings.phpInterceptor,
      JSON.stringify(postDataClone), {headers}
    )
     .pipe(map(user => {
        return user;
      }));
  }

  logIn(systemId: string, password: string) {

     const data = {
      requestType: 'USER_LOGIN',
      username: systemId,
      password: password,
     };

     const postDataClone = {
      requestUrl: `${AppSettings.apiName}`+'portal/request',
      requestBody: data
    };

    // logIn(systemId: string, password: string) {

    //   const data = {
    //    requestType: 'USER_LOGIN',
    //    username: systemId,
    //    password: password,
    //   };
 
    //   const postDataClone = {
    //    //requestUrl: `${AppSettings.apiName}`+'portal/request',
    //    requestType: 'USER_LOGIN',
    //    username: systemId,
    //    password: password, 
    //  };

    const dataHash = this.md5hash(JSON.stringify(postDataClone)+AppSettings.XPR_TOS_PST_TEST);

    const headers = new Headers({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials' : 'true',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'POST',
      'X-TPS-OCT-PLAC-7': dataHash
    });

     return this.http.post(AppSettings.phpInterceptor,
      JSON.stringify(postDataClone), {headers}
     )
      .pipe(map(user => {
         return user;
       }));

    // return this.http.post(AppSettings.phpInterceptor+'portal/request',
    //   data, {headers}
    //   )
    //    .pipe(map(user => {
    //       return user;
    //     }));
   }

  logOut() {
    // start
    sessionStorage.removeItem(AppSettings.invoiceStorageKey);
    // this.spinner.show();
    this.loggedIn = false;
    this.user = this.getUserDetails(),
    this.sessionData = this.getActiveSession();

    this.userId = this.user.id;
    let session_data = this.getActiveSession();
    this.token = session_data.token;

    sessionStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);

    let post_data = {

      header: {
        userId: this.userId,
        token: this.token,
        channelCode: AppSettings.channelCode,
        channelSecurityCode: AppSettings.channelSecurityCode
    }
   };

   const postDataClone = {
    requestUrl: `${AppSettings.apiName}`+'signout',
    requestBody: post_data
};

    const dataHash = this.md5hash(JSON.stringify(postDataClone)+AppSettings.XPR_TOS_PST_TEST);
    let headers = new Headers({
      Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'x-auth, content-type',
    'Access-Control-Allow-Methods': 'POST',
    'X-TPS-OCT-PLAC-7': dataHash
    });
    const user = this.getUserDetails();
    const session = this.getActiveSession();

    sessionStorage.removeItem(AppSettings.sessionKey);
    sessionStorage.removeItem(AppSettings.isLoggedInKey);
    sessionStorage.removeItem(AppSettings.rememberMeKey);
    sessionStorage.removeItem(AppSettings.sTokenKey);
    sessionStorage.removeItem(AppSettings.sIsLoggedInKey);
    sessionStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
    return this.http.post(AppSettings.phpInterceptor, JSON.stringify(postDataClone) , { headers })
      .pipe(map(data => {
        sessionStorage.removeItem(AppSettings.isLoggedInKey);
        sessionStorage.removeItem(AppSettings.sTokenKey);
        sessionStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
        this.spinner.hide();
        // location.reload();
      })).subscribe();
      // end
  }

  changeUserpassword(data: any, path: string) {
    const user = this.getUserDetails();
    const session = this.getActiveSession();

    let post_data = {
        header: {
          userId: user.id,
          token: session.token,
          channelCode: AppSettings.channelCode,
          channelSecurityCode: AppSettings.channelSecurityCode
      },
      data
  };

  const postDataClone = {
    requestUrl: `${AppSettings.apiName}`+path,
    requestBody: post_data
};

    const dataHash = this.md5hash(JSON.stringify(postDataClone)+AppSettings.XPR_TOS_PST_TEST);
    let headers = new Headers({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'POST',
      'X-TPS-OCT-PLAC-7': dataHash
    });
    return this.http.post(AppSettings.phpInterceptor,
    JSON.stringify(postDataClone), {headers}
    )
    .pipe(map(resp => {
        return resp;
      }));
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const isLoggedIn = this.authService.isLoggedIn;

        const isLoginForm = route.routeConfig.path === 'login';

        const isTempLogin = this.authService.isTemporaryLogin;

        if (isLoggedIn && isLoginForm && !isTempLogin) {
          if (this.authService.getUserDetails().userGroup === 'EMPLOYER' || this.authService.getUserDetails().userGroup === 'INDIVIDUAL_CONTRIBUTOR') {
            this.router.navigate(['/']);
            return false;
          } else {
            this.router.navigate(['/customers/dashboard']);
            return false;
          }
        }
        if (!isLoggedIn && isTempLogin && isLoginForm) {
            this.router.navigate(['/change-temporary-password']);
            return false;
        }

        if (isTempLogin) {
          this.router.navigate(['/change-temporary-password']);
          return false;
        }

        if (!isLoggedIn && !isLoginForm) {
            this.router.navigate(['/login']);
        }

        // return isLoggedIn || isLoginForm || isTempLogin;
        return isLoggedIn || isLoginForm ;

    }
}

@Injectable()
export class AuthGuardServiceAdminsOnly implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private toast: ToastrService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const user = this.authService.getUserDetails();

        const expectedRole = route.data.expectedRole;
        const isLoggedIn = this.authService.isLoggedIn;
        const isLoginForm = route.routeConfig.path === 'login';

        if (isLoggedIn && isLoginForm && expectedRole.includes(user.roleId)) {
          this.router.navigate(['/']);
          return false;
      }
        if (!isLoggedIn && !isLoginForm) {
        this.toast.error('You are not authorized to access this route, login first.', 'Access Denied');
        this.router.navigate(['/login']);
    }
        if (isLoggedIn && !expectedRole.includes(user.roleId)) {
          this.toast.error('You don\'t have rights to access this route, logging you out.', 'Access Denied');
          this.authService.logOut();
      }
        return isLoggedIn || isLoginForm;

    }
}

@Injectable({providedIn: 'root'})
export class InvoiceLeaveGuard implements CanDeactivate<MultipleContibutionInvoiceComponent> {
  canDeactivate(
    component: MultipleContibutionInvoiceComponent
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.isInvoiceCreated && !component.isInvoicePostedForPayment) {
      const userAction = confirm('Warning: If you leave the page, your invoice will be discarded and all data will be lost');
      if (userAction) {
        sessionStorage.removeItem(AppSettings.invoiceBillsItems);
        sessionStorage.removeItem(AppSettings.invoiceStorageKey);
        sessionStorage.removeItem(AppSettings.invoiceDetailsKey);
      }
      return userAction;
    }
    if (component.isInvoiceCreated && component.isInvoicePostedForPayment && !component.isInvoicePrinted) {
      const userAction = confirm('Info: you\'re about to leave the page, please print the invoice or save the control number');
      if (userAction) {
        sessionStorage.removeItem(AppSettings.invoiceBillsItems);
        sessionStorage.removeItem(AppSettings.invoiceStorageKey);
        sessionStorage.removeItem(AppSettings.invoiceDetailsKey);
      }
      return userAction;
    }
    if (component.isInvoiceCreated && component.isInvoicePrinted && component.isInvoicePostedForPayment) {
      sessionStorage.removeItem(AppSettings.invoiceBillsItems);
      sessionStorage.removeItem(AppSettings.invoiceStorageKey);
      sessionStorage.removeItem(AppSettings.invoiceDetailsKey);
      return true;
    }
    return true;
  }
}

@Injectable({providedIn: 'root'})
export class GeneratedContributionsLeaveGuard implements CanDeactivate<GeneratedContributionsComponent> {
  canDeactivate(
    component: GeneratedContributionsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.contributionDataEdited) {
      return confirm('Warning: You\'re about to leave the page,  All modifications that have been done, will be discared.');
    }
    return true;
  }
}
