import { Component, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Router } from "@angular/router";
import { DxButtonModule } from "devextreme-angular/ui/button";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import notify from "devextreme/ui/notify";
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { DxPopupModule } from "devextreme-angular/ui/popup";

import { AuthService, AppInfoService } from "../../services";

import { AppSettings } from "../../../app-settings";
import { UtilitiesService } from "../../services/utilities.service";
import { EncryptionService } from "../../services/encryption.service";
@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent {
  userLoginForm: FormGroup;
  message = "";
  code: number;
  isLoggedIn: any;
  response: any;
  disableBtn = false;
  rememberMe = true;
  getEmployeeDetailsEndpoint = "employer/get";
  passwordMode: string;
  passwordButton: any;
  showAlertDialogN = false;

  constructor(
    private authService: AuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private spinner: SpinnerVisibilityService,
    public toastr: ToastrService,
    public apiCall: UtilitiesService,
    public securityService: EncryptionService
  ) {
    this.passwordMode = "password";
    this.passwordButton = {
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=",
      type: "default",
      onClick: () => {
        this.passwordMode = this.passwordMode === "text" ? "password" : "text";
      },
    };
    this.userLoginForm = new FormGroup({
      systemID: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      rememberMe: new FormControl(null),
    });
    // sets the current page title in index.html title tag
    this.appInfo.setTitle("Login");
  }

  clearStorage() {
    sessionStorage.removeItem(AppSettings.sTokenKey);
    sessionStorage.removeItem(AppSettings.sIsLoggedInKey);

    sessionStorage.clear();
    sessionStorage.removeItem(AppSettings.sessionKey);
    sessionStorage.removeItem(AppSettings.isLoggedInKey);
    sessionStorage.removeItem(AppSettings.invoiceStorageKey);

    sessionStorage.removeItem(AppSettings.userDataKey);
    sessionStorage.clear();
  }

  onLoginClick() {
    if (!this.userLoginForm.valid) {
      this.toastr.error(
        "Fill all the fields to proceed",
        "Credentials required!"
      );
      return;
    }
    this.disableBtn = true;
    this.rememberMe = this.userLoginForm.value.rememberMe === true;
    this.spinner.show();

    this.authService
      .logIn(
        this.userLoginForm.value.systemID,
        this.userLoginForm.value.password
      )
      .subscribe(
        (response) => {
          const authResponse = response.json();

          console.log(authResponse);
          this.message = authResponse.message;
          this.code = authResponse.code;
          if (this.code == 2000) {
            //notify('REMITTANCE OF ADDITIONAL ONE PERCENT (1%) OF ZSSF CONTRIBUTION FOR EMPLOYMENT INJURY BENEFIT', 'info', 6000);
            this.disableBtn = false;
            this.clearStorage();
            sessionStorage.setItem(
              AppSettings.sIsLoggedInKey,
              this.securityService.encryptString(authResponse.code)
            );
            sessionStorage.setItem(
              AppSettings.sUserDataKey,
              this.securityService.encryptString(authResponse.data.user)
            );
            sessionStorage.setItem(
              AppSettings.sTokenKey,
              this.securityService.encryptString(authResponse.data.session)
            );
            sessionStorage.setItem("fname", authResponse.data.user.firstName);
            sessionStorage.setItem("lname", authResponse.data.user.lastName);
            //console.log("OGGED USER")
            //console.log(authResponse.data)
            //sessionStorage.removeItem("showDialog")
            sessionStorage.setItem("showDialog", "true");

            if (
              authResponse.data.user.userGroup === "EMPLOYER" ||
              authResponse.data.user.userGroup === "INDIVIDUAL_CONTRIBUTOR"
            ) {
              this.router.navigate(["/home"]);
              this.toastr.info(this.message, "Success!");
              location.reload();
            } else {
              this.toastr.info(this.message, "Success!");
              this.router.navigate(["/customers/dashboard"]);
              location.reload();
            }
          } else if (this.code == 2113) {
            this.clearStorage();
            // sessionStorage.setItem(AppSettings.sIsLoggedInKey, this.securityService.encryptString(authResponse.code));
            sessionStorage.setItem(
              AppSettings.sUserDataKey,
              this.securityService.encryptString(authResponse.data.user)
            );
            sessionStorage.setItem(
              AppSettings.sTokenKey,
              this.securityService.encryptString(authResponse.data.session)
            );

            this.router.navigate(["/change-temporary-password"]);
            // sessionStorage.removeItem(AppSettings.sessionKey);
            // sessionStorage.removeItem(AppSettings.isLoggedInKey);
            // sessionStorage.clear();

            this.toastr.info(this.message);
            this.spinner.hide();
            return;
          } else {
            this.disableBtn = false;
            sessionStorage.setItem(
              AppSettings.sIsLoggedInKey,
              this.securityService.encryptString(authResponse.code)
            );
            this.toastr.error(this.message, "Alert!");
            this.spinner.hide();
            return;
            //this.logServerErrors(this.router.url, this.message, 'USER_LOGIN');
          }
          this.spinner.hide();
        },
        (error) => {
          this.disableBtn = false;
          this.logServerErrors(this.router.url, error, "USER_LOGIN");
          //this.toastr.info('Something has went wrong, please try again');
          this.spinner.hide();
        }
      );
  }

  logServerErrors(currentRoute, errorLog, action?) {
    const data = {
      currentRoute,
      errorLog,
      action,
      userDetails: "",
    };
    this.apiCall.errorLogApiCall(data).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(`Failed to send error log ${err}`);
      }
    );
  }

  closeDialog() {
    this.showAlertDialogN = false;
  }

  funcpopWidth(percentage_width) {
    if (window.innerWidth > 800) {
      return (window.innerWidth * percentage_width) / 100;
    } else {
      return window.innerWidth - 50;
    }
  }
  // set the widget height
  funcpopHeigt(height) {
    return (window.innerHeight * height) / 100;
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxButtonModule,
    DxPopupModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent],
})
export class LoginFormModule {}
