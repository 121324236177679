import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from '../../shared-class/shared-class.component';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html'
})
export class PermissionsComponent  extends SharedClassComponent implements OnInit {
  title:string= 'System Group Permissions';
  model_name:string='permissions';
  //variables defination
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  userRolesDta:any;
  param_id:any;
  response:any;
  navigationLevels:any = [{
    id:1,
    name:'Level One'
  },{
    id:2,
    name:'Level Two'
  },{
    id:2,
    name:'Level Three'
  }]

  navigationTypes:any= [{
    id:1,
    name:'Online Services'
  }]
  isParentHidden:boolean = true;
  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    this.paramwinfrm =  new FormGroup({
      role_id: new FormControl('', Validators.compose([Validators.required])),
      navigation_id: new FormControl('', Validators.compose([Validators.required])),
      accesslevel_id: new FormControl('', Validators.compose([])),
      is_disabled: new FormControl('', Validators.compose([])),
      is_online: new FormControl('', Validators.compose([])),
      status: new FormControl('', Validators.compose([])),

      code: new FormControl('', Validators.compose([])),  id: new FormControl('', Validators.compose([]))
    });
    // Controls the datagrid height and max rows to display
    this.observerCall();
    this.onGetParamsdetails();
    this.onGetSystemNavParamsdetails();
    this.onGetuserAccessLevelParamsdetails();
    this.onGetOtheRolesdetails('roles','');

  }
  onChangeNavigationLevel(e){
      this.isParentHidden = true;
      if(e.value > 1){
        this.isParentHidden = false;
      }

  }
}
