import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
// import * as xmljs from 'xml-js';
import { AppSettings } from 'src/app/app-settings';
import {SpinnerVisibilityService} from 'ng-http-loader';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  config: any;
  generatedOTP: any;
  user = 'jkhfuighuigf';
  password = '7565657676676756';
  sender = 'uhiogfuihgh';
  baseUrl = 'ret://api.jkhfgd.m/a/fdgfg/plain';

  constructor(private httpClient: HttpClient, private spinner: SpinnerVisibilityService, public toastr: ToastrService) { }

  generateOTP(): string {
    const digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 6; i++ ) {
      OTP += digits[Math.floor(Math.random() * 10)];
  }
    return OTP;
  }

  verifyOTP(code) {
    const OTP = this.decryptNumberString(sessionStorage.getItem(AppSettings.OTPkey));
    if (OTP == code) {
      return true;
    } else {
      return false;
    }
  }

  // sendOTP(phoneNumber) {
  //   this.callSMSgateway(phoneNumber).subscribe(res => {
  //     setTimeout(() => {
  //       sessionStorage.removeItem(AppSettings.OTPkey);
  //     }, 300000);
  //     const obj = JSON.parse(xmljs.xml2json(res, {compact: true, spaces: 4}));
  //     const status = obj.results.result.status._text;
  //     if (status == 0) {
  //       this.toastr.success('Message sent successfuly to your mobile number');
  //     } else {
  //       this.toastr.error('Failed to send sms, try again later');
  //     }
  //   }, error => {
  //     this.toastr.error(error);
  //   });
  // }

  // sendOTPapi(phoneNumber, OTP) {
  //   this.callSMSgatewayApi(phoneNumber, OTP).subscribe(res => {
  //     setTimeout(() => {
  //       sessionStorage.removeItem(AppSettings.OTPkey);
  //     }, 300000);
  //     const obj = JSON.parse(xmljs.xml2json(res, {compact: true, spaces: 4}));
  //     const status = obj.results.result.status._text;

  //     if (status == 0) {
  //       this.toastr.success('Message sent successfuly to your mobile number');
  //     } else {
  //       this.toastr.error('Failed to send sms, try again later');
  //     }
  //   }, error => {
  //     this.toastr.error(error);
  //   });
  // }

  resendOTP() {

  }

  callSMSgateway(phoneNumber) {

    const headers = new HttpHeaders({
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'GET'
    });

    const OTP = this.generateOTP();
    sessionStorage.removeItem(AppSettings.OTPkey);
    sessionStorage.setItem(AppSettings.OTPkey, this.encryptString(OTP));
    // tslint:disable-next-line: max-line-length
    const message = `Dear our valued customer, your OTP code for account registration in ZSSF employers self-service portal is ${this.decryptNumberString(sessionStorage.getItem(AppSettings.OTPkey))}`;

    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('user', this.user);
    params = params.append('password', this.password);
    params = params.append('sender', this.sender);
    params = params.append('SMSText', message);
    params = params.append('GSM', phoneNumber);

    return this.httpClient.get(this.baseUrl, { params , responseType: 'text', headers } ).pipe(map(res => {
      return res;
   }));
  }
  callSMSgatewayApi(phoneNumber, code) {

    const headers = new HttpHeaders({
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'GET'
    });

    // const OTP = this.generateOTP();
    sessionStorage.removeItem(AppSettings.OTPkey);
    sessionStorage.setItem(AppSettings.OTPkey, this.encryptString(code));
    // tslint:disable-next-line: max-line-length
    const message = `Dear our valued customer, your OTP code for account registration in ZSSF employers self-service portal is ${this.decryptNumberString(sessionStorage.getItem(AppSettings.OTPkey))}`;

    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('user', this.user);
    params = params.append('password', this.password);
    params = params.append('sender', this.sender);
    params = params.append('SMSText', message);
    params = params.append('GSM', phoneNumber);

    return this.httpClient.get(this.baseUrl, { params , responseType: 'text', headers } ).pipe(map(res => {
      return res;
   }));
  }

  encryptString(data): string {
    const ciphertext = CryptoJS.AES.encrypt(data, AppSettings.XPR_TOS_PST_TEST).toString();
    return ciphertext;
    }

  decryptNumberString(data) {
    if (data === null || data === undefined) {
      return data;
    }
    const bytes  = CryptoJS.AES.decrypt(data, AppSettings.XPR_TOS_PST_TEST);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }


}
