import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from '../shared-class/shared-class.component';
import { jsPDF } from "jspdf";
import { confirm } from 'devextreme/ui/dialog';



@Component({
  selector: 'app-currency-denominations',
  templateUrl: './currency-denominations.component.html',
  styleUrls: ['./currency-denominations.component.scss']
})
export class CurrencyDenominationsComponent extends SharedClassComponent implements OnInit {
  title = 'Invoice Payments';
  apiEndpoint = 'employer/bills';
  InvoicePaymentsDataSource = [];
  hidePrintMembers = false;

  employerName = null;
  invoiceNumber = null;
  clearanceDate = null;
  description = null;
  employerNumber = null;
  controlNumber = null;
  paymentOpt = null;
  currency = null;
  amount = null;
  receiptNumber = null;
  hideReceiptTemplate = true;

  // invoice items
  billDescription = '';
  totalAmountPaid: any;
  payableBanks = '';
  totalAmountContributed: any;
  employerPhone: any;
  employerEmail: any;
  paymentType: any;
  invoiceDescription: any;
  invoiceCurrency: any;
  billExpireDate = '';
  invoiceDate: any;
  qrCodeString = '';
  hideQrCode = true;
  invoiceBillItems = [];
  userGroup: any;
  now = new Date();
  currentYear = new Date().getFullYear();
  navReceipt = '';
  amountInWorlds = '';

  hideViewControls = false;
  isPenaltyInvoice = false;
  AMOUNT: any;
  ID: any;
  DESCRIPTION: any;
  CURRENCY: any;
  individualBillDescription: any;
  contributedMembers = [];
  invoiceID = '';
  callEndpoint = 'portal/request';
  billItems: any;
  id: any;
  billAmount: any;
  amountDue: any;
  amountPaid: any;
  billDesc: any;
  billExDate: any;
  catCode: any;
  catName: any;
  controlNo: any;
  createdBy: any;
  creationDate: any;
  cuurency: any;
  deptCode: any;
  deptName: any;
  payerEmail: any;
  payerMobile: any;
  payerName: any;
  pmtOption: any;
  payerTel: any;
  invoiceNo: any;
  customerNo: any;
  zmControlNo: any;
  zmExpDate: any;
  zmCreationDate: any;
  cleared: any;
  crAcc: any;
  pmtMethod: any;
  psp: any;
  pspRef: any;
  amountWords: any;
  billItemsOrderForm: any;
  amountPaidWords: any;
  fname =sessionStorage.getItem("fname")
  lname =sessionStorage.getItem("lname")
  printedDate: string;
  todayDate = new Date()
  val:any
  hideControlNumber: boolean=false;

  ngOnInit() {
    this.changeFormat(this.todayDate)
   // this.printBillInvoiceNEWTRIGGER()
    this.employerNumber = this.authService.getUserDetails().linkId;
    this.employerName = this.authService.getUserDetails().accountName;
    this.userGroup = this.authService.getUserDetails().userGroup;
    if (`${this.userGroup}`.match('INDIVIDUAL_CONTRIBUTOR')) {
      this.hideViewControls = true;
    }
    if (!`${this.userGroup}`.match('EMPLOYER') && !`${this.userGroup}`.match('INDIVIDUAL_CONTRIBUTOR')) {
      this.hidePrintMembers = true;
    }
    this.appInfo.setTitle(this.title);
    this.observerCall();
    // this.getEmployeesList(this.authService.getUserDetails().linkId);
    this.getEmployerBills();
  }

  changeFormat(data:any){
    //this.printedDate = new Date(data).getFullYear() + "-"+  ("0"+(new Date(data).getMonth()+1)).slice(-2) + "-" +("0" + new Date(data).getDate()).slice(-2);
    this.printedDate =("0" + new Date(data).getDate()).slice(-2)+"-"+("0"+(new Date(data).getMonth()+1)).slice(-2) + "-" +new Date(data).getFullYear();
   
  }

  deleteInvoice(e) {
    console.log(e);
    const result = confirm(
      'Do you want to delete selected invoice ? Click Yes to continue',
      'Discard Changes'
    );

    result.then(dialogResult => {
      if (dialogResult) {
        const data = {
          requestType: 'INVOICE_DELETE',
          invoiceRef: e.INVOICE_ID
        };

        this.spinner.show();
        this.utilities.postServiceCall(data, this.callEndpoint).subscribe(res => {
          const serverResp = res.json();
          this.spinner.hide();
          if (serverResp.code == 2000) {
            this.toastr.success(serverResp.message, 'Invoice Delete');
            this.getEmployerBills();
          } else {
            this.toastr.error(serverResp.message, 'Invoice Delete');
            this.logServerErrors(this.router.url, serverResp.message, 'INVOICE_DELETE');
          }
        }, err => {
          this.spinner.hide();
          this.logServerErrors(this.router.url, err, 'INVOICE_DELETE');
          this.toastr.success('Something went wrong while processing the request.', 'Request Failed');
        });
      }
  });

  }

  getEmployerBills() {
    this.spinner.show();
    const data = {
      requestType: 'BILLS_LIST',
      systemId: this.authService.getUserDetails().systemId
    };

    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      this.spinner.hide();
      const serverRes = res.json();
      if (serverRes.code == 2000) {
        this.InvoicePaymentsDataSource = serverRes.data;
      } else {
        this.toastr.error(serverRes.message);
        this.logServerErrors(this.router.url, serverRes.message, 'BILLS_LIST');
      }
    }, error => {
      this.spinner.hide();
      this.logServerErrors(this.router.url, error, 'BILLS_LIST');});
  }

  printPaymentReceipt(e) {
    this.spinner.show();
    this.employerName = this.authService.getUserDetails().accountName;
    this.invoiceNumber = e.INVOICE_NO;
    this.clearanceDate = e.CLEARANCE_DATE;
    this.description = e.DESCRIPTION;
    this.employerNumber = this.authService.getUserDetails().linkId;
    this.controlNumber = e.CONTROL_NUMBER;
    this.paymentOpt = e.PAYMENT_OPTION;
    this.currency = e.CURRENCY;
    this.amount = e.AMOUNT_PAID;
    this.receiptNumber = e.ID;
    this.navReceipt = e.NAV_RECEIPT;
    this.invoiceID = e.INVOICE_ID;
    this.amountInWorlds = e.AMOUNT_WORDS;


    setTimeout(()=> {
      this.printInvoiceReceipt();
      this.spinner.hide();
    }, 3000);
  }

  onToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getEmployerBills.bind(this)
        }
      }
    );
  }
  printBillInvoiceNEWTRIGGER()
  {
    const data={
      requestType:"BILLS_GET",
      billRef: "55"
    }

    this.utilities.postServiceCall(data,'portal/request').subscribe(res => {
      
     
      const serverResponse = res.json()
      console.log(serverResponse)
      
      this.spinner.hide();
    }, error => {
      
      this.spinner.hide();
    });

  }

  printBillInvoiceNEW(e) {
   
    this.invoiceBillItems = [];
    this.controlNo = e.CONTROL_NUMBER;
    this.zmControlNo =e.ZM_CONTROL_NO
    this.invoiceNo = e.INVOICE_NO
    this.payerName = e.PAYER_NAME
    this.payerMobile = e.MOBILE_NO
    this.amountWords = e.AMOUNT_WORDS
    this.billExDate =e.EXPIRE_DATE
    this.createdBy= e.CREATED_BY
    this.creationDate=e.CREATION_DATE
    this.billDesc = e.DESCRIPTION
    this.billAmount = e.AMOUNT
    this.billItems =e.billItems
   
        if (this.controlNo && this.controlNo.startsWith('55')) {
      this.hideControlNumber=true;
       } else {
         this.hideControlNumber=false;
       }
    this.spinner.show();
   
    const data={
      requestType:"BILLS_GET",
      billRef: e.CONTROL_NUMBER
    }

    // this.utilities.postServiceCall(data,'portal/request').subscribe(res => {
      
     
    //   const serverResponse = res.json()
    //   console.log(serverResponse)
    //   if (serverResponse.code == 2000) {
     
    //     this.id = serverResponse.data.id;
        
    //     this.billAmount = serverResponse.data.billAmount;
    //     this.amountDue = serverResponse.data.amountDue;
    //     this.amountPaid = serverResponse.data.amountPaid;
    //     this.billDesc = serverResponse.data.billDesc;
    //     this.billExDate = serverResponse.data.billExDate;
    //     this.catCode = serverResponse.data.catCode;
    //     this.catName = serverResponse.data.catName;
    //     this.controlNo = serverResponse.data.controlNo;
    //     this.createdBy = serverResponse.data.createdBy; 
    //     this.creationDate = serverResponse.data.creationDate;
    //     this.cuurency = serverResponse.data.ccuurency;
    //     this.deptCode = serverResponse.data.deptCode;
    //     this.deptName = serverResponse.data.deptName;
    //     this.payerEmail = serverResponse.data.payerEmail;
    //     this.payerMobile = serverResponse.data.payerMobile;
    //     this.payerName = serverResponse.data.payerName;
    //     this.pmtOption = serverResponse.data.pmtOption;
    //     this.payerTel = serverResponse.data.payerTel;
    //     this.invoiceNo = serverResponse.data.invoiceNo;
    //     this.clearanceDate = serverResponse.data.clearanceDate;
    //     this.customerNo = serverResponse.data.customerNo;
    //     this.zmControlNo = serverResponse.data.zmControlNo;
    //     this.zmExpDate = serverResponse.data.zmExpDate;
    //     this.zmCreationDate = serverResponse.data.zmCreationDate;
    //     this.cleared = serverResponse.data.cleared;
    //     this.crAcc = serverResponse.data.crAcc;
    //     this.pmtMethod = serverResponse.data.pmtMethod;
    //     this.psp = serverResponse.data.psp;
    //     this.pspRef = serverResponse.data.pspRef;
    //     this.navReceipt = serverResponse.data.navReceipt;
    //     this.amountWords = serverResponse.data.amountWords;
    //     this.billItemsOrderForm =serverResponse.data.billItems
    //     this.billItems =serverResponse.data.billItems
    //     this.amountPaidWords = serverResponse.data.amountPaidWords;

       
    //   } else {
              
    //   }
    //   this.spinner.hide();
    // }, error => {
    //   this.toastr.error('Something went wrong please try again.');
    //   this.spinner.hide();
    // });

     setTimeout(()=> {

      this.printBillFormNEW();
      this.spinner.hide();
     }, 3000);
  }


  printPaymentReceiptZanMalipo(e) {
    console.log(e)
    this.spinner.show();
   
    this.invoiceBillItems = [];
    this.controlNo = e.CONTROL_NUMBER;
    this.zmControlNo =e.ZM_CONTROL_NO
    this.invoiceNo = e.INVOICE_NO
    this.payerName = e.PAYER_NAME
    this.payerMobile = e.MOBILE_NO
    this.amountPaidWords = e.AMOUNT_WORDS
    this.billExDate =e.EXPIRE_DATE
    this.createdBy= e.CREATED_BY
    this.creationDate=e.CREATION_DATE
    this.description = e.DESCRIPTION
    this.billAmount = e.AMOUNT
    this.pspRef = e.PSP_REFERENCE_ID
    this.clearanceDate=e.CLEARANCE_DATE
    this.amountDue = e.AMOUNT_DUE;
    this.amountPaid = e.AMOUNT_PAID
    this.id =e.ID


    this.billItems =e.billItems

    const data={
      requestType:"BILLS_GET",
      billRef: e.CONTROL_NUMBER
    }

    // this.utilities.postServiceCall(data,'portal/request').subscribe(res => {

    //    const serverResponse = res.json()
    //    console.log(serverResponse)
    //    if (serverResponse.code == 2000) {
      
    //      this.id = serverResponse.data.id;
         
    //      this.billAmount = serverResponse.data.billAmount;
    //      this.amountDue = serverResponse.data.amountDue;
    //      this.amountPaid = serverResponse.data.amountPaid;
    //      this.billDesc = serverResponse.data.billDesc;
    //      this.billExDate = serverResponse.data.billExDate;
    //      this.catCode = serverResponse.data.catCode;
    //      this.catName = serverResponse.data.catName;
    //      this.controlNo = serverResponse.data.controlNo;
    //      this.createdBy = serverResponse.data.createdBy;
    //      this.creationDate = serverResponse.data.creationDate;
    //      this.cuurency = serverResponse.data.cuurency;
    //      this.deptCode = serverResponse.data.deptCode;
    //      this.deptName = serverResponse.data.deptName;
    //      this.payerEmail = serverResponse.data.payerEmail;
    //      this.payerMobile = serverResponse.data.payerMobile;
    //      this.payerName = serverResponse.data.payerName;
    //      this.pmtOption = serverResponse.data.pmtOption;
    //      this.payerTel = serverResponse.data.payerTel;
    //      this.invoiceNo = serverResponse.data.invoiceNo;
    //      this.clearanceDate = serverResponse.data.clearanceDate;
    //      this.customerNo = serverResponse.data.customerNo;
    //      this.zmControlNo = serverResponse.data.zmControlNo;
    //      this.zmExpDate = serverResponse.data.zmExpDate;
    //      this.zmCreationDate = serverResponse.data.zmCreationDate;
    //      this.cleared = serverResponse.data.cleared;
    //      this.crAcc = serverResponse.data.crAcc;
    //      this.pmtMethod = serverResponse.data.pmtMethod;
    //      this.psp = serverResponse.data.psp;
    //      this.pspRef = serverResponse.data.pspRef;
    //      this.navReceipt = serverResponse.data.navReceipt;
    //      this.amountWords = serverResponse.data.amountWords;
    //      this.billItemsOrderForm =serverResponse.data.billItems
    //      this.billItems =serverResponse.data.billItems
    //      this.amountPaidWords = serverResponse.data.amountPaidWords;
 
        
    //    } else {
        
         
    //    }
    //   this.spinner.hide();
    // }, error => {
    //   this.toastr.error('Something went wrong please try again.');
    //   this.spinner.hide();
    // });
    setTimeout(()=> {
      this.printInvoiceReceiptZAN();
      this.spinner.hide();
    }, 3000);
  }


  printBillInvoice(e) {
 
    this.spinner.show();
    this.invoiceBillItems = [];
    this.controlNumber = e.CONTROL_NUMBER;
    this.totalAmountContributed = e.AMOUNT;
    this.invoiceCurrency = e.CURRENCY;
    this.invoiceDate = e.CREATION_DATE;
    this.employerNumber = this.authService.getUserDetails().linkId;
    this.employerName = this.authService.getUserDetails().accountName;
    this.qrCodeString = `{"controlNumber":${e.CONTROL_NUMBER};"totalAmount":${e.AMOUNT};"currency":${e.CURRENCY};}`;
    this.hideQrCode = false;
    this.payableBanks = 'PBZ, CRDB, NMB';

    if (e.CATEGORY_CODE === 'FUMIS_PENALTIES') {
      this.isPenaltyInvoice = true;
      this.AMOUNT = e.AMOUNT;
      this.ID = e.ID;
      this.DESCRIPTION = e.DESCRIPTION;
      this.CURRENCY = e.CURRENCY;
    }
    this.individualBillDescription = e.DESCRIPTION;
    this.invoiceBillItems.push({
       ID: e.ID,
       DESCRIPTION: e.DESCRIPTION,
       individualBillDescription: e.DESCRIPTION,
       invoiceCurrency: e.CURRENCY,
       AMOUNT: e.AMOUNT,
       CURRENCY: e.CURRENCY
    });
    setTimeout(() => {
      this.printBillForm();
      this.spinner.hide();
    }, 3000);
  }

  printBillForm() {
    let popUpWindow;
    this.hideReceiptTemplate = false;
    const innerContents = document.getElementById('print-container3').innerHTML;
    this.hideReceiptTemplate = false;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
           <style>
           body{
            font-size: 14px;
            line-height: 1.42857143;
            color: #333;
          }
          .space{
            display: flex;
            justify-content: space-between;
            border: 1px dotted #4d88bb;
            padding: 1rem 0;
          }
          .logo__block{
          /* border-bottom: 1px dotted #4d88bb; */
          border-bottom: 1px dotted rgb(0 172 238);
          }
          .logo__block p, .footer, p {
            /* color: #4d88bb; */
            color: rgb(0 172 238);
          }
          .invoice__head__child_contacts{
            margin: 19px;
          }
          .invoice_wrapper {
            position: absolute;
          }
          .invoice_wrapper, .invoice{
            /*background-color: rgba(0, 0, 0, 0.055);*/
            padding: 2rem;
            width: fit-content;
            height: 100vh;
          }
          .invoice__head,
          .invoice__how_to_pay{
            display: flex;
            justify-content: space-between;
          }
          .invoice__body{
            display: flex;
            /* justify-content: center; */
          }
          .invoice__body table tr th{
            background: rgb(0 172 238);
            color:white;
            text-align:left;
            vertical-align:center;
          }
          .invoice__body table tr {
            background-color: rgba(136 ,136 ,136, .1);
            border: 1px solid #ddd;
            padding: .35em;
          }
          .logo__block, .footer{
              text-align: center;
          }
          .full_table{
            width: 100%;
          }
          .divide {
            position: relative;
            min-height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          /* .watermark {
          position:fixed;
          font-size: 88px;
          bottom: 0;
          opacity:0.5;
          z-index:99;
          color: #35313126;
          text-align: center;
          } */
          table tbody tr td{
          padding: .5rem;
          }

          @media print {

          @page {
            size: A4;
            margin: 0mm;
          }

          html, body {
            width: 1024px;
          }

          body {
            margin: 0 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            /* font: 14px "Times New Roman", Times, serif; */
            font-size: 17px;
            background:rgba(0, 0, 0, 0.1);
            color:black;
            width: 100%;
            float: none;
          }
          .invoice__body table tr th{
            padding: 5px;
          }
          .footer{
            position: absolute;
            bottom: 10px;
            width: 100%;
            text-align: center;
          }
          /* avoid page-breaks inside a listingContainer*/
          .listingContainer{
            page-break-inside: avoid;
          }
          h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }

            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }

           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
  }

  printInvoiceReceipt() {
    let popUpWindow;
    this.hideReceiptTemplate = false;
    const innerContents = document.getElementById('print-container2').innerHTML;
    this.hideReceiptTemplate = false;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
           <head>
           <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
           <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css">
           <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css" integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2" crossorigin="anonymous">
           <style>
          p span, .stamp span{
            display: block;
          }
          .respect, .recieved-from, .amount-words{
            margin-right: 30px;
          }
          .reciever-name{
            border: none;
            border-bottom: 1px dashed black;
            display:block;
            width: 100%;
          }
          .cover{
            position: absolute;
            top: 11%;
            left: 35%;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.05;
          }
          .app{
            background-color: lightgoldenrodyellow;
          }
          h1, p{
            color: #0c713dbf;
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
          }
          .group{
            display: flex;
            justify-content: space-between;
          }
          .full_table{
            width: 100%;
          }
          .paid{
              /* z-index: 90000; */
              position: absolute;
              top: 12%;
              /* right: 2%; */
              left: 43%;
              width: 280px;
              height: 280p;
              background-repeat: no-repeat;
              background-position: center;
              opacity: .13;
          }
          @media print {

          @page {
            size: A4;
            margin: 0mm;
          }

          html, body {
            width: 1024px;
          }

          body {
            margin: 0 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            /* font: 14px "Times New Roman", Times, serif; */
            width: 100%;
            float: none;
          }


          /* avoid page-breaks inside a listingContainer*/
          .listingContainer{
            page-break-inside: avoid;
          }
          h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }

            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }

           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
    this.hideReceiptTemplate = true;
  }


  generatePdf(e){
    this.spinner.show();
    this.invoiceBillItems = [];
    this.controlNumber = e.CONTROL_NUMBER;
    this.totalAmountContributed = e.AMOUNT;
    this.invoiceCurrency = e.CURRENCY;
    this.invoiceDate = e.CREATION_DATE;
    this.employerNumber = this.authService.getUserDetails().linkId;
    this.employerName = this.authService.getUserDetails().accountName;
    this.qrCodeString = `{"controlNumber":${e.CONTROL_NUMBER};"totalAmount":${e.AMOUNT};"currency":${e.CURRENCY};}`;
    this.hideQrCode = false;
    this.payableBanks = 'PBZ, CRDB, NMB';

    if (e.CATEGORY_CODE === 'FUMIS_PENALTIES') {
      this.isPenaltyInvoice = true;
      this.AMOUNT = e.AMOUNT;
      this.ID = e.ID;
      this.DESCRIPTION = e.DESCRIPTION;
      this.CURRENCY = e.CURRENCY;
    }
    this.individualBillDescription = e.DESCRIPTION;
    this.invoiceBillItems.push({
       ID: e.ID,
       DESCRIPTION: e.DESCRIPTION,
       individualBillDescription: e.DESCRIPTION,
       invoiceCurrency: e.CURRENCY,
       AMOUNT: e.AMOUNT,
       CURRENCY: e.CURRENCY
    });
    setTimeout(() => {
      this.printBillForm();
      this.spinner.hide();
    }, 3000);
  }

  onPdfGenerationHandler() {
    const pdf = new jsPDF();

    pdf.text
  }


getMembersList(e, flag) {
    this.employerName = this.authService.getUserDetails().accountName;
    this.invoiceNumber = e.INVOICE_NO;
    this.clearanceDate = e.CLEARANCE_DATE;
    this.description = e.DESCRIPTION;
    this.employerNumber = this.authService.getUserDetails().linkId;
    this.controlNumber = e.CONTROL_NUMBER;
    this.paymentOpt = e.PAYMENT_OPTION;
    this.currency = e.CURRENCY;
    this.amount = e.AMOUNT;
    this.receiptNumber = e.ID;
    this.navReceipt = e.NAV_RECEIPT;
    this.invoiceID = e.INVOICE_ID;

    this.spinner.show();

    let requestType = '';

    if(flag == 1) {
      requestType = 'INVOICE_INDIVIDUAL_CONTRIBUTIONS'; 
    } else {
      requestType = 'INVOICE_INDIVIDUAL_CONTRIBUTIONS_INVOICES';
    }

    const data = {
      requestType,
      invoiceRef: e.INVOICE_ID
     };

    
    this.utilities.postServiceCall(data, this.callEndpoint).subscribe(res => {
      const serverResponse = res.json();
      const data = {};
      let counter = 0;
      this.contributedMembers = [];
      if (serverResponse.code == 2000) {
        for (const el of serverResponse.data.contributedMembers) {
          if(flag == 1) {
            this.contributedMembers.push({
              counter: counter + 1,
              memberNumber: el.memberNumber,
              memberNames: `${el.firstName} ${el.middleName} ${el.surName}`,
              contributionYear: `${el.contributingPeriod}`.substring(0, 4),
              contributionMonth: this.contributionInWords(`${el.contributingPeriod}`.substring(4)),
              amountContributed: el.amountContributed,
              memberSalary: el.baseAmount
            });
          } else {
            this.contributedMembers.push({
              counter: counter + 1,
              memberNumber: el.memberNumber,
              memberNames: el.memberNames,
              contributionYear: `${el.contributingPeriod}`.substring(0, 4),
              contributionMonth: this.contributionInWords(`${el.contributingPeriod}`.substring(4)),
              amountContributed: el.amountContributed,
              memberSalary: el.baseAmount
            });
          }
         counter++;
        }
        setTimeout(() => {
          this.printMembersList(flag);
          this.spinner.hide();
        }, 3000);
        // this.hidePrintMembersButton = false;
      } else {
        this.toastr.error(serverResponse.message);
        this.logServerErrors(this.router.url, serverResponse.message, requestType);
        this.spinner.hide();
      }
    }, error => {
      this.logServerErrors(this.router.url, error, requestType);
      this.spinner.hide();
    });
   }

contributionInWords(month) {
    if (month == 1 || month == '01') {
      return 'January';
    } else if (month == 2 || month == '02') {
      return 'February';
    } else if (month == 3 || month == '03') {
      return 'March';
    } else if (month == 4 || month == '04') {
      return 'April';
    } else if (month == 5 || month == '05') {
      return 'May';
    } else if (month == 6 || month == '06') {
      return 'June';
    } else if (month == 7 || month == '07') {
      return 'July';
    } else if (month == 8 || month == '08') {
      return 'August';
    } else if (month == 9 || month == '09') {
      return 'September';
    } else if (month == 10 || month == 10) {
      return 'October';
    } else if (month == 11 || month == 11) {
      return 'November';
    } else if (month == 12 || month == 12) {
      return 'December';
    }
}

printMembersList(flag) {
  let popUpWindow;

  const innerContents = flag === 1 ? document.getElementById('print-container4').innerHTML : document.getElementById('print-container5').innerHTML;
console.log("print members ");


  popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
  // popUpWindow.document.open();
  popUpWindow.document.write(`<html lang="en">
         <head>
         <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
         <style>
         body{
          font-size: 14px;
          line-height: 1.42857143;
          color: #333;
        }
        .logo__block{
        border-bottom: 1px dotted rgb(0 172 238);
        }
        .logo__block p, .footer, p {
          color: rgb(0 172 238);
        }
        .invoice{
          background-color: rgba(0, 0, 0, 0.055);
          padding: 2rem;
          width: fit-content;
          height: 100vh;
        }
        .invoice__body{
          display: flex;
        }
        .invoice__body table tr th{
          background: rgb(0 172 238);
          color:white;
          text-align:left;
          vertical-align:center;
        }
        .invoice__body table tr {
          background-color: rgba(136 ,136 ,136, .1);
          border: 1px solid #ddd;
          padding: .35em;
        }
        .logo__block, .footer{
            text-align: center;
        }
        .full_table{
          width: 100%;
        }
        table tbody tr td{
        padding: .5rem;
        }

        @media print {

        @page {
          size: A4;
          margin: 0mm;
        }

        html, body {
          width: 1024px;
        }

        body {
          margin: 0 auto;
          line-height: 1em;
          word-spacing:2px;
          letter-spacing:0.2px;
          /* font: 14px "Times New Roman", Times, serif; */
          font-size: 17px;
          /* background:rgba(0, 0, 0, 0.055); */
          color:black;
          width: 100%;
          float: none;
        }
        table { page-break-inside:auto }
        tr    { page-break-inside:avoid; page-break-after:auto }
        thead { display:table-header-group }
        tfoot { display:table-footer-group }
        .invoice__body table tr th{
          padding: 5px;
        }
        .footer{
          position: absolute;
          bottom: 10px;
          width: 100%;
          text-align: center;
        }
        .invoice{
          background-color: transparent;
        }

        /* avoid page-breaks inside a listingContainer*/
        .listingContainer{
          page-break-inside: avoid;
        }
        h1 {
            font: 28px "Times New Roman", Times, serif;
          }

          h2 {
            font: 24px "Times New Roman", Times, serif;
          }

          h3 {
            font: 20px "Times New Roman", Times, serif;
          }

          /* Improve colour contrast of links */
          a:link, a:visited {
            color: #781351
          }

          /* URL */
          a:link, a:visited {
            background: transparent;
            color:#333;
            text-decoration:none;
          }

          a[href]:after {
            content: "" !important;
          }
          a[href^="http://"] {
            color:#000;
          }

          #header {
            height:75px;
            font-size: 24pt;
            color:black
          }
        }

         </style>
         </head>
         <body onload="window.print()">${innerContents}</html>`);
  popUpWindow.document.close();
}

printBillFormNEW() {
  let popUpWindow;
  this.hideReceiptTemplate = false;
  const innerContents = document.getElementById('print-container-new').innerHTML;
  this.hideReceiptTemplate = false;
  popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
  popUpWindow.document.open();
  popUpWindow.document.write(`<html lang="en">
         <head>
         <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-iYQeCzEYFbKjA/T2uDLTpkwGzCiq6soy8tYaI1GyVh/UjpbCx/TYkiZhlZB6+fzT" crossorigin="anonymous"><!-- JavaScript Bundle with Popper -->
         <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-u1OknCvxWvY5kfmNBILK2hRnQC3Pr17a+RTT6rIHI7NnikvbZlHgTPOOmMi466C8" crossorigin="anonymous"></script>
         <style>
         hr {
          border:none;
          border-top:1px dashed #555;
          color:#fff;
          background-color:#fff;
          height:1px;
        }
        body{
          display: flex;
          align-items: center;
          justify-content: center;
          color: #222;
          font-size: 15px;
          line-height: 1.75;
          padding: 0.5em 2em;
        }
        div.main{
          width: 210mm;
          /*box-shadow: 0 0 3px 3px #777;*/
        }
        .tbtxt tr td:nth-child(2){
          padding-left: 1em;
        }
        .tbtxt tr td:nth-child(1){
          color: #000;
          font-weight: 500;
        }
        .tbdtails tr td:nth-child(3){
          float: right;
        }
        .heading{
          margin-top: 1em;
          font-weight: bold;
        }
        table.tbdtails {
          width: 100%;
        }
        table.tbdtails tr:last-child{
          background: #f4f4f7;
          font-weight: 500;
        }
        table td, table td * {
          vertical-align: top;
        }
        table.tbmnos{
          font-size: 0.8em;
        }
      
        table.tbmnos tr td{
          width: 33%;
        }
        @media print {
          .pagebreak { page-break-before: always; } 
        }
         </style>
         </head>
         <body onload="window.print()">${innerContents}</html>`);
  popUpWindow.document.close();
}


printInvoiceReceiptZAN() {
  let popUpWindow;
  this.hideReceiptTemplate = false;
  const innerContents = document.getElementById('print-container-receipt').innerHTML;
  this.hideReceiptTemplate = false;
  popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
  // popUpWindow.document.open();
  popUpWindow.document.write(`<html lang="en">
         <head>
         <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
         <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css">
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-iYQeCzEYFbKjA/T2uDLTpkwGzCiq6soy8tYaI1GyVh/UjpbCx/TYkiZhlZB6+fzT" crossorigin="anonymous"><!-- JavaScript Bundle with Popper -->
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-u1OknCvxWvY5kfmNBILK2hRnQC3Pr17a+RTT6rIHI7NnikvbZlHgTPOOmMi466C8" crossorigin="anonymous"></script>
         
         <style>
         hr {
          border:none;
          border-top:1px dashed #555;
          color:#fff;
          background-color:#fff;
          height:1px;
        }
        body{
          display: flex;
          align-items: center;
          justify-content: center;
          color: #222;
          font-size: 15px;
          line-height: 1.75;
          padding: 05em 2em;
        }
        div.main{
          width: 210mm;
          /*box-shadow: 0 0 3px 3px #777;*/
        }
        .tbtxt tr td:nth-child(2){
          padding-left: 1em;
        }
        .tbtxt tr td:nth-child(1){
          color: #000;
          font-weight: 500;
        }
        .tbdtails tr td:nth-child(3){
          float: right;
        }
        .heading{
          margin-top: 1em;
          font-weight: bold;
        }
        table.tbdtails {
          width: 100%;
        }
        table.tbdtails tr:last-child{
          background: #f4f4f7;
          font-weight: 500;
        }
        table td, table td * {
          vertical-align: top;
        }
        table.tbmnos{
          font-size: 0.8em;
        }
      
        table.tbmnos tr td{
          width: 33%;
        }
        @media print {
          .pagebreak { page-break-before: always; } /* page-break-after works, as well */
        }

         </style>
         </head>
         <body onload="window.print()">${innerContents}</html>`);
  popUpWindow.document.close();
  this.hideReceiptTemplate = true;
}


}
