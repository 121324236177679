import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { ModalDialogService } from 'ngx-modal-dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent extends SharedClassComponent implements OnInit {

  // variables declaration reactive forms
  paramwinfrm: FormGroup;
  questionsForm: FormGroup;
  securityQuestionsForm: FormGroup;

  paramswinpnl: boolean = false; // hide/show the pop up panel
  endpointcall:string; // end point string
  paramsDataSets:any; // holding a certain endpoint response data and inject to datagrid
  param_id:any;
  params_actions:any;
  response:any; // hold response data from server
  title:string= 'System Users'; // title of the page
  model_name:string='users';  // endpoint
  securityAnswersSubmitted = 0; // checks if the all security questions have been answered or not

  // object array to temporary store security answers
  securityQuestionAnswers = [
    {
      questionId: 0,
      questionAnswer: '',
    },
    {
      questionId: 0,
      questionAnswer: '',
    },
    {
      questionId: 0,
      questionAnswer: '',
    },
  ];
  // object array to store registration data
  RegistrationData = {
    firstName: '',
    middleName: '',
    lastName: '',
    roleId: 0,
    phones: [
      {
        phoneNumber: 0,
        phoneTypeId: 0,
        main: 0,
      },
      {
        phoneNumber: 0,
        phoneTypeId: 0,
        main: 0,
      },
      {
        phoneNumber: 0,
        phoneTypeId: 0,
        main: 0,
      },
    ],
    questions: [
      {
        questionId: 0,
        answer: '',
      },
      {
        questionId: 0,
        answer: '',
      },
      {
        questionId: 0,
        answer: '',
      },
    ],
    email: '',
    routeName: '',
  };
  currentSecurityQuestion = 1; // question counter
  index = 1; // used as indicator of the string which shows the total qns answered.
  currentSecurityQuestionIndex = 0; // hold the index of the selected question
  securityQuestionSelectBoxPlaceholder = `Security Question (${this.index} of 3)`; // string which shows the total qns answered.
  routeNameOfSelectedRoleID = ''; // holds the route name of the selected role

  registrationEndPoint = 'users/create'; // end point
  // datasource
  main = [
    {
      id: 1,
      text: 'Yes',
    },
    {
      id: 0,
      text: 'No',
    },
  ];

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle('System Users');

    // reactive form configuration
    this.paramwinfrm = new FormGroup({
      firstName: new FormControl('', Validators.compose([Validators.required])),
      middleName: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      lastName: new FormControl('', Validators.compose([Validators.required])),
      roleId: new FormControl('', Validators.compose([Validators.required])),
      routeName: new FormControl('', Validators.compose([])),
      email: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.email])
      ),
      phoneNumber1: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(16),
          Validators.minLength(10),
        ])
      ),
      phoneNumber2: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(16),
          Validators.minLength(10),
        ])
      ),
      phoneNumber3: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(16),
          Validators.minLength(10),
        ])
      ),
      phoneTypeIdForphoneNumber1: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      phoneTypeIdForphoneNumber2: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      phoneTypeIdForphoneNumber3: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      mainForphoneNumber1: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      mainForphoneNumber2: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      mainForphoneNumber3: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      userId: new FormControl('', Validators.compose([])),
      id: new FormControl('', Validators.compose([]))
    });

    // reactive form configuration
    this.securityQuestionsForm = new FormGroup({
      questionId: new FormControl([Validators.compose([Validators.required])]),
      questionAnswer: new FormControl('', [
        Validators.compose([Validators.required]),
      ])
    });

    // load datasets into datagrid
    this.onGetParamsdetails();
    // getting list of system privileges
    this.onGetOtheRolesdetails('priviledges', this.userRolesDta);
    // getting list of system roles
    this.onGetRolesList('roles', this.rolesList);
    // getting list of system phone type ids
    this.onGetPhoneTypeIDs('phone/types');
    // gets the list of security questions
    this.onGetAdditionalSupportingParamsDetails('questions');

    // Controls the datagrid height and max rows to display
    this.observerCall();


  }
  // call getParamsdetails function to refresh datagid
  refreshDataGrid() {
    this.onGetParamsdetails();
  }
  /*

   a method called when a user click next button
   after answered the security question.

  */
  nextQuestion() {
    // check if the security form if valid thus all questions has been answered
    if (!this.securityQuestionsForm.valid) {
      this.toastr.error('Please choose question and provide your answer');
      return;
    }
    // adds 1 to the index for every method call
    this.index += 1;
    // adds 1 to the currentSecurityQuestion for every method call
    this.currentSecurityQuestion += 1;
    // checks if all three questions have been answered, if yes it prevent further method excution
    if (this.currentSecurityQuestion >= 4) {
      // help to forcebly patch the answers to security questions object array at index 2
      this.securityQuestionAnswers[
        this.currentSecurityQuestionIndex
      ].questionId = this.securityQuestionsForm.get('questionId').value;
      this.securityQuestionAnswers[
        this.currentSecurityQuestionIndex
      ].questionAnswer = this.securityQuestionsForm.get('questionAnswer').value;

      this.securityAnswersSubmitted = 1; // indicates that, all security questions have been answered
      this.showNextButton = false; // hides the next button
      this.securityQnInputReadOnly = true; // set readOnly mode to true to securityQuestions formGroup forms controls
    }
    // checks if all three questions have been answered, if yes it prevent further method excution
    if (this.currentSecurityQuestion > 4) {
      return;
    }
    // reduces index value by 1 when it reachs 4
    if (this.index === 4) {
      this.index -= 1;
    }
    // getting answers from securityQuestionsForm and assign them to securityQuestionAnswers object
    this.securityQuestionAnswers[
      this.currentSecurityQuestionIndex
    ].questionId = this.securityQuestionsForm.get('questionId').value;
    this.securityQuestionAnswers[
      this.currentSecurityQuestionIndex
    ].questionAnswer = this.securityQuestionsForm.get('questionAnswer').value;
    this.currentSecurityQuestionIndex += 1;
    this.securityQuestionSelectBoxPlaceholder = `Security Question (${this.index} of 3)`;

    // removes already answered questions
    this.randomSecurityQuestions.splice(
      this.randomSecurityQuestions.findIndex(
        (item) => item.id === this.securityQuestionsForm.get('questionId').value
      ),
      1
    );
    // reset securityQuestionsForm after next button clicked
    this.securityQuestionsForm.reset();
  }

  onSubmitAccountForm() {
    // checks if the submitted form is invalid
    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please fill in all the details', 'Details Required');
      return;
    }
    // checks if user already answered the security questions
    if (this.securityAnswersSubmitted !== 1) {
      this.toastr.error('Please choose and answer 3 security questions');
      return;
    }

    /*
    loop through the list of roles and fetch the selected role's routeName and assign it to variable routeNameOfSelectedRoleID
     */
    for (
      let index = 0;
      index < this.rolesList.length;
      index++
    ) {
      if (
        this.rolesList[index].id ==
        this.paramwinfrm.get('roleId').value
      ) {
        this.routeNameOfSelectedRoleID = this.rolesList[
          index
        ].routeName;
        break;
      }
    }
    // patch paramwinfrm form data to RegistrationData object
    this.RegistrationData = {
      firstName: this.paramwinfrm.get('firstName').value,
      middleName: this.paramwinfrm.get('middleName').value,
      lastName: this.paramwinfrm.get('lastName').value,
      roleId: this.paramwinfrm.get('roleId').value,
      phones: [
        {
          phoneNumber: this.paramwinfrm.get('phoneNumber1').value,
          phoneTypeId: this.paramwinfrm.get('phoneTypeIdForphoneNumber1')
            .value,
          main: this.paramwinfrm.get('mainForphoneNumber1').value,
        },
        {
          phoneNumber: this.paramwinfrm.get('phoneNumber2').value,
          phoneTypeId: this.paramwinfrm.get('phoneTypeIdForphoneNumber2')
            .value,
          main: this.paramwinfrm.get('mainForphoneNumber2').value,
        },
        {
          phoneNumber: this.paramwinfrm.get('phoneNumber3').value,
          phoneTypeId: this.paramwinfrm.get('phoneTypeIdForphoneNumber3')
            .value,
          main: this.paramwinfrm.get('mainForphoneNumber3').value,
        },
      ],
      questions: [
        {
          questionId: this.securityQuestionAnswers[0].questionId,
          answer: this.securityQuestionAnswers[0].questionAnswer,
        },
        {
          questionId: this.securityQuestionAnswers[1].questionId,
          answer: this.securityQuestionAnswers[1].questionAnswer,
        },
        {
          questionId: this.securityQuestionAnswers[2].questionId,
          answer: this.securityQuestionAnswers[2].questionAnswer,
        },
      ],
      email: this.paramwinfrm.get('email').value,
      routeName: this.routeNameOfSelectedRoleID,
    };
    // show loading spinner
    this.spinner.show();
    // call utilities' service postservicecall to submit registration data to server.
    this.utilities
      .postServiceCall(this.RegistrationData, this.registrationEndPoint)
      .subscribe(
        (res) => {
          this.response = res.json();
          if (this.response.code == 2000) {
            const result = this.response;
            this.toastr.success(result.message, 'Response');
            this.paramswinpnl = false;
            this.onGetParamsdetails();
          } else {
            this.toastr.error(this.response.message, 'Error');
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            'Error occurred, check the communication channel!!',
            'Response'
          );
        }
      );
  }
  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'before',
      widget: 'dxButton',
      options: {
        text: 'New User',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewParameter.bind(this)
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
       onClick: this.refreshDataGrid.bind(this)
      }
    });

  }
funcpopWidth(percentage_width) {
    if (window.innerWidth > 800){
      return  window.innerWidth * percentage_width / 100;
    } else {
      return  window.innerWidth - 50;
    }

  }

}
