import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from '../../shared-class/shared-class.component';

@Component({
  selector: 'app-navigations',
  templateUrl: './navigations.component.html'
})
export class NavigationsComponent  extends SharedClassComponent implements OnInit {
  title:string= 'Navigations Setup';
  model_name:string='navigations';
  //variables defination
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  userRolesDta:any;
  param_id:any;
  response:any;
  navigationLevels:any = [{
    id:1,
    name:'Level One'
  },{
    id:2,
    name:'Level Two'
  },{
    id:3,
    name:'Level Three'
  }]

  navigationTypes:any= [{
    id:1,
    name:'Online Services'
  }]
  isParentHidden:boolean = true;
  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    this.paramwinfrm =  new FormGroup({
      text: new FormControl('', Validators.compose([Validators.required])),
      navigation_type_id: new FormControl('', Validators.compose([Validators.required])),
      parent_id: new FormControl('', Validators.compose([])),
      path: new FormControl('', Validators.compose([])),
      is_disabled: new FormControl('', Validators.compose([])),
      is_online: new FormControl('', Validators.compose([])),
      navigation_level: new FormControl('', Validators.compose([Validators.required])),
      icon: new FormControl('', Validators.compose([])),
      order_no: new FormControl('', Validators.compose([])),
      on_line: new FormControl(1, Validators.compose([])),
      subsidium_03: new FormControl('', Validators.compose([])),
      subsidium_02: new FormControl('', Validators.compose([])),
      subsidium_01: new FormControl('', Validators.compose([])),
      id: new FormControl('', Validators.compose([])),
      navigation_id: new FormControl('', Validators.compose([])),

      code: new FormControl('', Validators.compose([]))
    });
     // Controls the datagrid height and max rows to display
    this.observerCall();
    this.setDataGridRefreshTimeInterval();

    this.onGetParamsdetails();
  }
  onChangeNavigationLevel(e){
      this.isParentHidden = true;
      if(e.value > 1){
        this.isParentHidden = false;
      }

  }
}
