import {initializeState, InvoiceState} from '../state/InvoiceState';
import * as InvoiceActions from '../action/InvoiceAction';
import {Action, createReducer, on} from '@ngrx/store';
import {InvoiceModel} from '../models/invoiceModel';


export const initialState = initializeState();

const reducer = createReducer(
  initialState,
  on(InvoiceActions.getInvoiceAction, state => state),
  on(InvoiceActions.CreateInvoiceAction, (state: InvoiceState, invoice: InvoiceModel) => {
    return {...state, Invoice: [...state.invoice, invoice], invoiceError: null};
  }),
  on(InvoiceActions.SuccessGetInvoiceAction, (state: InvoiceState, { payload }) => {
    return  { ...state, invoice: payload };
  }),
  on(InvoiceActions.SuccessCreateInvoiceAction, (state: InvoiceState, { payload }) => {
    return {...state, invoice: [...state.invoice, payload], invoiceError: null};
  }),
  on(InvoiceActions.ErrorInvoiceAction, (state: InvoiceState, error: Error) => {
    return {...state, invoiceError: error};
  })
);

export function InvoiceReducer(state: InvoiceState | undefined, action: Action) {
  return reducer(state, action);
}

