import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { Headers } from "@angular/http";

import { map, timeout } from "rxjs/operators";
import notify from "devextreme/ui/notify";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";



@Component({
  selector: 'app-employer-self-registration',
  templateUrl: './employer-self-registration.component.html',
  styleUrls: ['./employer-self-registration.component.scss']
})
export class EmployerSelfRegistrationComponent 
  extends SharedClassComponent
  implements OnInit
{
  regType;
  copyRightBanner = "";
  isRegCompleted = false;
  maxDate: Date = new Date();
  investmentCustomerPhoneNumber: any;

  cityPattern = "^[^0-9]+$";
  namePattern: any = /^[^0-9]+$/;
  phonePattern: any = /^(1|)?(\d{3})(\d{3})(\d{6})$/;
  countries: string[];
  phoneRules: any = {
    X: /[02-9]/,
  };
  endPoint = "users/verify";
  registrationEndPoint = "employer/create";

  registrationTypeForm: FormGroup;
  showAlertDialogeForVerfication: boolean=false;
  alertDialogMessagesVerfication: any;
  isUserVerfied: boolean=false;

;
  customerVerificationForm: FormGroup;

  hideEmpRegForm = true;
  hideInvestmentReg = true;
  hideInvCustVerifyForm = false;
  hideEmployerReg = true;
  hideRegTypeOption = false;
  hideOTPform = true;
  hideCreateAccountForm = true;
  hideCreateUserForm = true;
  hideCreateInvestmentUserForm = true;
  hideInvCustRegForm = true;
  hideEmployerVerifyForm = true;


  showAlertDialog = false;
  alertReason = "";

  btntext = "Resend OTP";
  disableResendOTPbtn = false;

  timeout = 60000;
  resendOTPcounter = 0;
  sendToNumber;

  headerTitle = "ZSSF Employer Portal - Self Registration";

  password = "";
  passwordPattern =
    "(?=^.{6,}$)((?=.*d)|(?=.*W+))(?![.\n])(?=.*[A-Z])(?=.*[0-9]).*$";

  accountForm: FormGroup;
localSectorWithPrivate=[{
  "SectorID": 3,
  "Description": "Private",
  "AllowIndividualContributions": false
}]
  // individual contributor
  hideINDReg = true;
  hideINDVerifyForm = true;
  indPhoneNumer = null;
  indEmail = null;
  hideINDRegForm = true;
  passwordRegex =
    "(?=[^A-Z]*[A-Z])(?=.*[!@#$%^&*])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{8,}";
  // passwordRegex = '(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[0-9]).*$';
  usernameClone = null;
  hideEmployerEnrollment: boolean = false;
  fumisEmployerCreateForm: FormGroup;
  sectorList: any;
  districtList: any;
  natureBusinessList: any;
  branchList: any;
  districtListNew: any;
  regionList: any;
  showAlertDialoge: boolean=false;
  alertDialogMessages: string;
  maxValue: number = 0;
  

  ngOnInit() {
    this.regType = [
  
      {
        id: 1,
        text: "Employer Registration",
      },
      {
        id: 2,
        text: "Check Registration Status",
      },

    ];

    this.customerVerificationForm = new FormGroup({
      customerID: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      invRegType: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
    this.copyRightBanner = this.appInfo.getCopyRightText();
    this.maxDate = new Date(
      this.maxDate.setFullYear(this.maxDate.getFullYear() - 21)
    );



    this.registrationTypeForm = new FormGroup({
      registerAs: new FormControl(1, Validators.compose([Validators.required])),
    });



    this.fumisEmployerCreateForm = new FormGroup({
      emName: new FormControl("", Validators.compose([Validators.required])),
      altName: new FormControl("", Validators.compose([])),
      acronomy: new FormControl("", Validators.compose([])),
      regNo: new FormControl("", Validators.compose([Validators.required])),
      district: new FormControl("", Validators.compose([Validators.required])),
      sector: new FormControl("", Validators.compose([Validators.required])),
      natureBusines: new FormControl("", Validators.compose([Validators.required])),
      postalAddress: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      physicalAddress: new FormControl("", Validators.compose([])),

      regDate: new FormControl("", Validators.compose([Validators.required])),
      opDate: new FormControl("", Validators.compose([Validators.required])),

      emailAddress: new FormControl("", Validators.compose([Validators.email])),
      mobile: new FormControl(
        "",
        Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      telephone: new FormControl(
        "",
        Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      faxnumber: new FormControl("", Validators.compose([])),
      contactPerson: new FormControl("",  Validators.compose([Validators.pattern(/^0\d{9}$/)])),
  
      website: new FormControl("", Validators.compose([])),

      attachment: this.fb.array([this.createattachment(this.maxValue)]),
      certificateOfIncorporation: new FormControl("", Validators.compose([])),
      memorandum: new FormControl("", Validators.compose([])),
      articleOfAssociation: new FormControl("", Validators.compose([])),

    });

    this.getDistricts();
    this.getSectors();
    this.businessNature();
 
    this.getRegion();
  }


  registrationTypeSubmit() {
    if (this.registrationTypeForm.invalid) {
      this.toastr.error("Please choose registration type to contrinue!");
      return;
    }

    this.hideEmployerEnrollment = false;
    // register as employer
    if (this.registrationTypeForm.get("registerAs").value === 2) {
      this.hideInvCustVerifyForm = true;
      this.hideINDVerifyForm = true;

      this.hideRegTypeOption = true;
      this.hideEmpRegForm = true;
      this.hideEmployerVerifyForm = false;
      this.hideEmployerReg = false;
      this.headerTitle = "ZSSF Employer Portal - Self Registration";
    }
  
    if (this.registrationTypeForm.get("registerAs").value === 1) {
      // this.router.navigate(["employer-create"])
      this.hideEmployerEnrollment = true;
    }
  }
  onFileChangeCertificateOfIncorporation(event: any): void {
    const files = event.target.files;

    if (files && files.length > 0) {
        const file = files[0];
        const acceptedFileTypes = ['application/pdf'];

        if (acceptedFileTypes.includes(file.type)) {
            const reader = new FileReader();

            reader.onload = (e: any) => {
                const base64String = e.target.result as string;
                const bas64 = base64String.split(",")[1];
                const fileControl = this.fumisEmployerCreateForm
                    .get("certificateOfIncorporation") as FormControl | null;

                if (fileControl) {
                    fileControl.patchValue(bas64);

                  
                    const fileName = file.name;
                    const label = event.target.nextElementSibling;
                    label.innerText = fileName;
                }
            };

            reader.readAsDataURL(file);
        } else {
            this.toastr.error("Unsupported file type, only PDF are allowed");
        }
    }
}

onFileChangeMemorandum(event: any): void {
  const files = event.target.files;

  if (files && files.length > 0) {
      const file = files[0];
      const acceptedFileTypes = ['application/pdf'];

      if (acceptedFileTypes.includes(file.type)) {
          const reader = new FileReader();

          reader.onload = (e: any) => {
              const base64String = e.target.result as string;
              const bas64 = base64String.split(",")[1];
              const fileControl = this.fumisEmployerCreateForm
                  .get("memorandum") as FormControl | null;

              if (fileControl) {
                  fileControl.patchValue(bas64);

                
                  const fileName = file.name;
                  const label = event.target.nextElementSibling;
                  label.innerText = fileName;
              }
          };

          reader.readAsDataURL(file);
      } else {
          this.toastr.error("Unsupported file type, only PDF are allowed");
      }
  }
}

onFileChangeArticleOfAssociation(event: any): void {
  const files = event.target.files;

  if (files && files.length > 0) {
      const file = files[0];
      const acceptedFileTypes = ['application/pdf'];

      if (acceptedFileTypes.includes(file.type)) {
          const reader = new FileReader();

          reader.onload = (e: any) => {
              const base64String = e.target.result as string;
              const bas64 = base64String.split(",")[1];
              const fileControl = this.fumisEmployerCreateForm
                  .get("articleOfAssociation") as FormControl | null;

              if (fileControl) {
                  fileControl.patchValue(bas64);

                
                  const fileName = file.name;
                  const label = event.target.nextElementSibling;
                  label.innerText = fileName;
              }
          };

          reader.readAsDataURL(file);
      } else {
          this.toastr.error("Unsupported file type, only PDF are allowed");
      }
  }
}


  createattachment(index) {
   
    return this.fb.group({
    
      document: [""],


      id: [index],
    });
    
  }
  get attachment() {
    return this.fumisEmployerCreateForm.get("attachment") as FormArray;
  }

  addattachment() {
    const max = this.maxValue++;
    this.attachment.push(this.createattachment(max + 1));
  }

  onFileChange(event: any, index: number): void {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];


      const acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      if (acceptedFileTypes.includes(file.type)) {
        this.convertToBase64(file, index, event.target);
      } else {
     

          this.toastr.error("Unsupported file type, only PDF and Images are allowed");
          return;
      }
     
    }
  }

  convertToBase64(file: File, index: number, inputElement: any): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64String = e.target.result as string;
      const bas64 = base64String.split(",")[1];
      const fileControl = this.attachment
        .at(index)
        .get("document") as FormControl | null;

      if (fileControl) {
        fileControl.patchValue(bas64);

        // Update the label text to display the file name
        const fileName = file.name;
        const label = inputElement.nextElementSibling;
        label.innerText = fileName;
      }
    };

    reader.readAsDataURL(file);
  }


  onBackPressed() {
    this.hideRegTypeOption = false;
    this.hideEmployerEnrollment = false;
    this.hideINDReg = true;
    this.hideINDRegForm = true;
    this.hideEmpRegForm = true;
    this.hideInvestmentReg = true;
    this.hideInvCustVerifyForm = true;
    this.hideEmployerVerifyForm = true;

    this.customerVerificationForm.reset();

    this.fumisEmployerCreateForm.reset();
  }

 



  navigateToUser(){

    this.router.navigate(["registration"]);
    // this.router.navigate(["registration"], {
    //   queryParams: { v: true },
    //   queryParamsHandling: "merge",
    // });
  }

  hideDialog() {
    this.showAlertDialog = false;
  }



  onEmployerNumberValidation() {
    this.showAlertDialogeForVerfication=false;
    if (this.customerVerificationForm.get("customerID").invalid) {
      this.toastr.error("Please enter your employer number for verification");
      return;
    }
    const data = {
      requestType: "EMPLOYER_CHECK_STATUS",   
      employerID: this.customerVerificationForm.get("customerID").value,
    };
    this.hideInvestmentReg = true;
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (response) => {


        const res = response.json();
        if (res.code == 2000) {
          this.toastr.success(res.message);

          this.showAlertDialogeForVerfication=true;
          this.alertDialogMessagesVerfication=res.data[0].Status
          this.isUserVerfied=res.data[0].IsActive
        
       
   // TEMP32710

        } else {
          this.toastr.error(res.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error("Something went wrong please try again.");
        this.spinner.hide();
      }
    );
  }


  finish() {
    this.isRegCompleted = false;
    this.hideRegTypeOption = true;
    this.hideINDReg = true;
    this.hideINDRegForm = true;
    this.hideEmpRegForm = true;
    this.hideInvCustVerifyForm = true;
    this.hideInvCustRegForm = true;
    this.hideEmployerReg = true;

    this.router.navigate(["/logn"]);
  }



  hideEmail(email) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  }

  createEmployer() {

   

    // console.log(this.fumisEmployerCreateForm.value)

    const attachment = this.fumisEmployerCreateForm
    .get("attachment")
    .value.map(({ id, ...rest }) => rest);

    let isattachmentHasError = false;
    if(this.attachment.length != 1){
      attachment.forEach((item) => {
        if (item.document === "") {
          isattachmentHasError = true;
        }
      });
    }
   
   const newAttachment= attachment.filter(item => item.document !== "");



  if (isattachmentHasError) {
    this.toastr.error("There is more than one attachemnt fields please fill all!", "Error");
    return;
  }


  
    const errorMessages = {
      emName: "Employer Name is required",
      altName: "",
      acronomy: "",
      regNo: "Registration Number is required",
      district: "District is required",
      sector: "Sector is required",
      natureBusines: "Nature of teh business is reqired",
      postalAddress: "Postal Address is required",
      physicalAddress: "",
      regDate: "Registration Date is required",
      opDate: "Operation Date is required",
      emailAddress: "Invalid Email Address",
      mobile: "Mobile No  must be 10 digits",
      telephone: "Telephone Office Mobile number  must be 10 digits",
      contactPerson: "Contact Person Mobile number is required and must be 10 digits",
      faxnumber: "",
  
      branchCode: "",
      website: "",
    };

    for (const controlName in this.fumisEmployerCreateForm.controls) {
      const control = this.fumisEmployerCreateForm.get(controlName);
      if (control.invalid) {
        let errorMessage = errorMessages[controlName];

        // Handle email validation
        if (
          controlName === "emailAddress" &&
          control.hasError("emailAddress")
        ) {
          errorMessage = errorMessages.emailAddress;
        }

        // Handle mobile number validation
        if (controlName === "mobile" && control.hasError("mobile")) {
          errorMessage = errorMessages.mobile;
        }

        if (controlName === "contactPerson" && control.hasError("contactPerson")) {
          errorMessage = errorMessages.contactPerson;
        }


        // Handle telephone number validation
        if (controlName === 'telephone' && control.value) { // Check if telephone has a value
          if (control.hasError('telephone')) {
            errorMessage = errorMessages.telephone;
          }}

        this.toastr.error(errorMessage);
        return;
      }
    }

    if (this.fumisEmployerCreateForm.get("emName").invalid) {
      this.toastr.error("Employer name required");
      return;
    }
    if (this.fumisEmployerCreateForm.get("regNo").invalid) {
      this.toastr.error("Registration number required");
      return;
    }

    if (this.fumisEmployerCreateForm.get("district").invalid) {
      this.toastr.error("District required");
      return;
    }



    if (this.fumisEmployerCreateForm.get("regDate").value == null) {
      this.fumisEmployerCreateForm.get("regDate").patchValue(this.today);
    }

    if (this.fumisEmployerCreateForm.get("opDate").value == null) {
      this.fumisEmployerCreateForm.get("opDate").patchValue(this.today);
    }

    const registrationtDate =
      new Date(
        this.fumisEmployerCreateForm.get("regDate").value
      ).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(
          this.fumisEmployerCreateForm.get("regDate").value
        ).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(this.fumisEmployerCreateForm.get("regDate").value).getDate()
      ).slice(-2);
    const operationDate =
      new Date(this.fumisEmployerCreateForm.get("opDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.fumisEmployerCreateForm.get("opDate").value).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(this.fumisEmployerCreateForm.get("opDate").value).getDate()
      ).slice(-2);

    const data = {
      requestType: "ONLINE_EMPLOYER_CREATE",
    
      employerName: this.fumisEmployerCreateForm.get("emName").value,
      alternateName: this.fumisEmployerCreateForm.get("altName").value,
      acronym: this.fumisEmployerCreateForm.get("acronomy").value,
      registrationnumber: this.fumisEmployerCreateForm.get("regNo").value,
      districtId: this.fumisEmployerCreateForm.get("district").value,
      sectorId: this.fumisEmployerCreateForm.get("sector").value,
      natureOfBusiness: this.fumisEmployerCreateForm.get("natureBusines").value,
      postalAddress: this.fumisEmployerCreateForm.get("postalAddress").value,
      physicalAddress:
        this.fumisEmployerCreateForm.get("physicalAddress").value,
      registrationDate: registrationtDate,
      firstOperationDate: operationDate,
      emailAddress: this.fumisEmployerCreateForm.get("emailAddress").value,
      website: this.fumisEmployerCreateForm.get("website").value,
      telephoneOffice: this.fumisEmployerCreateForm.get("telephone").value,
      telephoneMobile: this.fumisEmployerCreateForm.get("mobile").value,
      faxNumber: this.fumisEmployerCreateForm.get("faxnumber").value,
      contactPerson: this.fumisEmployerCreateForm.get("contactPerson").value,
      isActive: "1",

      certificateOfIncorporation: this.fumisEmployerCreateForm.get("certificateOfIncorporation").value,
      memorandum: this.fumisEmployerCreateForm.get("memorandum").value,
      articleOfAssociation: this.fumisEmployerCreateForm.get("articleOfAssociation").value,

      
  
      attachment: newAttachment,
    
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (response) => {
        // this.spinner.hide();
        // this.toastr.success("Operation Successfully")
        // this.fumisEmployerCreateForm.reset()
        // this.router.navigate(['fumis-employer-info',])

        const res = response.json();
        if (res.code == 2000) {
          this.toastr.success(res.message);
        
       

          const employerNo = res.data[0].EmployerNumber;
          this.alertDialogMessages = `Employer has been created, with this Employer Number `+ employerNo;
          this.showAlertDialoge=true;
          // this.router.navigate(['fumis-employer-info',res.data[0].EmployerNumber])
        } else {
          this.toastr.error(res.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error("Something went wrong please try again.");
        this.spinner.hide();
      }
    );
  }

  hidePopUp(){
    this.fumisEmployerCreateForm.reset();
    this.hideEmployerEnrollment = false;
    this.showAlertDialoge=false;
  }

  hidePopUpVerfication(){

    this.hideEmployerEnrollment = false;
    this.showAlertDialoge=false;
    this.showAlertDialogeForVerfication= false;
  }

  getSectors() {
    const data = {
      requestType: "SECTOR_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.sectorList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getDistricts() {
    const data = {
      requestType: "DISTRICT_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.districtList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  businessNature() {
    const data = {
      requestType: "NATURE_OF_BUSINESS_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.natureBusinessList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }



  filterDistricts(e) {
    this.districtListNew = this.districtList.filter(
      (res) => res.RegionID == e.value
    );
  }

  getRegion() {
    const data = {
      requestType: "REGION_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.regionList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
}
