import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from '../../shared-class/shared-class.component';

@Component({
  selector: 'app-bank-branches',
  templateUrl: './bank-branches.component.html',
  styleUrls: ['./bank-branches.component.scss']
})
export class BankBranchesComponent  extends SharedClassComponent implements OnInit {
  title = 'Invoices Monthly Records';

  endPointCallInvoiceGenerated = 'employer/invoices';
  invoiceGeneratedByEmployerDataSource = [];

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.getListOfGeneratedInvoices(this.authService.getUserDetails().linkId);
  }

  getListOfGeneratedInvoices(employerNumber) {
    const data = {
      employerNumber
    };
    this.utilities.postServiceCall(data, this.endPointCallInvoiceGenerated).subscribe(res => {
      const serverResponse = res.json();
      if (serverResponse.code === 2000) {
        this.invoiceGeneratedByEmployerDataSource = serverResponse.data;
      } else {
        this.invoiceGeneratedByEmployerDataSource = [];
      }
    });
  }
}
