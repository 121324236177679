import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-death-form",
  templateUrl: "./death-form.component.html",
  styleUrls: ["./death-form.component.scss"],
})
export class DeathFormComponent extends SharedClassComponent implements OnInit {
  //@ViewChild('videoPlayer','') videoplayer: any;
  returnedList = [
    { val: 1, name: "Yes" },
    { val: 0, name: "No" },
  ];
  employmentTypesList = [{ name: "FULL TIME" }, { name: "PART TIME" }];
  deathcauseList = [{ name: "Accident" }, { name: "Disease" }];
  searchKeyDataSource = [
    { val: 1, name: "Employer Number" },
    { val: 2, name: "Employer Name" },
  ];
  witnesses: any = [];
  fileDatasource: any = [];
  witnessForm: FormGroup;
  NotificationDeathForm: FormGroup;
  file: any;
  districtList: any = [];
  districtListNew: any = [];
  regionList: any = [];
  data: any;
  fileUpload: any;
  base64file: any;
  fileDescription: any;
  fileExtension: any;
  incidentOutcomeList: any = [];
  treatmenttypesList: any = [];
  paymentModes: any = [];
  bankList: any = [];
  previewUrl: string; // URL for previewing the PDF
  fileName: string; // File name for download
  accident = false;
  showAlertDialogEmployerx = false; // Show

  fileData = "";
  employerNumberValid = false;
  shehiaListNew: any;
  shehiaList: any;
  sectorList: any;
  nationalityDataSource: any;
  isSubmitButtonShown = false;
  activateMemberNumberSearch = false;
  activateMemberNameSearch = false;
  searchKeySelected = false;
  searchForm: FormGroup;
  showAlertDialog = false;
  memberInfo: any = [];
  showAlertDialogEmployer = true;
  employerDatasource: any = [];
  activateEmployerNameSearch = false;
  activateEmployerNumberSearch = false;
  employerDetails: any = [];
  employerNumber: any;
  membersList: any;
  memberDetails: any = [];
  showDepartmentTextbox = false;
  declarationValued = false;
  showSearchOptions = false;
  employerInfoSearchResult: any = [];
  employerNumberSearch = false;
  employerNameSearch = false;
  showAlertDialogEmp = false;
  showOtherDetails = false;
  inputTimer: any;
  fetchForm: FormGroup;
  showOtherDetais: boolean = false;
  ngOnInit() {
    this.getDistricts();
    this.getRegion();
    this.shehia();
    this.getSectors();
    this.nationality();

    this.witnessForm = new FormGroup({
      fullname: new FormControl("", Validators.compose([])),
      address: new FormControl("", Validators.compose([])),
      phone: new FormControl(
        "",
        Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      position: new FormControl("", Validators.compose([])),
    });

    this.NotificationDeathForm = new FormGroup({
      empNo: new FormControl("", Validators.compose([Validators.required])),
      empName: new FormControl("", Validators.compose([])),
      memberNo: new FormControl("", Validators.compose([Validators.required])),
      representativeName: new FormControl("", Validators.compose([])),
      representativePhone: new FormControl("", Validators.compose([])),
      representativeAddress: new FormControl("", Validators.compose([])),
      deathDate: new FormControl("", Validators.compose([Validators.required])),
      deathCause: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      deathReportingDate: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      deathPlace: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      premises: new FormControl("", Validators.compose([])),
      Ddept: new FormControl("", Validators.compose([])),
      deathActivityInvolved: new FormControl("", Validators.compose([])),
      DhospitalName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      DpractitionalName: new FormControl("", Validators.compose([])),
      DpractitionalPhone: new FormControl("", Validators.compose([])),
      DeathmoreDetails: new FormControl("", Validators.compose([])),
      fullName: new FormControl("", Validators.compose([])),
      //  sector: new FormControl('', Validators.compose([])),
      //  telephone: new FormControl('', Validators.compose([])),
      //  email: new FormControl('', Validators.compose([])),
      //  shehia: new FormControl('', Validators.compose([])),
      //  district: new FormControl('', Validators.compose([])),
      //  region: new FormControl('', Validators.compose([])),
      //  address: new FormControl('', Validators.compose([])),
      //  CountryName: new FormControl('', Validators.compose([])),

      //  fname: new FormControl('', Validators.compose([])),
      //  mName: new FormControl('', Validators.compose([])),
      //  lname: new FormControl('', Validators.compose([])),
      //  dob: new FormControl('', Validators.compose([])),
      //  gender: new FormControl('', Validators.compose([])),
      //  maritalStatus: new FormControl('', Validators.compose([])),
      //  memberAddress: new FormControl('', Validators.compose([])),
    });

    this.searchForm = new FormGroup({
      searchValue: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      searchCretiliaValue: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
    this.fetchForm = new FormGroup({
      empNo: new FormControl("", Validators.compose([Validators.required])),
    });

    this.witnesses = [];

    const userDetails = this.authService.getUserDetails();

    if (userDetails.linkId) {
      this.employerNoSearch(userDetails.linkId);
    }
  }

  addWitness() {
    if (this.witnessForm.invalid) {
      this.toastr.error("Invalid form", "Error");
      return;
    }
    this.witnesses.push({
      fullName: this.witnessForm.get("fullname").value,
      phone: this.witnessForm.get("phone").value,
      address: this.witnessForm.get("address").value,
      position: this.witnessForm.get("position").value,
    });
    this.witnessForm.reset();
  }

  onChange1(event: any) {
    if (event.target.files.length > 0) {
      this.fileUpload = event.target.files[0];

      // this.NotificationDeathForm.patchValue({
      //   fileSource: file
      // });
      const extension = event.target.files[0].name.split(".").pop();
    }
  }

  onChange(event) {
    const file = event.target.files[0];
    this.fileExtension = event.target.files[0].name.split(".").pop();
    this.fileDescription = event.target.files[0].name.split(".")[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64file = reader.result;
    };
  }

  register() {
    const errorMessages = {
      empNo: "Employer number is required",
      memberNo: "Member number is required",
      representativeName: "Representative name is required",
      representativePhone: "Representative mobile number is required",
      representativeAddress: "Representative address is required",
      deathDate: "Death date is required",
      deathCause: "Death cause is required",
      deathReportingDate: "Death reporting date is required",
      deathPlace: "Death place is required",
      DhospitalName: "Hospital name is required",
      DpractitionalName: "Medical Practitioner name is required",
      DpractitionalPhone: "Medical Practitioner mobile number is required",
    };

    for (const controlName in this.NotificationDeathForm.controls) {
      const control = this.NotificationDeathForm.get(controlName);
      if (control.invalid) {
        let errorMessage = errorMessages[controlName];

        this.toastr.error(errorMessage, "", { timeOut: 6000 });
        return;
      }
    }

    const deathDate =
      new Date(
        this.NotificationDeathForm.get("deathDate").value
      ).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(
          this.NotificationDeathForm.get("deathDate").value
        ).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(this.NotificationDeathForm.get("deathDate").value).getDate()
      ).slice(-2);
    //const anticipatedDate = new Date(this.NotificationDeathForm.get('AntireturnedDate').value).getFullYear() + "-"+  ("0"+(new Date(this.NotificationDeathForm.get('AntireturnedDate').value).getMonth()+1)).slice(-2) + "-" +("0" + new Date(this.NotificationDeathForm.get('AntireturnedDate').value).getDate()).slice(-2);
    const deathReportingDate =
      new Date(
        this.NotificationDeathForm.get("deathReportingDate").value
      ).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(
          this.NotificationDeathForm.get("deathReportingDate").value
        ).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(
          this.NotificationDeathForm.get("deathReportingDate").value
        ).getDate()
      ).slice(-2);

    const reportingDateValue = new Date(
      this.NotificationDeathForm.get("deathReportingDate").value
    );
    const incDateValue = new Date(
      this.NotificationDeathForm.get("deathDate").value
    );

    const differenceInMs = Math.abs(
      incDateValue.getTime() - reportingDateValue.getTime()
    );
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    if (differenceInDays > 7) {
      this.toastr.error("Seven days have passed since the death happened", "", {
        timeOut: 6000,
      });
      return;
      // Perform your rejection action here
    }

    //when
    const data = {
      requestType: "COMPENSATION_CLAIM_EMPLOYER_NOTIFICATION_ONLINE",
      employerNumber: this.NotificationDeathForm.get("empNo").value,
      memberNumber: this.NotificationDeathForm.get("memberNo").value,
      accidentDateTime: null,
      accidentReportingDate: null,
      bodyInjuries: "",
      accidentStreet: "",
      accidentWard: "",
      accidentShehia: null,
      isAccicdentOnPremise: "",
      accidentPremiseDepartment: "",
      acidentAtivityInvolved: "",
      accidentDescription: "",
      diseaseDiagnosisDate: null,
      diseaseDiagnosed: "",
      diseaseReportingDate: null,
      diseasePremiseDepartment: "",
      diseaseDescription: "",
      hospitalNameOfDiagnosis: "",
      medicalPractionerName: "",
      medicalPractionerPhone: "",
      memberRepresentativeName:
        this.NotificationDeathForm.get("representativeName").value,
      memberRepresentativePhone: this.NotificationDeathForm.get(
        "representativePhone"
      ).value,
      memberRepresentativePhysicalAddress: this.NotificationDeathForm.get(
        "representativeAddress"
      ).value,
      dateOfDeath: deathDate,
      causeOfDeath: this.NotificationDeathForm.get("deathCause").value,
      deathReportingDate: deathReportingDate,
      deathStreet: "",
      deathWard: "",
      deathShehia: this.NotificationDeathForm.get("deathPlace").value,
      isDeathOnPremise: this.NotificationDeathForm.get("premises").value,
      deathPremiseDepartment: this.NotificationDeathForm.get("Ddept").value,
      deathAtivityInvolved: this.NotificationDeathForm.get(
        "deathActivityInvolved"
      ).value,
      deathDesription: this.NotificationDeathForm.get("DeathmoreDetails").value,
      hospitalNameOfDeathComfirmation:
        this.NotificationDeathForm.get("DhospitalName").value,
      deathComfirmPractionerName:
        this.NotificationDeathForm.get("DpractitionalName").value,
      deathComfirmPractionerPhone:
        this.NotificationDeathForm.get("DpractitionalPhone").value,
      employerDeclaration: this.declarationValued,
      notificationType: 3,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        this.spinner.hide();
        const serverResponse = res.json();
        this.spinner.hide();
        if (serverResponse.code == 2000) {
          this.toastr.success(serverResponse.message);
          this.NotificationDeathForm.reset();
          setTimeout(() => {
            const id = serverResponse.data.notificationID;
            this.router.navigate(["notification-info", id]);
          }, 3000);
        } else {
          this.toastr.error(serverResponse.message);
          // this.toastr.error(serverResponse.message, 'Failed to create invoice');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, please try again");
      }
    );
  }

  validateInput(value: any, inputType: string) {
    switch (inputType) {
      case "employerNumber":
        if (value.length < 5) {
          this.employerNumberValid = false;
        } else {
          this.employerNumberValid = true;
        }
    }
  }

  getPDFDataUrl() {
    return "data:application/pdf;base64," + this.fileData;
  }

  isPDF(fileData: string): boolean {
    // Implement the logic to determine if the file is a PDF (e.g., by checking the file extension)
    // Return true if it is a PDF, false otherwise
    return true; // Replace with your logic
  }

  downloadFile(): void {
    const element = document.createElement("a");
    element.href = "data:application/pdf;base64," + this.fileData;
    element.download = "download.pdf"; // Set the appropriate filename and extension
    element.click();
  }

  previewFile(): void {
    const byteCharacters = atob(this.fileData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileBlob = new Blob([byteArray], { type: "application/pdf" });

    this.previewUrl = URL.createObjectURL(fileBlob);

    this.openPreview();
  }

  openPreview(): void {
    const newTab = window.open();
    newTab.document.body.innerHTML = `<embed src="${this.previewUrl}" width="100%" height="100%" type="application/pdf">`;
  }

  filterDistricts(e) {
    this.districtListNew = this.districtList.filter(
      (res) => res.RegionID == e.value
    );
  }

  filterShehia(e) {
    this.shehiaListNew = this.shehiaList.filter(
      (res) => res.DistrictID == e.value
    );
  }

  getDistricts() {
    const data = {
      requestType: "DISTRICT_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.districtList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getRegion() {
    const data = {
      requestType: "REGION_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.regionList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  shehia() {
    const data = {
      requestType: "SHEHIA",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.shehiaList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getSectors() {
    const data = {
      requestType: "SECTOR_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.sectorList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  nationality() {
    const data = {
      requestType: "NATIONALITY",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.nationalityDataSource = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  declaration(e) {
    const ischecked = (<HTMLInputElement>e.target).checked;

    if (ischecked) {
      this.isSubmitButtonShown = true;
      this.declarationValued = true;
    } else {
      this.isSubmitButtonShown = false;
      this.declarationValued = false;
    }
  }

  searchMember(e) {
    // if(this.typeSelected == false && this.modeSelected == false) {
    //   this.toastr.error("Select application type and mode to continue");
    //   return
    // }
    if (e) {
      this.searchForm.reset();
      this.memberInfo = [];
      //this.memberDatasource = []
      this.activateMemberNameSearch = false;
      this.activateMemberNumberSearch = false;
      this.searchKeySelected = false;
      this.showAlertDialog = true;
    }
  }

  previewDetails(e) {
    const data = {
      requestType: "MEMBER_GET",
      memberNumber: e.data.MemberNumber,
    };

    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.memberDetails = response.data;
          this.showAlertDialog = false;
          // this.names = response.data.FullName
          // this.mothersName = response.data.MothersName
          // //this.gender = response.data.Gender
          // this.physicalAddress = response.data.PhysicalAddress
          // this.postalAddress = response.data.PostalAddress
          // this.memberNumber = response.data.MemberNumber
          // this.lastEmployer = response.data.EmployerName
          // this.employerID = response.data.EmployerID
          // this.DateofBirth = response.data.DateofBirth
          // this.NumberOfContributions = response.data.NumberOfContributions
          // this.lastContributionYear = response.data.LastContributionYear
          // this.lastContributionMonth = response.data.LastContributionMonth
          // this.numOfContributions = response.data.NumberOfContributions
          // this.memberPhoto ='data:image/jpeg;base64,'+response.data.MemberPhoto
          // this.memberSignature = 'data:image/jpeg;base64,'+response.data.MemberSignature
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  searchEmployerPopup(e) {
    if (e) {
      this.searchForm.reset();
      this.employerInfoSearchResult = [];

      this.showAlertDialogEmp = true;
    }
  }

  onInputChange(event: any) {
    const newValue = event.trim();

    // Clear the previous input timer, if any
    clearTimeout(this.inputTimer);

    // Set a new input timer for 3 seconds (3000 milliseconds)
    this.inputTimer = setTimeout(() => {
      this.handleSearch(newValue);
    }, 2000);
  }

  handleSearch(e) {
    // const data = {
    //   "requestType": "MEMBER_GET",
    //   "memberNumber": this.searchForm.get("fullName").value,
    // };

    if (this.employerNameSearch) {
      this.data = {
        requestType: "COMPENSATION_CLAIM_SEARCH_EMPLOYERS",
        employersName: "%" + e + "%",
        employerNumber: "",
      };
    }

    if (this.employerNumberSearch) {
      this.data = {
        requestType: "COMPENSATION_CLAIM_SEARCH_EMPLOYERS",
        employersName: "",
        employerNumber: "%" + e + "%",
      };
    }

    this.utilities.postServiceCallNew(this.data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.employerInfoSearchResult = response.data;

          // this.searchForm.reset()
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  // searchEmployer()
  // {

  //   if(this.activateEmployerNameSearch)
  //   {
  //      this.data = {
  //       "requestType": "SEARCH_EMPLOYERS_LIST",
  //       "employersName": '%'+this.searchForm.get("searchValue").value+'%',
  //       "employerNumber": ''
  //    };
  //   }

  //   if(this.activateEmployerNumberSearch)
  //   {
  //     this.data= {
  //       "requestType": "SEARCH_EMPLOYERS_LIST",
  //       "employersName": '',
  //       "employerNumber": '%'+this.searchForm.get("searchValue").value+'%'
  //    };
  //   }

  //   this.spinner.show();
  //   this.utilities.postServiceCallNew(this.data).subscribe(
  //     (res) => {
  //       const response = res.json();
  //       if (response.code == 2000) {
  //         this.employerDatasource =response.data
  //         this.activateEmployerNameSearch = false
  //         this.activateEmployerNumberSearch = false
  //       } else {
  //         this.toastr.error(response.message, 'Error');
  //       }
  //       this.spinner.hide();
  //     },
  //     (error) => {
  //       this.spinner.hide();
  //       this.toastr.error(
  //         'Something went wrong please try again',
  //         'Request Failed'
  //       );
  //     }
  //   );
  // }

  previewDetailsEmployer(e) {
    this.employerNoSearch(e.data.EmployerNumber);
  }

  employerNoSearch(EmployerNumber) {
    const data = {
      requestType: "EMPLOYERS_GET",
      employerNumber: EmployerNumber,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.employerDetails = response.data;
          //this.employerNumber = response.data.EmployerNumber
          this.showAlertDialogEmp = false;
          this.showOtherDetails = true;
          this.getMembers(response.data.EmployerNumber);
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onCheckChangeEmployerNumberSelected(e) {
    const ischecked = (<HTMLInputElement>e.target).checked;

    if (ischecked) {
      this.activateEmployerNumberSearch = true;
      this.searchKeySelected = true;
    } else {
      this.activateEmployerNumberSearch = false;
      this.searchKeySelected = false;
    }
  }

  onCheckChangeEmployerNameSelected(e) {
    const ischecked = (<HTMLInputElement>e.target).checked;

    if (ischecked) {
      this.activateEmployerNameSearch = true;
      this.searchKeySelected = true;
    } else {
      this.activateEmployerNameSearch = false;
      this.searchKeySelected = false;
    }
  }

  getMembers(employerNumber: any) {
    const data = {
      requestType: "COMPENSATION_CLAIM_SEARCH_MEMBERS",
      employerNumber: employerNumber,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.membersList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  checkSelectedValue(e) {
    if (e.value == 1) {
      this.showDepartmentTextbox = true;
    } else {
      this.showDepartmentTextbox = false;
    }
  }

  selectedSearchCretiria(e) {
    if (e.value == 1) {
      this.searchForm.get("searchValue").reset();
      this.employerNumberSearch = true;
    } else {
      this.employerNumberSearch = false;
    }

    if (e.value == 2) {
      this.searchForm.get("searchValue").reset();
      this.employerNameSearch = true;
    } else {
      this.employerNameSearch = false;
    }

    //employerNameSearch
  }

  searchEmployer() {
    const serchedValue = `${this.fetchForm.get("empNo").value}`.trim();

    if (
      serchedValue == null ||
      serchedValue == "" ||
      serchedValue == undefined ||
      serchedValue.length < 1
    ) {
      this.searchForm.reset();
      this.employerInfoSearchResult = [];

      this.showAlertDialogEmp = true;
      return;
    }

    if (this.fetchForm.invalid) {
      this.toastr.error("Please enter member number");
      return;
    }

    this.NotificationDeathForm.reset();
    const data = {
      requestType: "EMPLOYERS_GET",
      employerNumber: `${this.fetchForm.get("empNo").value}`.trim(),
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.employerDetails = response.data;
          //this.employerNumber = response.data.EmployerNumber
          this.showAlertDialogEmp = false;
          this.showOtherDetails = true;
          //this.showSearch = false
          this.getMembers(response.data.EmployerNumber);
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
}
