import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-compensation-claim-info',
  templateUrl: './compensation-claim-info.component.html',
  styleUrls: ['./compensation-claim-info.component.scss']
})
export class CompensationClaimInfoComponent extends SharedClassComponent implements OnInit {
  claimInfo: any = [];

  tab_paneldata: any = [{
    ID: 1,
    icon: '',
    name: 'Employer Details',
}, {
    ID: 2,
    icon: '',
    name: 'Incident Details',
}, {
  ID: 3,
  icon: '',
  name: 'Medical Details',
},
{
  ID: 4,
  icon: '',
  name: 'Payment Details',
},
{
  ID: 5,
  icon: '',
  name: 'Claim Details',
},

{
  ID: 6,
  icon: '',
  name: 'Uploads',
}

];

 

  ngOnInit() {
    this.claimInfo =JSON.parse(sessionStorage.getItem("compensationClaimData"))
  }

}


