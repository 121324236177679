import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent extends SharedClassComponent  implements OnInit {

  invoiceDetails = [];
  invoiceId: any;
  invoiceType: any;
  customerId: any;
  customerName: any;
  postingDate: any;
  description: any;
  currency: any;
  amount: any;

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.invoiceId = params.invoiceId;
    });
    if (this.invoiceId === null || this.invoiceId === undefined) {
      return;
    }
    const data = {
      requestType: 'CUSTOMER_INVOICE_DETAILS',
      customerInvoiceId: this.invoiceId
    };
    this.spinner.show();
    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      this.spinner.hide();
      const serverResponse = res.json();
      if (serverResponse.code == 2000) {
        this.toastr.success(serverResponse.message);
        this.toastr.toastrConfig.closeButton = true;
        this.invoiceType = serverResponse.data.invoiceType;
        this.customerId =  serverResponse.data.customerId;
        this.customerName =  serverResponse.data.customerName;
        this.postingDate =  serverResponse.data.postingDate;
        this.description =  serverResponse.data.description;
        this.currency =  serverResponse.data.currency;
        this.amount =  serverResponse.data.amount;
        this.invoiceDetails = serverResponse.data.invoceDetails;
      } else {
        this.toastr.error(serverResponse.message);
        this.toastr.toastrConfig.closeButton = true;
        this.logServerErrors(this.router.url, serverResponse.message, 'CUSTOMER_INVOICE_DETAILS');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error);
      this.logServerErrors(this.router.url, error, 'CUSTOMER_INVOICE_DETAILS');
      this.toastr.toastrConfig.closeButton = true;
    });
  }

}
