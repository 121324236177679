import {InvoiceModel} from '../models/invoiceModel';

export class InvoiceState {
  invoice: Array<InvoiceModel>;
  invoiceError: Error;
}

export const initializeState = (): InvoiceState => {
  return {invoice: Array<InvoiceModel>(), invoiceError: null};
};
