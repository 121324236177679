import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-revenue-transaction',
  templateUrl: './revenue-transaction.component.html',
  styleUrls: ['./revenue-transaction.component.scss']
})
export class RevenueTransactionComponent extends SharedClassComponent implements OnInit   {
  title = 'Members List';
  apiEndpoint = 'employer/employees';
  EmployeesDataSource = [];

  ngOnInit() {
   this.appInfo.setTitle(this.title);
   this.observerCall();
   this.getEmployeesList();
  }

  getEmployeesList() {
    this.spinner.show();
    const data = {
      employerNumber: this.authService.getUserDetails().linkId
    };

    this.utilities.postServiceCall(data, this.apiEndpoint).subscribe(res => {
      this.spinner.hide();
      const serverRes = res.json();
      if (serverRes.code == 2000) {
        this.EmployeesDataSource = serverRes.data;

        for (const iterator of serverRes.data) {
          this.EmployeesDataSource.push({
            memberId: iterator.memberId,
            memberNumber: iterator.memberNumber,
            fullName: `${iterator.fisrtName} ${iterator.middleName} ${ iterator.surName}`,
            salaryBeforeTax: iterator.salaryBeforeTax
          });
        }

      } else {
        this.toastr.error(serverRes.message);
        this.logServerErrors(this.router.url, serverRes.message, 'GET_EMPLOYEES_LIST');
      }
    }, error => {
      this.logServerErrors(this.router.url, error, 'GET_EMPLOYEES_LIST');
     this.spinner.hide()
    }

    );
  }


  toolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
   {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getEmployeesList.bind(this)
        }
      }
    );
  }

}
