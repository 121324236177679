export const navigation = [
  {
    text: "Dashboard",
    path: "home",
  },
  {
    text: "User Account",
    items: [
      {
        text: "My profile",
        path: "profile",
      }
    ],
  },
  {
    text: "Contributions",
    items: [
      {
        text: "new Contribution",
        path: "contribution/upload",
      },
      {
        text: "Contributions Records",
        path: "contribution-records",
      },
    ],
  },
  {
    text: "Invoicing",
    items: [
      {
        text: "Published Invoices",
        path: "published-invoices",
      },
      {
        text: "Invoice Payments",
        path: "invoice-payments",
      },
      // {
      //   text: 'Penalty Records',
      //   path: 'invoices/penalties/records',
      // }
    ],
  },
  {
    text: "Self Service",
    items: [
      {
        text: "Feedback",
        path: "user-feedback",
      },
      {
        text: "Replies",
        path: "feedback/replies",
      },
    ],
  },
  {
    text: "Compensation",
    items: [
      {
        text: "Notification",
        path: "compensation-employer-notification",
      },
    ],
  },
];
//
export const navigation2 = [
  {
    text: "Dashboard",
    items: [
      {
        text: "Home",
        path: "home",
      },
    ],
  },
  {
    text: "User Account",
    items: [
      {
        text: "My profile",
        path: "profile",
      },
      {
        text: "Edit My Account",
        path: "profile",
      },
      {
        text: "Security Manager",
        path: "security-manager",
      },
      {
        text: "Access Logs",
      },
    ],
  },
  {
    text: "System Admnistration",
    items: [
      {
        text: "System Privileges",
        path: "system-privileges",
      },
      {
        text: "System Roles",
        path: "user-roles",
      },
      {
        text: "System Users",
        path: "user-management",
      },
      {
        text: "Phone Types",
        path: "phone-types",
      },
      {
        text: "System Settings",
        path: "settings",
      },
      {
        text: "Security Questions",
        path: "security-questions",
      },
    ],
  },
  {
    text: "Self Service",
    items: [
      {
        text: "Feedback",
        path: "user-feedback",
      },
      {
        text: "Help Desk",
      },
    ],
  },
];

export const investmentNavigation = [
  {
    text: "Dashboard",
    path: "/customers/dashboard",
  },
  {
    text: "User Account",
    items: [
      {
        text: "My profile",
        path: "profile",
      },
      
      // {
      //   text: "Employer Details",
      //   path: "employer-details-change",
      // },
    ],
  },
  {
    text: "Records",
    items: [
      {
        text: "Invoice Payments",
        path: "invoice-payments",
      },
    ],
  },
  {
    text: "Self Service",
    items: [
      {
        text: "Feedback",
        path: "user-feedback",
      },
    ],
  },
  {
    text: "Compensation",
    items: [
      {
        text: "Notification",
        path: "compensation-employer-notification",
      },
    ],
  },

  // {
  //   text: "Endorsement",
  //   items: [
  //     {
  //       text: "Members",
  //       path: "members-employer-endorsement-list",
  //     },
  //     {
  //       text: "Maternity",
  //       path: "maternity-employer-endorsement-list",
  //     },
  //     {
  //       text: "Long Term",
  //       path: "longterm-employer-endorsement-list",
  //     },
  //   ],
  // },

  // {
  //   text: "Certificate",
  //   path: "employer-certificate",
  // },
  // {
  //   text: 'Penalty List',
  //   items: [
  //     {
  //       text: 'Penality',
  //       path: 'penalty-list',
  //     }
  //   ],
  // },
];
