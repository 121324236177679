import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { AppComponent } from "./app.component";
import {
  SideNavOuterToolbarModule,
  SideNavInnerToolbarModule,
  SingleCardModule,
} from "./layouts";
import { FooterModule, LoginFormModule } from "./shared/components";
import { AuthService, ScreenService, AppInfoService } from "./shared/services";
import { AppRoutingModule } from "./app-routing.module";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgHttpLoaderModule } from "ng-http-loader"; // <============
import { AngularFontAwesomeModule } from "angular-font-awesome";
import {
  DxContextMenuModule,
  DxMenuModule,
  DxPopupModule,
  DxDataGridModule,
} from "devextreme-angular";
import { LayoutModule } from "@angular/cdk/layout";
import { OnlineServicesModule } from "./modules/online-services/online-services.module";
import { PublicServicesModule } from "./modules/public-services/public-services.module";
import { ConfirmationDialogueComponent } from "./utilities/confirmation-dialogue/confirmation-dialogue.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReplySectionComponent } from "./self-service/reply-section/reply-section.component";
import { OutgoingRequestInterceptor } from "./shared/interceptors/outgoing-requests.interceptor";
import { CompensationClaimRegisterComponent } from "./COMPENSATION/compensation-claim-register/compensation-claim-register.component";
import { CompensationClaimInfoComponent } from "./COMPENSATION/compensation-claim-info/compensation-claim-info.component";

@NgModule({
  declarations: [AppComponent, ConfirmationDialogueComponent],
  imports: [
    DxContextMenuModule,
    DxMenuModule,
    DxDataGridModule,
    BrowserModule,
    LayoutModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    DxPopupModule,
    FooterModule,
    OnlineServicesModule,
    PublicServicesModule,
    LoginFormModule,
    NgHttpLoaderModule.forRoot(),
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
  ],
  entryComponents: [ConfirmationDialogueComponent],
  providers: [
    AuthService,
    ScreenService,
    AppInfoService,
    HttpClientModule,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OutgoingRequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
