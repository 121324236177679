import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-associated-monthly-contributions-per-invoice',
  templateUrl: './associated-monthly-contributions-per-invoice.component.html',
  styleUrls: ['./associated-monthly-contributions-per-invoice.component.scss']
})
export class AssociatedMonthlyContributionsPerInvoiceComponent extends SharedClassComponent implements OnInit {

  ngOnInit() {
  }

}
