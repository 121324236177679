import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Headers } from "@angular/http";

import { map, timeout } from "rxjs/operators";
import notify from "devextreme/ui/notify";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
import { confirm } from "devextreme/ui/dialog";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import csv2json from "csvjson-csv2json/csv2json";

import XLSX from "xlsx-style/dist/xlsx";

const passwordStrengthValidator = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  const password = control.value;
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
  const isValid = password && strongPasswordRegex.test(password);

  if (!isValid) {
    return { invalidPassword: true };
  }
  return null;
};

const urlValidator = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  if (!control.value || control.value == "") {
    return null;
  }

  const urlPattern =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  return urlPattern.test(control.value) ? null : { invalidUrl: true };
};

@Component({
  selector: "app-employer-self-registration",
  templateUrl: "./employer-self-registration.component.html",
  styleUrls: ["./employer-self-registration.component.scss"],
})
export class EmployerSelfRegistrationComponent
  extends SharedClassComponent
  implements OnInit
{
  @ViewChild("kazinaukazi", { static: true })
  public kazinaukazi!: ElementRef;
  regType;
  copyRightBanner = "";
  isRegCompleted = false;
  maxDate: Date = new Date();
  investmentCustomerPhoneNumber: any;
  today: Date = new Date();

  cityPattern = "^[^0-9]+$";
  namePattern: any = /^[^0-9]+$/;
  phonePattern: any = /^(1|)?(\d{3})(\d{3})(\d{6})$/;
  countries: string[];
  phoneRules: any = {
    X: /[02-9]/,
  };
  endPoint = "users/verify";
  registrationEndPoint = "employer/create";

  registrationTypeForm: FormGroup;
  otpForm: FormGroup;
  showAlertDialogeForVerfication: boolean = false;
  alertDialogMessagesVerfication: any;
  isUserVerfied: boolean = false;
  shehiaListNew: any;
  shehiaList: any;
  showEmployerDetails: boolean = false;
  employerInfo: any;
  disableButton: boolean;
  openFileUploadDialog: boolean = false;

  value: any[] = [];
  customerVerificationForm: FormGroup;

  hideEmpRegForm = true;
  hideInvestmentReg = true;
  hideInvCustVerifyForm = false;
  hideEmployerReg = true;
  hideRegTypeOption = false;
  hideOTPform = true;
  hideCreateAccountForm = true;
  hideCreateUserForm = true;
  hideCreateInvestmentUserForm = true;
  hideInvCustRegForm = true;
  hideEmployerVerifyForm = true;

  showAlertDialog = false;
  alertReason = "";

  btntext = "Resend OTP";
  disableResendOTPbtn = false;

  timeout = 60000;
  resendOTPcounter = 0;
  sendToNumber;

  headerTitle = "ZSSF Employer Portal - Self Registration";

  password = "";
  passwordPattern =
    "(?=^.{6,}$)((?=.*d)|(?=.*W+))(?![.\n])(?=.*[A-Z])(?=.*[0-9]).*$";

  accountForm: FormGroup;
  localSectorWithPrivate = [
    {
      SectorID: 3,
      Description: "Private",
      AllowIndividualContributions: false,
    },
  ];
  // individual contributor
  hideINDReg = true;
  hideINDVerifyForm = true;
  indPhoneNumer = null;
  indEmail = null;
  hideINDRegForm = true;
  passwordRegex =
    "(?=[^A-Z]*[A-Z])(?=.*[!@#$%^&*])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{8,}";
  // passwordRegex = '(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[0-9]).*$';
  usernameClone = null;
  hideEmployerEnrollment: boolean = false;
  fumisEmployerCreateForm: FormGroup;
  sectorList: any;
  districtList: any;
  natureBusinessList: any;
  branchList: any;
  districtListNew: any;
  regionList: any;
  showAlertDialoge: boolean = false;
  alertDialogMessages: string;
  maxValue: number = 0;

  certificateDate = new Date();
  effectiveDate: any;
  uploadFailedMessage: string;
  selectedMembersList: any[] = [];
  hideInfoAlert: boolean;

  allowUpdate: boolean = true;
  selectedRegionalId: number;
  selectedDistrictId: number;
  selectedShehiaId: number;

  bankList = [];

  ngOnInit() {
    this.regType = [
      {
        id: 1,
        text: "Employer Registration",
      },
      {
        id: 2,
        text: "Check Registration Status",
      },
    ];
    this.getDate(this.certificateDate);
    this.customerVerificationForm = new FormGroup({
      customerID: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      invRegType: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
    this.copyRightBanner = this.appInfo.getCopyRightText();
    this.maxDate = new Date(
      this.maxDate.setFullYear(this.maxDate.getFullYear() - 21)
    );

    this.registrationTypeForm = new FormGroup({
      registerAs: new FormControl(1, Validators.compose([Validators.required])),
    });

    this.otpForm = new FormGroup({
      otp: new FormControl("", Validators.compose([Validators.required])),
    });
    this.fumisEmployerCreateForm = new FormGroup({
      emName: new FormControl("", Validators.compose([Validators.required])),
      altName: new FormControl("", Validators.compose([])),
      acronomy: new FormControl("", Validators.compose([])),
      regNo: new FormControl("", Validators.compose([Validators.required])),
      district: new FormControl("", Validators.compose([Validators.required])),
      shehia: new FormControl("", Validators.compose([Validators.required])),
      sector: new FormControl("", Validators.compose([Validators.required])),
      natureBusines: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      postalAddress: new FormControl("", Validators.compose([])),
      physicalAddress: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),

      opDate: new FormControl("", Validators.compose([Validators.required])),

      emailAddress: new FormControl("", Validators.compose([Validators.email])),
      mobile: new FormControl(
        "",
        Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      telephone: new FormControl(
        "",
        Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      faxnumber: new FormControl("", Validators.compose([])),
      contactPersonMob: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^0\d{9}$/),
        ])
      ),

      contactPersonTitle: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),

      contactPersonFirstName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      contactPersonMiddleName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      contactPersonLastName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      contactPersonUsername: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      contactPersonPassword: new FormControl(
        "",
        Validators.compose([Validators.required, passwordStrengthValidator])
      ),
      website: new FormControl("", Validators.compose([urlValidator])),

      attachment: this.fb.array([this.createattachment(this.maxValue)]),
      certificateOfIncorporation: new FormControl("", Validators.compose([])),
      memorandum: new FormControl("", Validators.compose([])),
      articleOfAssociation: new FormControl("", Validators.compose([])),
    });

    this.getDistricts();
    this.getSectors();
    this.businessNature();
    this.shehia();
    this.getRegion();
  }

  allowFormUpdate() {
    this.allowUpdate = false;
  }

  employerUpdate() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const phonePattern = /^[0-9]{10}$/;
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    if (
      this.employerInfo.TelephoneMobile &&
      !phonePattern.test(this.employerInfo.TelephoneMobile)
    ) {
      this.toastr.error(
        "Invalid Telephone Office Number. Please provide a valid 10-digit phone number.",
        "Error"
      );

      return;
    }

    if (
      this.employerInfo.TelephoneOffice &&
      !phonePattern.test(this.employerInfo.TelephoneOffice)
    ) {
      this.toastr.error(
        "Invalid Telephone Mobile Number. Please provide a valid 10-digit phone number.",
        "Error"
      );

      return;
    }

    if (
      this.employerInfo.Website &&
      !urlPattern.test(this.employerInfo.Website)
    ) {
      this.toastr.error(
        "Invalid Website. Please provide a valid Website Url.",
        "Error"
      );

      return;
    }

    if (
      this.employerInfo.EmailAddress &&
      !emailPattern.test(this.employerInfo.EmailAddress)
    ) {
      this.toastr.error(
        "Invalid email address. Please provide a valid email.",
        "Error"
      );

      return;
    }
    const result = confirm(
      "Do you really want to Save changes.",
      "Update Employer Infomation"
    );
    result.then((dialogResult) => {
      console.log(dialogResult);

      if (!dialogResult) {
        this.allowUpdate = true;
        return;
      }

      const data = {
        requestType: "EMPLOYER_DETAILS_CHANGE_UPDATE",
        employerId: this.employerInfo.EmployerID,

        AlternateName: this.employerInfo.AlternateName,
        PostalAddress: this.employerInfo.PostalAddress,
        PhysicalAddress: this.employerInfo.PhysicalAddress,
        EmailAddress: this.employerInfo.EmailAddress,
        Website: this.employerInfo.Website,
        TelephoneOffice: this.employerInfo.TelephoneOffice,
        TelephoneMobile: this.employerInfo.TelephoneMobile,
        RegionID: this.selectedRegionalId,
        DistrictID: this.selectedDistrictId,
        ShehiaID: this.selectedShehiaId,
      };

      this.spinner.show();

      this.utilities.postServiceCallNew(data).subscribe(
        (res) => {
          const response = res.json();
          this.spinner.hide();
          if (response.code == 2000) {
            this.allowUpdate = true;
            this.showEmployerDetails = false;
            this.toastr.success(
              "Employer updated Succesfull, Please wait for Admin Approval"
            );
            this.paramswinpnl = false;
          } else {
            // window.location.href = "memberregistration:"  + "&" + AppSettings.baseURL;
            this.toastr.error(response.message, "Error msg");
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            "Something went wrong, please try again",
            "Request Failed"
          );
        }
      );
    });
  }

  filterDistrictsNew(e) {
    this.districtListNew = this.districtList.filter((res) => res.RegionID == e);
    this.selectedRegionalId = e;
  }

  filterShehiaNew(e) {
    this.shehiaListNew = this.shehiaList.filter((res) => res.DistrictID == e);
    this.selectedDistrictId = e;
  }

  shehiaChangeNew(e) {
    this.selectedShehiaId = e;
  }
  getDate(dateVal) {
    this.effectiveDate =
      ("0" + new Date(dateVal).getDate()).slice(-2) +
      "-" +
      ("0" + (new Date(dateVal).getMonth() + 1)).slice(-2) +
      "-" +
      new Date(dateVal).getFullYear();
  }

  filterShehia(e) {
    this.shehiaListNew = this.shehiaList.filter(
      (res) => res.DistrictID == e.value
    );
  }

  registrationTypeSubmit() {
    if (this.registrationTypeForm.invalid) {
      this.toastr.error("Please choose registration type to contrinue!");
      return;
    }

    this.hideEmployerEnrollment = false;
    // register as employer
    if (this.registrationTypeForm.get("registerAs").value === 2) {
      this.hideInvCustVerifyForm = true;
      this.hideINDVerifyForm = true;

      this.hideRegTypeOption = true;
      this.hideEmpRegForm = true;
      this.hideEmployerVerifyForm = false;
      this.hideEmployerReg = false;
      this.headerTitle = "ZSSF Employer Portal - Self Registration";
    }

    if (this.registrationTypeForm.get("registerAs").value === 1) {
      // this.router.navigate(["employer-create"])
      this.hideEmployerEnrollment = true;
    }
  }
  onFileChangeCertificateOfIncorporation(event: any): void {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const acceptedFileTypes = ["application/pdf"];

      if (acceptedFileTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const base64String = e.target.result as string;
          const bas64 = base64String.split(",")[1];
          const fileControl = this.fumisEmployerCreateForm.get(
            "certificateOfIncorporation"
          ) as FormControl | null;

          if (fileControl) {
            fileControl.patchValue(bas64);

            const fileName = file.name;
            const label = event.target.nextElementSibling;
            label.innerText = fileName;
          }
        };

        reader.readAsDataURL(file);
      } else {
        this.toastr.error("Unsupported file type, only PDF are allowed");
      }
    }
  }

  onFileChangeMemorandum(event: any): void {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const acceptedFileTypes = ["application/pdf"];

      if (acceptedFileTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const base64String = e.target.result as string;
          const bas64 = base64String.split(",")[1];
          const fileControl = this.fumisEmployerCreateForm.get(
            "memorandum"
          ) as FormControl | null;

          if (fileControl) {
            fileControl.patchValue(bas64);

            const fileName = file.name;
            const label = event.target.nextElementSibling;
            label.innerText = fileName;
          }
        };

        reader.readAsDataURL(file);
      } else {
        this.toastr.error("Unsupported file type, only PDF are allowed");
      }
    }
  }

  onFileChangeArticleOfAssociation(event: any): void {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const acceptedFileTypes = ["application/pdf"];

      if (acceptedFileTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const base64String = e.target.result as string;
          const bas64 = base64String.split(",")[1];
          const fileControl = this.fumisEmployerCreateForm.get(
            "articleOfAssociation"
          ) as FormControl | null;

          if (fileControl) {
            fileControl.patchValue(bas64);

            const fileName = file.name;
            const label = event.target.nextElementSibling;
            label.innerText = fileName;
          }
        };

        reader.readAsDataURL(file);
      } else {
        this.toastr.error("Unsupported file type, only PDF are allowed");
      }
    }
  }

  createattachment(index) {
    return this.fb.group({
      document: [""],

      id: [index],
    });
  }
  get attachment() {
    return this.fumisEmployerCreateForm.get("attachment") as FormArray;
  }

  addattachment() {
    const max = this.maxValue++;
    this.attachment.push(this.createattachment(max + 1));
  }

  onFileChange(event: any, index: number): void {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const acceptedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (acceptedFileTypes.includes(file.type)) {
        this.convertToBase64(file, index, event.target);
      } else {
        this.toastr.error(
          "Unsupported file type, only PDF and Images are allowed"
        );
        return;
      }
    }
  }

  convertToBase64(file: File, index: number, inputElement: any): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64String = e.target.result as string;
      const bas64 = base64String.split(",")[1];
      const fileControl = this.attachment
        .at(index)
        .get("document") as FormControl | null;

      if (fileControl) {
        fileControl.patchValue(bas64);

        // Update the label text to display the file name
        const fileName = file.name;
        const label = inputElement.nextElementSibling;
        label.innerText = fileName;
      }
    };

    reader.readAsDataURL(file);
  }

  onBackPressed() {
    this.hideRegTypeOption = false;
    this.hideEmployerEnrollment = false;
    this.hideINDReg = true;
    this.hideINDRegForm = true;
    this.hideEmpRegForm = true;
    this.hideInvestmentReg = true;
    this.hideInvCustVerifyForm = true;
    this.hideEmployerVerifyForm = true;

    this.customerVerificationForm.reset();

    this.fumisEmployerCreateForm.reset();
    this.selectedMembersList = [];
  }

  navigateToUser() {
    this.router.navigate(["registration"]);
    // this.router.navigate(["registration"], {
    //   queryParams: { v: true },
    //   queryParamsHandling: "merge",
    // });
  }

  hideDialog() {
    this.showAlertDialog = false;
  }

  onEmployerNumberValidation() {
    this.showAlertDialogeForVerfication = false;
    if (this.customerVerificationForm.get("customerID").invalid) {
      this.toastr.error("Please enter your employer number for verification");
      return;
    }
    const data = {
      requestType: "EMPLOYER_CHECK_STATUS",
      employerID: this.customerVerificationForm.get("customerID").value,
    };
    this.hideInvestmentReg = true;
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (response) => {
        this.spinner.hide();

        const res = response.json();
        if (res.code == 2000) {
          this.toastr.success(res.message);

          this.showAlertDialogeForVerfication = true;
          this.alertDialogMessagesVerfication = res.data[0].Status;
          this.isUserVerfied = res.data[0].IsActive;

          // TEMP32710
        } else {
          this.toastr.error(res.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error("Something went wrong please try again.");
        this.spinner.hide();
      }
    );
  }

  finish() {
    this.isRegCompleted = false;
    this.hideRegTypeOption = true;
    this.hideINDReg = true;
    this.hideINDRegForm = true;
    this.hideEmpRegForm = true;
    this.hideInvCustVerifyForm = true;
    this.hideInvCustRegForm = true;
    this.hideEmployerReg = true;

    this.router.navigate(["/logn"]);
  }

  hideEmail(email) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  }

  findDuplicates(arr, value) {
    const seen = new Set();
    const duplicates = [];

    arr.forEach((member) => {
      if (seen.has(member[value])) {
        duplicates.push(member);
        return duplicates;
      } else {
        seen.add(member[value]);
      }
    });

    return duplicates;
  }

  checkForInvalidSalary(arr) {
    const membersList = [];

    arr.forEach((member) => {
      if (
        member.salary === 0 ||
        member.salary === null ||
        member.salary == "" ||
        member.salary === undefined
      ) {
        membersList.push(member);
        return membersList;
      }
    });

    return membersList;
  }

  checkForInvalidName(arr) {
    const membersList = [];

    arr.forEach((member) => {
      if (
        member.name === null ||
        member.name === "" ||
        member.name === undefined
      ) {
        membersList.push(member);
        return membersList;
      }
    });

    return membersList;
  }
  createEmployer() {
    const newMembersList = this.selectedMembersList.map((member) => {
      return {
        memNo: member.memberNumber || "",
        name: member.memberNames || "",
        salary: member.memberSalary ? member.memberSalary : 0,
      };
    });

    const duplicatesMemberNo = this.findDuplicates(newMembersList, "memNo");

    if (duplicatesMemberNo.length != 0) {
      this.toastr.error(
        "Duplicate Member Number Found " + duplicatesMemberNo[0].name
      );
      return;
    }

    const duplicatesMemberName = this.findDuplicates(newMembersList, "name");
    if (duplicatesMemberName.length != 0) {
      this.toastr.error(
        "Duplicate Member Name Found " + duplicatesMemberName[0].name
      );
      return;
    }

    const inValidSalary = this.checkForInvalidSalary(newMembersList);

    if (inValidSalary.length != 0) {
      this.toastr.error(
        "Error: Member salary is empty or 0 for  " + inValidSalary[0].name
      );
      return;
    }

    const inValidMemberName = this.checkForInvalidName(newMembersList);

    if (inValidMemberName.length != 0) {
      this.toastr.error(
        "Error: Member Name is empty for  " + inValidSalary[0].memNo
      );
      return;
    }
    const attachment = this.fumisEmployerCreateForm
      .get("attachment")
      .value.map(({ id, ...rest }) => rest);

    let isattachmentHasError = false;
    if (this.attachment.length != 1) {
      attachment.forEach((item) => {
        if (item.document === "") {
          isattachmentHasError = true;
        }
      });
    }

    const newAttachment = attachment.filter((item) => item.document !== "");

    if (isattachmentHasError) {
      this.toastr.error(
        "There is more than one attachemnt fields please fill all!",
        "Error"
      );
      return;
    }

    const errorMessages = {
      contactPersonTitle: "Contact Person Title is required",
      contactPersonFirstName: "Contact Person First Name is required",
      contactPersonMiddleName: "Contact Person Middle Name is required",
      contactPersonLastName: "Contact Person Last Name is required",
      contactPersonUsername: "Contact Person Username is required",
      contactPersonPassword:
        "Contact Person Password should have at least 8 characters length, including 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character",
      emName: "Employer Name is required",
      altName: "",
      acronomy: "",
      regNo: "Registration Number is required",
      district: "District is required",
      shehia: "Shehia is required",

      sector: "Sector is required",
      natureBusines: "Nature of teh business is reqired",
      postalAddress: "Postal Address is required",
      physicalAddress: "Physical Address is required",

      opDate: "Operation Date is required",
      emailAddress: "Invalid Email Address",
      mobile: "Mobile No  must be 10 digits",
      telephone: "Telephone Office Mobile number  must be 10 digits",
      contactPersonMob:
        "Contact Person Mobile number is required and must be 10 digits",
      contactPersonName: "Contact Person Name is required",

      faxnumber: "Invalid fax number",

      branchCode: "",
      website: "Invalid website URL",
    };

    for (const controlName in this.fumisEmployerCreateForm.controls) {
      const control = this.fumisEmployerCreateForm.get(controlName);
      if (control.invalid) {
        let errorMessage = errorMessages[controlName];

        // Handle email validation
        if (
          controlName === "emailAddress" &&
          control.hasError("emailAddress")
        ) {
          errorMessage = errorMessages.emailAddress;
        }

        // Handle mobile number validation
        if (controlName === "mobile" && control.hasError("mobile")) {
          errorMessage = errorMessages.mobile;
        }

        if (
          controlName === "contactPersonMob" &&
          control.hasError("contactPersonMob")
        ) {
          errorMessage = errorMessages.contactPersonMob;
        }

        // Handle telephone number validation
        if (controlName === "telephone" && control.value) {
          // Check if telephone has a value
          if (control.hasError("telephone")) {
            errorMessage = errorMessages.telephone;
          }
        }

        this.toastr.error(errorMessage);
        return;
      }
    }

    if (this.fumisEmployerCreateForm.get("emName").invalid) {
      this.toastr.error("Employer name required");
      return;
    }
    if (this.fumisEmployerCreateForm.get("regNo").invalid) {
      this.toastr.error("Registration number required");
      return;
    }

    if (this.fumisEmployerCreateForm.get("district").invalid) {
      this.toastr.error("District required");
      return;
    }

    if (this.fumisEmployerCreateForm.get("opDate").value == null) {
      this.fumisEmployerCreateForm.get("opDate").patchValue(this.today);
    }

    const operationDate =
      new Date(this.fumisEmployerCreateForm.get("opDate").value).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(this.fumisEmployerCreateForm.get("opDate").value).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(this.fumisEmployerCreateForm.get("opDate").value).getDate()
      ).slice(-2);

    const data = {
      requestType: "ONLINE_EMPLOYER_CREATE",

      employerName: this.fumisEmployerCreateForm.get("emName").value,
      alternateName: this.fumisEmployerCreateForm.get("altName").value,
      acronym: this.fumisEmployerCreateForm.get("acronomy").value,
      registrationnumber: this.fumisEmployerCreateForm.get("regNo").value,
      districtId: this.fumisEmployerCreateForm.get("district").value,
      shehiaId: this.fumisEmployerCreateForm.get("shehia").value,

      sectorId: this.fumisEmployerCreateForm.get("sector").value,
      natureOfBusiness: this.fumisEmployerCreateForm.get("natureBusines").value,
      postalAddress: this.fumisEmployerCreateForm.get("postalAddress").value,
      physicalAddress:
        this.fumisEmployerCreateForm.get("physicalAddress").value,

      firstOperationDate: operationDate,
      emailAddress: this.fumisEmployerCreateForm.get("emailAddress").value,
      website: this.fumisEmployerCreateForm.get("website").value,
      telephoneOffice: this.fumisEmployerCreateForm.get("telephone").value,
      telephoneMobile: this.fumisEmployerCreateForm.get("mobile").value,
      faxNumber: this.fumisEmployerCreateForm.get("faxnumber").value,
      contactPersonMob:
        this.fumisEmployerCreateForm.get("contactPersonMob").value,
      isActive: "1",

      contactPersonTitle:
        this.fumisEmployerCreateForm.get("contactPersonTitle").value,

      contactPersonFirstName: this.fumisEmployerCreateForm.get(
        "contactPersonFirstName"
      ).value,
      contactPersonMiddleName: this.fumisEmployerCreateForm.get(
        "contactPersonMiddleName"
      ).value,
      contactPersonLastName: this.fumisEmployerCreateForm.get(
        "contactPersonLastName"
      ).value,
      contactPersonUsername: this.fumisEmployerCreateForm.get(
        "contactPersonUsername"
      ).value,
      contactPersonPassword: this.fumisEmployerCreateForm.get(
        "contactPersonPassword"
      ).value,

      certificateOfIncorporation: this.fumisEmployerCreateForm.get(
        "certificateOfIncorporation"
      ).value,
      memorandum: this.fumisEmployerCreateForm.get("memorandum").value,
      articleOfAssociation: this.fumisEmployerCreateForm.get(
        "articleOfAssociation"
      ).value,

      attachment: newAttachment,
      members: newMembersList,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (response) => {
        this.spinner.hide();
        // this.toastr.success("Operation Successfully")
        // this.fumisEmployerCreateForm.reset()
        // this.router.navigate(['fumis-employer-info',])

        const res = response.json();
        if (res.code == 2000) {
          this.toastr.success(res.message);

          this.selectedMembersList = [];
          const employerNo = res.data.EmployerNumber;
          this.alertDialogMessages =
            `Employer has been created, with this Employer Number ` +
            employerNo;
          this.showAlertDialoge = true;
          // this.router.navigate(['fumis-employer-info',res.data[0].EmployerNumber])
        } else {
          this.toastr.error(res.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error("Something went wrong please try again.");
        this.spinner.hide();
      }
    );
  }

  hidePopUp() {
    this.fumisEmployerCreateForm.reset();
    this.selectedMembersList = [];
    this.hideEmployerEnrollment = false;
    this.showAlertDialoge = false;
  }

  hidePopUpVerfication() {
    this.hideEmployerEnrollment = false;
    this.showAlertDialoge = false;
    this.showAlertDialogeForVerfication = false;
  }

  getSectors() {
    const data = {
      requestType: "SECTOR_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.sectorList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  employerGetDetails() {
    if (this.otpForm.invalid) {
      this.toastr.error("Please Enter OPT", "Failed");
      return;
    }
    const data = {
      requestType: "EMPLOYER_REG_GET_DETAILS",
      employerNo: this.customerVerificationForm.get("customerID").value,
      otpCode: this.otpForm.get("otp").value,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        this.spinner.hide();
        const response = res.json();
        if (response.code == 2000) {
          this.toastr.success(response.message, "Sucess");
          this.employerInfo = response.data;
          this.selectedRegionalId = Number(response.data.RegionID);
          if (this.selectedRegionalId) {
            this.filterDistrictsNew(Number(this.selectedRegionalId));
          }
          this.selectedDistrictId = Number(response.data.DistrictID);

          this.filterShehiaNew(Number(this.selectedDistrictId));

          this.selectedShehiaId = Number(response.data.ShehiaID);
          this.showEmployerDetails = true;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  sendOtp() {
    const result = confirm(
      "Are you sure you want to send OTP ?",
      "OPT Verification"
    );

    result.then((dialogResult) => {
      if (dialogResult) {
        const data = {
          requestType: "EMPLOYER_REG_OTP",
          employerNo: this.customerVerificationForm.get("customerID").value,
        };
        this.spinner.show();
        this.utilities.postServiceCallNew(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.code == 2000) {
              this.toastr.success(response.message, "Sucess");
            } else {
              this.toastr.error(response.message, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  openPdfInNewTab(base64String): void {
    if (!base64String) {
      this.toastr.error("No document");
      return;
    }
    const blobUrl = this.convertBase64ToBlobUrl(
      base64String,
      "application/pdf"
    );
    window.open(blobUrl, "_blank");
  }

  convertBase64ToBlobUrl(base64: string, contentType: string): string {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return URL.createObjectURL(blob);
  }
  shehia() {
    const data = {
      requestType: "SHEHIA",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.shehiaList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getDistricts() {
    const data = {
      requestType: "DISTRICT_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.districtList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  businessNature() {
    const data = {
      requestType: "NATURE_OF_BUSINESS_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.natureBusinessList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  filterDistricts(e) {
    this.districtListNew = this.districtList.filter(
      (res) => res.RegionID == e.value
    );
  }

  getRegion() {
    const data = {
      requestType: "REGION_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.regionList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  downloadCertificate() {
    if (this.disableButton) return;
    this.spinner.show();
    this.disableButton = true;

    let content: any;
    content = this.kazinaukazi.nativeElement;
    content.style.display = "block";

    // Set content dimensions
    const contentWidth = 230; // Desired width in mm
    content.style.width = `${contentWidth}mm`;
    content.style.maxWidth = `${contentWidth}mm`;

    const newFontSize = 20; // Set desired smaller font size
    const style = document.createElement("style");
    style.innerHTML = `
      .download-doc{

        overflow: hidden; // Prevent overflow
         color: #000000 !important;
      }
    `;
    content.appendChild(style);

    // Scale factor to fit A4 width (210mm)
    const scaleFactor = 210 / contentWidth;

    html2canvas(content, {
      useCORS: true,
      scale: scaleFactor, // Scale canvas
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Add the image to PDF
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        // Save the PDF
        pdf.save("certificate.pdf"); // Update filename as needed
        content.style.display = "none";
        this.spinner.hide();
        this.disableButton = false;
      })
      .catch((error) => {
        console.error(error);
        this.spinner.hide();
        content.style.display = "none";
        this.disableButton = false;
      });
  }

  openPopUp() {
    this.value = [];

    this.openFileUploadDialog = true;
  }

  onFileValueChangedNew(event) {
    this.selectedMembersList = [];

    this.uploadFailedMessage = "Loading....";

    if (
      `${event.file.name}`.endsWith(".xlsx") ||
      `${event.file.name}`.endsWith(".xls")
    ) {
      this.parseXLXSNew(event);
      return;
    }
  }

  parseXLXSNew = (event) => {
    this.selectedMembersList = [];

    let totalContributionsAmt = 0;
    this.uploadFailedMessage = "Loading....";
    let reader = new FileReader();

    reader.onload = () => {
      let data = reader.result;
      let workbook = XLSX.read(data, {
        type: "binary",
      });
      this.hideInfoAlert = false;

      workbook.SheetNames.forEach((sheetName) => {
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        let json = XL_row_object;
        this.uploadFailedMessage =
          "Converting excel data format to json format....";

        let memberSalaryConverted: any;
        let counter = 0;

        this.spinner.show();

        for (let i = 0; i < json.length; i++) {
          if (!json[0].hasOwnProperty("memberNumber")) {
            this.showAlertDialog = true;
            this.alertReason = `Please include memberNumber header in your excelsheet on member numbers column.`;
            this.spinner.hide();
            return;
          }

          if (!json[0].hasOwnProperty("memberName")) {
            this.showAlertDialog = true;
            this.alertReason = `Please include memberName header in your excelsheet on member names column.`;
            this.spinner.hide();
            return;
          }

          if (!json[0].hasOwnProperty("memberSalary")) {
            this.showAlertDialog = true;
            this.alertReason = `Please include memberSalary header in your excelsheet on members salary column.`;

            this.spinner.hide();
            return;
          }

          if (
            json[i].memberSalary == null ||
            json[i].memberSalary == "" ||
            json[i].memberSalary === undefined
          ) {
            this.showAlertDialog = true;
            this.alertReason = `Member Salary is Missing, please update your member\'s salary in the excel sheet.`;

            this.spinner.hide();
            return;
          }

          if (
            json[i].memberName == null ||
            json[i].memberName == "" ||
            json[i].memberName == undefined
          ) {
            this.showAlertDialog = true;
            this.alertReason = `MemberName column in your excelsheet is empty, please include it in your excelsheet.`;

            this.spinner.hide();
            return;
          }

          if (
            isNaN(json[i].memberSalary) &&
            json[i].memberSalary !== undefined &&
            json[i].memberSalary != ""
          ) {
            memberSalaryConverted = json[i].memberSalary.toString();
            memberSalaryConverted = +memberSalaryConverted.replace(/,/g, "");
          } else {
            memberSalaryConverted = Number(json[i].memberSalary);
          }
          if (!isNaN(memberSalaryConverted)) {
            if (memberSalaryConverted < 0) {
              this.showAlertDialog = true;
              this.alertReason = `Negative amount is not allowed for members salaries.`;
              this.spinner.hide();
              return;
            }
          }

          let memberNumber = `${json[i].memberNumber}`;
          let memberNames = `${json[i].memberName}`;

          if (memberNumber === null || memberNumber === undefined) {
            memberNumber = "";
          }

          if (
            `${memberNames}`.trim() === "" ||
            memberNames === null ||
            memberNames === undefined
          ) {
            memberNames = "";
          }
          const roundToTwo = (num) => parseFloat(num.toFixed(2));
          const data = {
            id: Math.floor(Math.random() * (50000 - 100 + 1)) + 100,
            memberNames: memberNames || "",
            memberSalary: (memberSalaryConverted) || 0,
            memberNumber: memberNumber === "" ? "" : memberNumber,
          };

          this.selectedMembersList.push(data);
        }

        this.uploadFailedMessage = "Conversion completed....";

        this.spinner.hide();
        this.openFileUploadDialog = false;
      });
    };

    reader.onloadend = () => {
      this.uploadFailedMessage = "Data uploaded successfully....";
      this.openFileUploadDialog = false;
    };

    reader.onerror = (ex) => {};

    reader.readAsBinaryString(event.file);
    // this.hideEmployeeUploadForm = true;
    // this.hideEmployeeListDataGrid = false;
  };

  isAmountIsZero = (e: any) => {
    const currentValue = e.value;
    const isNotZero = currentValue != 0;
    return isNotZero;
  };

  onRowInserting(e) {
    const data = e.data;
    data.id = Math.floor(Math.random() * (50000 - 100 + 1)) + 100;
  }

  printCertificate() {
    setTimeout(() => {
      //this.isInvoicePrinted = true;
      let popUpWindow;

      // Clone the content
      const printContent = document
        .getElementById("print-ssf4")
        .cloneNode(true) as Element;

      // Remove unwanted elements
      const unwantedElements =
        printContent.querySelectorAll(".pull-to-refresh");
      unwantedElements.forEach((element) => element.remove());

      const innerContents = document.getElementById("print-ssf4").innerHTML;
      popUpWindow = window.open(
        "",
        "_blank",
        "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
      );
      // popUpWindow.document.open();
      popUpWindow.document.write(`<html lang="en">
        <head>
   
        <style>
        body{
         font-size: 14px;
         /*color: #333;*/
       }
       .space{
         display: flex;
         justify-content: space-between;
         border: 1px dotted #4d88bb;
         padding: 1rem 0;
       }
       .logo__block{
       /* border-bottom: 1px dotted #4d88bb; */
       /* border-bottom: 1px dotted black; */
       }
       .logo__block p, .footer, p {
         /* color: #4d88bb; */
       }
       .invoice__head__child_contacts{
         margin: 19px;
       }
       .invoice_wrapper {
         position: absolute;
       }
       .invoice_wrapper, .invoice{
         /*background-color: rgba(0, 0, 0, 0.055);*/
         padding: 2rem;
         width: fit-content;
         height: 100%;
       }
       .invoice__head,
       .invoice__how_to_pay{
         display: flex;
         justify-content: space-between;
       }
       .invoice__body{
         display: flex;
         /* justify-content: center; */
       }
       .invoice__body table tr th{
         background: #035817;
         color:white;
         text-align:left;
         vertical-align:center;
       }
       .invoice__body table tr {
         background-color: rgba(136 ,136 ,136, .1);
         border: 1px solid #ddd;
         padding: .35em;
       }
       .logo__block, .footer{
           text-align: center;
       }
       .full_table{
         width: 100%;
       }
       .divide {
         position: relative;
         min-height: 80%;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
       }
       /* .watermark {
       position:fixed;
       font-size: 88px;
       bottom: 0;
       opacity:0.5;
       z-index:99;
       color: #35313126;
       text-align: center;
       } */
       table tbody tr td{
       padding: .5rem;
       }


       .certi-title{
         display: flex;
         justify-content: center;
         align-items: center;
             margin-left: 30px !important;
       }
     
       .certi-title p{
         font-size: 20px;
         font-weight: bold;
         text-align: center;
       }
     
      

       .content{
         padding-top: 15px !important;
       
       }
     
     

       .border-blue{
     
         border: 5px solid green;
         padding:4px;
   
         height: 98.5%;
       }
     
       .border-green{
         border: 5px solid blue;
         
         padding: 4px;
         height: 98.5%;
       }
     
       .border-yellow{
         border: 5px solid yellow;
         
         padding: 4px;
         height: 98.5%;
       }

       
     
       .red-circle{
      margin-top: 40px !important;
    margin-bottom: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #781351;
     height: 110px;
    width: 130px;
    border-radius: 50%;
    margin: auto;
       }

       .form-container{
         margin: 10px;
         background-color: white !important;
         display: flex;
         justify-content: center;
         align-items: center;
       }

       @media print {

         .pull-to-refresh {
           display: none !important;
         }
         

         .section{
          display: flex;
          justify-content: center;
          align-items: center;
        }
 
       @page {
         size: A4;
         margin: 0mm;
       }

       html, body {
         width: 1024px;
       }

       body {
         margin: 0 auto;
         line-height: 1em;
         word-spacing:2px;
         letter-spacing:0.2px;
         /* font: 14px "Times New Roman", Times, serif; */
         font-size: 17px;
         background:rgba(0, 0, 0, 0.1);
padding-top: 20px;
         color:black;
         width: 100%;
         float: none;
       }
       .invoice__body table tr th{
         padding: 5px;
       }
       .footer{
         position: absolute;
         bottom: 10px;
         width: 100%;
         text-align: center;
       }
       /* avoid page-breaks inside a listingContainer*/
       .listingContainer{
         page-break-inside: avoid;
       }
       h1 {
           font: 28px "Times New Roman", Times, serif;
         }

         h2 {
           font: 26px "Times New Roman", Times, serif;
          
         }

         h3 {
           font: 20px "Times New Roman", Times, serif;
         }

         /* Improve colour contrast of links */
         a:link, a:visited {
           color: #781351
         }

         /* URL */
         a:link, a:visited {
           background: transparent;
           color:#333;
           text-decoration:none;
         }

         a[href]:after {
           content: "" !important;
         }
         a[href^="http://"] {
           color:#000;
         }

         #header {
           height:75px;
           font-size: 24pt;
           color:black
         }
       }

        </style>
        </head>
        <body onload="window.print()">${innerContents}</html>`);
      popUpWindow.document.close();
    }, 500);
  }
}
