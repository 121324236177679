import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-account-types',
  templateUrl: './account-types.component.html',
  styleUrls: ['./account-types.component.scss']
})
export class AccountTypesComponent extends SharedClassComponent implements OnInit {
  title = 'Contribution Records';
  tabPanelData: any = [{
    id: 1,
    name: 'Uncontributed Periods Records',
}];

  customerDetails = [];
  uncontributedPeriod: any;
  yearsMonths = [];

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.getUncontributedPeriods();
  }
  getUncontributedPeriods() {
    const data = {
      requestType: 'UNCONTRIBUTED_PERIODS',
      userType: this.authService.getUserDetails().userGroup,
      linkId: this.authService.getUserDetails().linkId
    };

    this.spinner.show();
    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      const serverResponse = res.json();
      this.spinner.hide();
      if (serverResponse.code == 2000) {
        this.customerDetails.push({
        customerId: serverResponse.data.customerId,
        customerNo: serverResponse.data.customerNo,
        customerType: serverResponse.data.customerType,
        customerName: serverResponse.data.customerName,
        });
        this.uncontributedPeriod = serverResponse.data.uncontributedPeriods;
        let counter = 0;
        for (const iterator of serverResponse.data.uncontributedPeriods) {
          this.yearsMonths.push({
            id: ++counter,
            contributionMonth: this.contributionInWords(`${iterator.ContributingPeriod}`.substring(4)),
            contributionYear:  `${iterator.ContributingPeriod}`.substring(0, 4),
            description: `Uncontributed contribution for ${this.contributionInWords(`${iterator.ContributingPeriod}`.substring(4))} ${`${iterator.ContributingPeriod}`.substring(0, 4)}`
          });
        }
      } else {
       this.toastr.error(serverResponse.message);
      }
    }, error => {
      this.spinner.hide();
    });
  }
  contributionInWords(month) {
    if (month === '01') {
      return 'January';
    } else if (month === '02') {
      return 'February';
    } else if (month === '03') {
      return 'March';
    } else if (month === '04') {
      return 'April';
    } else if (month === '05') {
      return 'May';
    } else if (month === '06') {
      return 'June';
    } else if (month === '07') {
      return 'July';
    } else if (month === '08') {
      return 'August';
    } else if (month === '09') {
      return 'September';
    } else if (month === '10') {
      return 'October';
    } else if (month === '11') {
      return 'November';
    } else if (month === '12') {
      return 'December';
    }
}

}
