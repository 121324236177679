import { Component, OnInit } from '@angular/core';
import {  DxContextMenuModule, DxMenuModule } from 'devextreme-angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';

import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-phone-types',
  templateUrl: './phone-types.component.html',
  styleUrls: ['./phone-types.component.scss']
})
export class PhoneTypesComponent extends SharedClassComponent implements OnInit {


  title:string= 'Phone Types';
  model_name:string='phone/types';
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  paramsDataSets:any;
  param_id:any;
  response:any;

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);

    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      name: new FormControl('', Validators.compose([Validators.required])),
      phoneTypeId: new FormControl('', Validators.compose([]))
    });
    this.onGetParamsdetails();

    // Controls the datagrid height and max rows to display
    this.observerCall();

    this.setDataGridRefreshTimeInterval();

  }
}
