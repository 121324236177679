import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-bill-payments',
  templateUrl: './bill-payments.component.html',
  styleUrls: ['./bill-payments.component.scss']
})
export class BillPaymentsComponent extends SharedClassComponent implements OnInit {

  invoiceId = '';
  qrCodeString = '';
  hideNoContributionMadeForInvoiceBanner = true;



  hideInvoiceSheet = true;
  hideQrCode = true;

  // invoice items
  controlNumber: any;
  customerName: any;
  customerNumber: any;
  billDescription = '';
  totalAmountPaid: any;
  payableBanks = '';
  invoiceNumber: any;
  totalAmount: any;
  paymentType: any;
  invoiceDescription: any;
  invoiceCurrency: any;
  amountInWords = '';
  billExpireDate = '';
  invoiceBillItems = [];
  now: any;
  currentYear = new Date().getFullYear();
  openInvoiceEditPopUp = false;
  contributionBatchData: any;
  isGeneratedContributionBatch = false;
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.invoiceId = params.invoiceId;
    });

    if (this.invoiceId === null || this.invoiceId === undefined) {
      this.hideNoContributionMadeForInvoiceBanner = false;
      return;
    }
    this.hideInvoiceSheet = false;
    const data = {
      requestType: 'CUSTOMER_BILL_GENERATE',
      customerInvoiceId: this.invoiceId
    };
    this.spinner.show();
    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      this.spinner.hide();
      const serverResponse = res.json();
      if (serverResponse.code == 2000) {
        this.toastr.success(serverResponse.message);
        this.toastr.toastrConfig.closeButton = true;
        this.now = new Date();
        // tslint:disable-next-line: max-line-length
        this.qrCodeString = `invoiceNumber:${serverResponse.data.INVOICE_NO}controlNumber:${serverResponse.data.CONTROL_NUMBER};totalAmount:${serverResponse.data.AMOUNT};currency:${serverResponse.data.CURRENCY};`;
        this.controlNumber = serverResponse.data.CONTROL_NUMBER;
        this.billDescription = serverResponse.data.DESCRIPTION;
        // this.payableBanks = serverResponse.data.banks;
        this.payableBanks = 'CRDB, PBZ, NMB, NBC';
        this.invoiceBillItems = serverResponse.data.BILL_ITEMS;
        this.totalAmount = serverResponse.data.AMOUNT;
        this.paymentType = serverResponse.data.PAYMENT_OPTION;
        this.invoiceCurrency = serverResponse.data.CURRENCY;
        this.billExpireDate = serverResponse.data.EXPIRE_DATE;
        this.invoiceNumber = serverResponse.data.INVOICE_NO;
        this.customerName = serverResponse.data.PAYER_NAME;
        this.customerNumber = this.authService.getUserDetails().linkId;
        this.hideQrCode = false;
      } else {
        this.toastr.error(serverResponse.message);
        this.toastr.toastrConfig.closeButton = true;
        this.logServerErrors(this.router.url, serverResponse.message, 'CUSTOMER_BILL_GENERATE');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error);
      this.logServerErrors(this.router.url, error, 'CUSTOMER_BILL_GENERATE');
      this.toastr.toastrConfig.closeButton = true;
    });
  }

  printInvoiceReceipt() {
    // this.qrCodeString = `controlNumber:${this.controlNumber};
    // invoiceNumber:225CE497-F18D-4ECE-A693-E8DD20E6990D;
    // totalAmount:${this.totalAmountContributed};
    // currency:${this.invoiceCurrency};expireDate:${this.billExpireDate};`;
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
           <style>
           body{
            font-size: 14px;
            line-height: 1.42857143;
            color: #333;
          }
          .space{
            display: flex;
            justify-content: space-between;
            border: 1px dotted #4d88bb;
            padding: 1rem 0;
          }
          .logo__block{
          /* border-bottom: 1px dotted #4d88bb; */
          border-bottom: 1px dotted rgb(0 172 238);
          }
          .logo__block p, .footer, p {
            /* color: #4d88bb; */
            color: rgb(0 172 238);
          }
          .invoice__head__child_contacts{
            margin: 19px;
          }
          .invoice{
           background-color: rgba(0, 0, 0, 0.055);
            padding: 2rem;
            width: fit-content;
            height: 100vh;
          }
          .invoice__head,
          .invoice__how_to_pay{
            display: flex;
            justify-content: space-between;
          }
          .invoice__body{
            display: flex;
            /* justify-content: center; */
          }
          .invoice__body table tr th{
            background: rgb(0 172 238);
            color:white;
            text-align:left;
            vertical-align:center;
          }
          .invoice__body table tr {
            background-color: rgba(136 ,136 ,136, .1);
            border: 1px solid #ddd;
            padding: .35em;
          }
          .logo__block, .footer{
              text-align: center;
          }
          .full_table{
            width: 100%;
          }

          /* .watermark {
          position:fixed;
          font-size: 88px;
          bottom: 0;
          opacity:0.5;
          z-index:99;
          color: #35313126;
          text-align: center;
          } */
          table tbody tr td{
          padding: .5rem;
          }

          @media print {

          @page {
            size: A4;
            margin: 0mm;
          }

          html, body {
            width: 1024px;
          }

          body {
            margin: 0 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            /* font: 14px "Times New Roman", Times, serif; */
            font-size: 17px;
            /* background:rgba(0, 0, 0, 0.055); */
            color:black;
            width: 100%;
            float: none;
          }
          .invoice__body table tr th{
            padding: 5px;
          }
          .footer{
            position: absolute;
            bottom: 10px;
            width: 100%;
            text-align: center;
          }
          .invoice{
            background-color: transparent;
          }

          /* avoid page-breaks inside a listingContainer*/
          .listingContainer{
            page-break-inside: avoid;
          }
          h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }

            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }

           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
  }

}
