import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-associated-members-contributions-per-invoice',
  templateUrl: './associated-members-contributions-per-invoice.component.html',
  styleUrls: ['./associated-members-contributions-per-invoice.component.scss']
})
export class AssociatedMembersContributionsPerInvoiceComponent extends SharedClassComponent  implements OnInit {

  title = 'Members Contributed';
  membersDataSource = [];
  invoiceId = '';
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.invoiceId = params.invoiceId;
    });
  }

}
