import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent extends SharedClassComponent implements OnInit {
  now: Date = new Date();
  maxLength = 500;
  feedbackForm: FormGroup;
  loadingVisible = false;
  showSuccessDialog = false;
  successMessage = '';
  customerName = '';
  feedbackNumber: any;
  feedbackTypeDatasource = [
    {
      id: 200,
      text: 'Contributions'
    },
    {
      id: 201,
      text: 'Payments'
    },
    {
      id: 202,
      text: 'Investments'
    },
    {
      id: 203,
      text: 'Registration'
    },
    {
      id: 204,
      text: 'Improvements'
    },
    {
      id: 205,
      text: 'Suggestions'
    },
    {
      id: 206,
      text: 'System Bugs'
    },
    {
      id: 207,
      text: 'Others'
    }
 ];

  ngOnInit() {
    this.appInfo.setTitle('User Feedback');
    this.feedbackForm = new FormGroup({
      customerNumber: new FormControl(null, Validators.compose([Validators.required])),
      customerName: new FormControl(null, Validators.compose([Validators.required])),
      customerEmail: new FormControl(null, Validators.compose([])),
      customerPhone: new FormControl(null, Validators.compose([])),
      feedbackType: new FormControl(null, Validators.compose([Validators.required])),
      message: new FormControl(null, Validators.compose([Validators.required])),
      date: new FormControl(this.now, Validators.compose([]))
    });
    this.customerName = this.authService.getUserDetails().firstName;
    this.feedbackForm.get('customerNumber').patchValue(this.authService.getUserDetails().linkId);
    this.feedbackForm.get('customerName').patchValue(this.authService.getUserDetails().firstName);
    this.feedbackForm.get('customerEmail').patchValue(this.authService.getUserDetails().email);
    this.feedbackForm.get('customerPhone').patchValue(this.authService.getUserDetails().mobileNo);
  }

  onSubmitUserFeedBack() {
    if (this.feedbackForm.invalid) {
      this.toastr.error('Fill all the required fileds', 'Empty field detected');
      return;
    }
    const data = {
      customerNumber: this.feedbackForm.get('customerNumber').value,
      category: this.feedbackForm.get('feedbackType').value,
      customerEmail: this.feedbackForm.get('customerEmail').value,
      feedbackSource: 3000,
      feedbackMessage: this.feedbackForm.get('message').value
    };
    this.loadingVisible = true;
    this.utilities.helperApiCall(data, 'users/feedback/submit').subscribe(res => {
      this.loadingVisible = false;
      if (res.statusCode === 2000) {
        this.showSuccessDialog = true;
        this.feedbackNumber = res.data.feedbackNumber;
        this.feedbackForm.get('feedbackType').reset();
        this.feedbackForm.get('message').reset();
      } else {
        this.toastr.error(res.statusMessage);
      }
    }, error => {
      this.loadingVisible = false;
      this.toastr.error('Something went wrong, please try again');
    });
  }

  hideDialog() {
    this.showSuccessDialog = false;
  }

}
