import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-reply-section',
  templateUrl: './reply-section.component.html',
  styleUrls: ['./reply-section.component.scss']
})
export class ReplySectionComponent extends SharedClassComponent implements OnInit {
  title = 'Feedback Reply Section';
  isMultiline = true;
  flag = 0;
  feedbackData: any;
  feedbackReply = '';
  isNull = false;

  dateSubmitted: any;
  feedbackMessage: any;
  feedbackNumber: any;
  repliedBy: any;
  repliedOn: any;
  replyMessage: any;
  status: any;

  ngOnInit() {
    this.feedbackData = this.securityService.decryptString(sessionStorage.getItem(AppSettings.feedbackKey));
    if (this.feedbackData === null || this.feedbackData === undefined) {
      this.isNull = true;
      this.router.navigate(['/feedback/replies']);
      return;
    }
    this.dateSubmitted = this.feedbackData.dateSubmitted;
    this.feedbackMessage = this.feedbackData.feedbackMessage;
    this.feedbackNumber = this.feedbackData.feedbackNumber;
    this.repliedBy = this.feedbackData.repliedBy;
    this.repliedOn = this.feedbackData.repliedOn;
    this.replyMessage = this.feedbackData.repliedMessage;
  }

  back() {
    sessionStorage.removeItem(AppSettings.feedbackKey);
    this.router.navigate(['/feedback/replies']);
  }

}
