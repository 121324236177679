import { Injectable } from "@angular/core";
// import { Observable } from 'rxjs/Observable';
import * as CryptoJS from "crypto-js";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogueComponent } from "src/app/utilities/confirmation-dialogue/confirmation-dialogue.component";
import { AppSettings } from "src/app/app-settings";
import { Http, Response, Headers } from "@angular/http";
import { map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

export class Settings {
  id: number;
  name: string;
  value: string;
}

@Injectable()
export class UtilitiesService {
  config: any;
  userId: number; // private authService:AuthService
  token: any;
  sessionData: any;
  user: any;
  billControlNumber: number;
  billDetails: any;
  constructor(
    private modalService: NgbModal,
    private http: Http,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    if (this.authService.getUserDetails()) {
      (this.user = this.authService.getUserDetails()),
        (this.sessionData = this.authService.getActiveSession());

      this.userId = this.user.id;
      const activeSession = this.authService.getActiveSession();
      this.token = activeSession.token;
    } else {
    }
  }
  getBillControlNumber() {
    return this.billControlNumber;
  }

  md5hash(data: string): string {
    return CryptoJS.MD5(data).toString();
  }

  setBillControlNumber(controlNumber) {
    this.billControlNumber = controlNumber;
  }
  getBillControlDetails() {
    return this.billDetails;
  }
  setBillControlDetails(billDetails) {
    this.billDetails = billDetails;
  }

  postServiceCall(data: any, path) {
    const postData = {
      header: {
        userId: this.userId,
        token: this.token,
        channelCode: AppSettings.channelCode,
        channelSecurityCode: AppSettings.channelSecurityCode,
      },
      data,
    };
    const postDataClone = {
      requestUrl: `${AppSettings.apiName}` + path,
      requestBody: postData,
    };

    const dataHash = this.md5hash(
      JSON.stringify(postDataClone) + AppSettings.XPR_TOS_PST_TEST
    );
    const headers = new Headers({
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "x-auth, content-type",
      "Access-Control-Allow-Methods": "POST",
      "X-TPS-OCT-PLAC-7": dataHash,
    });

    return this.http
      .post(AppSettings.phpInterceptor, JSON.stringify(postDataClone), {
        headers,
      })
      .pipe(
        map((resp) => {
          return resp;
        })
      );

    // for non secure
    // return this.http.post(AppSettings.phpInterceptor+path,
    //   postData, {headers}
    //   )
    //    .pipe(map(resp => {
    //       return resp;
    //     }));
  }

  postServiceCallNew(data: any) {
    const postData = {
      header: {
        userId: this.userId,
        token: this.token,
        channelCode: AppSettings.channelCode,
        channelSecurityCode: AppSettings.channelSecurityCode,
      },
      data,
    };
    const postDataClone = {
      requestUrl: `${AppSettings.apiName}` + "portal/request",
      requestBody: postData,
    };

    const dataHash = this.md5hash(
      JSON.stringify(postDataClone) + AppSettings.XPR_TOS_PST_TEST
    );
    const headers = new Headers({
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "x-auth, content-type",
      "Access-Control-Allow-Methods": "POST",
      "X-TPS-OCT-PLAC-7": dataHash,
    });

    return this.http
      .post(
        AppSettings.phpInterceptorCompesation,
        JSON.stringify(postDataClone),
        {
          headers,
        }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );

    // for non secure
    // return this.http.post(AppSettings.phpInterceptor+path,
    //   postData, {headers}
    //   )
    //    .pipe(map(resp => {
    //       return resp;
    //     }));
  }

  // getServiceCall(data: any, path) {
  //       const headers = new HttpHeaders({
  //          Accept: 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Headers': 'x-auth, content-type',
  //         'Access-Control-Allow-Methods': 'POST'
  //       });
  //       data.channel_id = AppSettings.channelId;
  //       data.channel_security_code = AppSettings.channelSecurityCode;
  //       this.config = {
  //         params: data,
  //         headers
  //       };
  //       return this.httpClient.get(AppSettings.baseURL + path, this.config)
  //         .pipe(map(res => {
  //            return res;
  //         }));
  // }
  printService(printEl: HTMLElement) {
    let printContainer: HTMLElement =
      document.querySelector("#print-container");

    if (!printContainer) {
      alert("missing");
      printContainer = document.createElement("div");
      printContainer.id = "print-container";
    }

    printContainer.innerHTML = "";

    const elementCopy = printEl.cloneNode(true);
    printContainer.appendChild(elementCopy);
    document.body.appendChild(printContainer);

    window.print();
  }

  generalRequest(data) {
    const payload = {
      header: {
        userId: this.userId,
        token: this.token,
        channelCode: AppSettings.channelCode,
        channelSecurityCode: AppSettings.channelSecurityCode,
      },
      data,
    };

    const postDataClone = {
      requestUrl: `${AppSettings.apiName}` + `portal/request`,
      requestBody: payload,
    };

    const dataHash = this.md5hash(
      JSON.stringify(postDataClone) + AppSettings.XPR_TOS_PST_TEST
    );
    const headers = new HttpHeaders({
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "x-auth, content-type",
      "Access-Control-Allow-Methods": "POST",
      "X-TPS-OCT-PLAC-7": dataHash,
    });
    // tslint:disable-next-line: max-line-length
    return this.httpClient
      .post(AppSettings.phpInterceptor, JSON.stringify(postDataClone), {
        headers,
      })
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  helperApiCall(data, path) {
    const postDataClone = {
      requestUrl: AppSettings.feedbackUri + path,
      requestBody: data,
    };
    const dataHash = this.md5hash(
      JSON.stringify(postDataClone) + AppSettings.XPR_TOS_PST_TEST
    );
    const headers = new HttpHeaders({
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "x-auth, content-type",
      "Access-Control-Allow-Methods": "POST",
      "X-TPS-OCT-PLAC-7": dataHash,
    });
    return this.httpClient
      .post(AppSettings.phpInterceptor, JSON.stringify(postDataClone), {
        headers,
      })
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  errorLogApiCall(data) {
    const headers = new HttpHeaders({
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "x-auth, content-type",
      "Access-Control-Allow-Methods": "POST",
    });
    return this.httpClient
      .post(AppSettings.errorLogInterceptor, JSON.stringify(data), { headers })
      .pipe(map((res) => res));
  }
}
