

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";


@Component({
  selector: 'app-longterm-employer-endorsement-list',
  templateUrl: './longterm-employer-endorsement-list.component.html',
  styleUrls: ['./longterm-employer-endorsement-list.component.scss']
})
export class LongtermEmployerEndorsementListComponent
  extends SharedClassComponent
  implements OnInit
{
  employerList: any = [];
  filterEmployerForm: FormGroup;
  sectorList: any = [];
  districtList: any = [];
  regionList: any = [];
  natureBusinessList: any = [];
  districtListNew: any = [];
  title = "LONG TERM EMPLOYER'S ENDORSEMENT";
  titleList = "Long Term loyer's endorsement list";
  titleCreate = "Create Notification";
  showEmployerSearchValue: boolean;
  searchFormEmployer: FormGroup;
  endorsementForm: FormGroup;
  isEmployerNoObtainedFromSearch: boolean;
  showCreateEndorsementPopUp: boolean = false;
  isFromCreateEmployer: boolean = false;
  showLoadmemberDetails = false;


  mothersNameSearch: boolean = false;
  showMemberSearch: boolean;
  isRowRemoved: boolean = false;
  showRemarksPopUp: boolean = false;

  remarkPostForm: FormGroup;
  originalDatasourceModified: any = [];
  memberDetailsDatasource: any;
  memberButton: any;
  currentUserEmployerNo: any;
  membersList: any[];
  isObtainedFromPopulate: boolean;
  showLoadEmployerDetails: boolean;
  showLoadMembersEmployerDetails: boolean;
  isAllFieldValidated: boolean;

  ngOnInit() {

    this.memberButton = {
      icon: "search",
      type: "default",
      onClick: () => {
        this.showMemberSearch = false;
        this.isObtainedFromPopulate = false;
        this.showLoadMembersEmployerDetails = true;
        this.getMembers();
      },
    };
    this.endorsementForm = new FormGroup({
    

      firstName: new FormControl("", Validators.compose([Validators.required])),
      middleName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      lastName: new FormControl("", Validators.compose([Validators.required])),
      memberNo: new FormControl("", Validators.compose([Validators.required])),
    });


    const userDetails = this.authService.getUserDetails();

    if (userDetails.linkId && userDetails.linkId != "") {
      this.currentUserEmployerNo=userDetails.linkId;
      this.filterEmployers(userDetails.linkId);
    }
  }

  getMembers() {
    if (!this.currentUserEmployerNo) {
      this.toastr.error("Employer No not Found", "Error");
      return;
    }
    const data = {
      requestType: "COMPENSATION_CLAIM_SEARCH_MEMBERS",
      employerNumber: this.currentUserEmployerNo,
    };
    this.spinner.show();
    this.membersList = [];
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.membersList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getFullMemberDetails() {
    if (
      !this.endorsementForm.get("memberNo").value ||
      this.isObtainedFromPopulate
    ) {
      this.isObtainedFromPopulate = false;
      return;
    }

    const data = {
      requestType: "MEMBER_FILTER",
      keyText: this.endorsementForm.get("memberNo").value,
    };
    this.spinner.show();
    this.membersList = [];
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          if (
            response.data &&
            Array.isArray(response.data) &&
            response.data.length != 0
          ) {
            this.isAllFieldValidated = true;
            const data = response.data[0];
            this.endorsementForm.get("memberNo").patchValue(data.memNo);
            this.endorsementForm.get("firstName").patchValue(data.FirstName);
            this.endorsementForm.get("middleName").patchValue(data.MiddleName);
            this.endorsementForm.get("lastName").patchValue(data.SurName);
          } else {
            this.isAllFieldValidated = false;
            this.endorsementForm.get("firstName").patchValue("");
            this.endorsementForm.get("middleName").patchValue("");
            this.endorsementForm.get("lastName").patchValue("");
          }
        } else {
          this.isAllFieldValidated = false;
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  previewSelectedEmployerDetails(e) {
    const data = e.data;

    this.isObtainedFromPopulate = true;
    this.isAllFieldValidated = true;
    this.endorsementForm.get("memberNo").patchValue(data.MemberNumber);
    this.endorsementForm.get("firstName").patchValue(data.FirstName);
    this.endorsementForm.get("middleName").patchValue(data.MiddleName);
    this.endorsementForm.get("lastName").patchValue(data.SurName);

    this.showLoadEmployerDetails = false;
    this.showLoadMembersEmployerDetails = false;
  }


  filterEmployers(empNo) {
    const data = {
      requestType: "BENEFIT_EMPLOYER_NOTIFICATION_ONLINE_LIST",
      employerNo: empNo
    };
    this.employerList = [];
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        this.spinner.hide();
        if (response.code == 2000) {
          this.toastr.success(response.message);
          this.employerList = response.data;
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error("Something went wrong please try again.");
        this.spinner.hide();
      }
    );
  }

  submitEndorsement() {
    if (this.endorsementForm.invalid) {
      this.toastr.error("Please Fill all of the fields", "Error");
      return;
    }

    this.finalSubmit();
  }

  finalSubmit() {
    this.data = {
      requestType: "BENEFIT_EMPLOYER_NOTIFICATION_ONLINE",
      employerNo: this.currentUserEmployerNo,
      
      memberFirstName: `${this.endorsementForm.get("firstName").value}`
      .trim()
      .toUpperCase(),
      memberMiddleName: `${this.endorsementForm.get("middleName").value}`
      .trim()
      .toUpperCase(),
      memberLastName: `${this.endorsementForm.get("lastName").value}`
      .trim()
      .toUpperCase(),
      memberNo: this.endorsementForm.get("memberNo").value,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(this.data).subscribe(
      (res) => {
        this.spinner.hide();
        const response = res.json();
        if (response.code == 2000) {
          this.toastr.success(response.message, "Success");
          this.showCreateEndorsementPopUp = false;
          this.filterEmployers(this.currentUserEmployerNo);
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  printReport() {
    let popUpWindow;

    const innerContents = document.getElementById("report-container").innerHTML;
    popUpWindow = window.open(
      "",
      "_blank",
      "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
    );
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
             <head>
             <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
             <style>
             body{
              font-size: 14px;
              line-height: 1.42857143;
              color: #333;
            }
            .space{
              display: flex;
              justify-content: space-between;
              border: 1px dotted #4d88bb;
              padding: 1rem 0;
            }
            .logo__block{
            /* border-bottom: 1px dotted #4d88bb; */
            
            }
            .logo__block p, .footer, p {
              /* color: #4d88bb; */
            }
            .invoice__head__child_contacts{
              margin: 19px;
            }

         
            .invoice_wrapper {
              position: absolute;
            }
            .invoice_wrapper, .invoice{
              /*background-color: rgba(0, 0, 0, 0.055);*/
              padding: 2rem;
              width: fit-content;
              height: 100vh;
            }
            .invoice__head,
            .invoice__how_to_pay{
              display: flex;
              justify-content: space-between;
            }
            .invoice__body{
              display: flex;
              /* justify-content: center; */
            }
            .invoice__body table tr th{
              background: #035817;
              color:white;
              text-align:left;
              vertical-align:center;
            }
            .invoice__body table tr {
              background-color: rgba(136 ,136 ,136, .1);
              border: 1px solid #ddd;
              padding: .35em;
            }
            .logo__block, .footer{
                text-align: center;
            }
            .full_table{
              width: 100%;
            }
            .divide {
              position: relative;
              min-height: 80%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            /* .watermark {
            position:fixed;
            font-size: 88px;
            bottom: 0;
            opacity:0.5;
            z-index:99;
            color: #35313126;
            text-align: center;
            } */
            table tbody tr td{
            padding: .5rem;
            }
            td {
              padding: 5px !important; /* Set the height of the table cells */
            }
            
          
            table {
              border-collapse: collapse;
              width: 100%;
              table-layout: auto;
          }
         
          
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
            white-space: nowrap; /* Prevent line breaks within cells */
            overflow: hidden; /* Hide content that overflows the cell */
            text-overflow: ellipsis; /* Show an ellipsis (...) when content overflows */
            max-width: 0; /* Allow content to overflow */
    
             
          }
          
          th {
              background-color: #f2f2f2;
          }
          tfoot {
            display: table-footer-group;
          }
  
            @media print {
              thead { display: table-header-group; }
            @page {
              size: A4;
              margin: 0mm;
            }
  
            html, body {
              width: 1024px;
            }
            th:first-child,
            td:first-child {
              width: 10%;
              white-space: nowrap;
            }

            .long-name-cell {
              min-width: 25px; /* Set a minimum width to accommodate longer names */
            }
  
            body {
              margin: 0 auto;
              line-height: 1em;
              word-spacing:2px;
              letter-spacing:0.2px;
              /* font: 14px "Times New Roman", Times, serif; */
              font-size: 17px;
              background:rgba(0, 0, 0, 0.1);
padding-top: 20px;
              color:black;
              width: 100%;
              padding:5px;
              float: none;
            }
            .invoice__body table tr th{
              padding: 5px;
            }
            .footer{
              position: absolute;
              bottom: 10px;
              width: 100%;
              text-align: center;
            }
            /* avoid page-breaks inside a listingContainer*/
            .listingContainer{
              page-break-inside: avoid;
            }
            h1 {
                font: 28px "Times New Roman", Times, serif;
              }
  
              h2 {
                font: 24px "Times New Roman", Times, serif;
              }
  
              h3 {
                font: 20px "Times New Roman", Times, serif;
              }
  
              /* Improve colour contrast of links */
              a:link, a:visited {
                color: #781351
              }
  
              /* URL */
              a:link, a:visited {
                background: transparent;
                color:#333;
                text-decoration:none;
              }
  
              a[href]:after {
                content: "" !important;
              }
              a[href^="http://"] {
                color:#000;
              }
  
              #header {
                height:75px;
                font-size: 24pt;
                color:black
              }
            }
  
             @media print {
              .page-footer-space {
              height: 23px;
             }
.printed-by-info {
                position: fixed;
                bottom: 0;
                width: 98%;
                background-color: white;
                border-top: 1px solid black;
                 
                 
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            
              .printed-by-info b {
                margin: 0;
              }
            }
  
             </style>
             </head>
             <body onload="window.print()">
             ${innerContents}
             <div class="printed-by-info">
              
         
           </div>
           </body>
         </html>`);
    popUpWindow.document.close();
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "Print",
          type: "default",
          icon: "fa fa-print",
          onClick: this.printReport.bind(this),
        },
      },

      {
        location: "before",
        widget: "dxButton",
        options: {
          text: "Create notification",
          icon: "fa fa-plus",
          type: "default",

          onClick: this.showCreateNotificationPopUp.bind(this),
        },
      }
    );
  }

  showCreateNotificationPopUp() {
    this.showCreateEndorsementPopUp = true;
    this.isObtainedFromPopulate=false;
    this.endorsementForm.reset()
    this.isAllFieldValidated=false;
  }


  getTotalSum(arrayData: any[], actualItem: string): number {
    if (!arrayData || !actualItem) return 0;
    return arrayData.reduce((total, item) => total + item[actualItem], 0);
  }
}
