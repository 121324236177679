import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent extends SharedClassComponent implements OnInit {
  title:string= 'Registration Security Questions';
  model_name:string='questions';

  ngOnInit() {
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      question: new FormControl('', Validators.compose([Validators.required])),
      createdBy: new FormControl('', Validators.compose([])),
      createdAt: new FormControl('', Validators.compose([])),
      modifiedBy: new FormControl('', Validators.compose([])),
      modifiedAt: new FormControl('', Validators.compose([])),
      questionId: new FormControl('', Validators.compose([]))
    });
    this.onGetParamsdetails();
  // Controls the datagrid height and max rows to display
    this.observerCall();
    this.setDataGridRefreshTimeInterval();
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
  }

}
