import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class InactivityService {
  private inactivityTimer: any;
  // private inactivityDuration: number = 10 * 1000;
  private inactivityDuration: number = 15 * 60 * 1000; // 5 minutes in milliseconds
  public inactivityAlert$: Subject<void> = new Subject<void>();

  constructor(
    private _authService: AuthService,
    private toast: ToastrService,
    private _router: Router
  ) {
    this.resetTimer();
  }

  resetTimer() {
    // console.log("reset has been called");
    clearTimeout(this.inactivityTimer);
    this.inactivityTimer = setTimeout(() => {
      this.inactivityAlert$.next(); // Emit the inactivity alert event
    }, this.inactivityDuration);
  }

  handleUserActivity() {
    this.resetTimer();
  }

  logout() {
    sessionStorage.clear();

    localStorage.clear();
    this._router.navigate(["login"]).then(() => {
      this.toast.info("You have been logged out due to inactivity");
    });
  }

  cancelLogout() {
    clearTimeout(this.inactivityTimer); // Clear the timeout to cancel logout
  }
}
