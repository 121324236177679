import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-penalty',
  templateUrl: './penalty.component.html',
  styleUrls: ['./penalty.component.scss']
})
export class PenaltyComponent extends SharedClassComponent implements OnInit {

  penaltyInvoiceDataSource = [];
  penaltyDataSource = [];
  title = 'Penalties';
  employerNumber: any;
  penaltyEndpointcall = 'employer/penalties';
  showpenaltyInvoicePopDialog = false;

  penaltyInvoiceActions = [{
    text: 'Actions',
    icon: 'bulletlist',
    type: 'default',
    items: [
      {
      text: 'view',
      icon: 'edit',
      action: 'viewInvoice'
      },
      {
      text: 'test',
      icon: 'edit',
      action: 'viewInvoice'
      }
  ]
}];

  tabPanelData: any = [
    {
    id: 1,
    name: 'Penalty Invoices',
  },
    {
    id: 2,
    name: 'penalties Records',
  }
];

penaltyInvoiceDetails= [];


  ngOnInit() {
    this.observerCall();
    this.loadPenalties();
    this.appInfo.setTitle(this.title);
  }

  loadPenalties(): void {
      const data = {
        employerNumber:  this.authService.getUserDetails().linkId
      };
    this.spinner.show();
    this.utilities.postServiceCall(data, this.penaltyEndpointcall).subscribe(res => {
      const serverResponse = res.json();
      if (serverResponse.code == 2000) {

        this.penaltyInvoiceDataSource = serverResponse.data.penaltyInvoices;
        this.penaltyDataSource = serverResponse.data.penalties;

        for (let index = 0; index < this.penaltyDataSource.length; index++) {

          // if (this.penaltyInvoiceDataSource[index].invoiceNo) {

          // }

          this.penaltyInvoiceDetails.push(
            {
              id: this.penaltyInvoiceDataSource[index].id,
              penaltyInvoiceNumber: this.penaltyInvoiceDataSource[index].invoiceNo,
              controbutionInvoiceNumber: this.penaltyDataSource[index].penaltNumber,
              contributionMonth: this.getMonthName(this.penaltyDataSource[index].penaltyContributionMonth),
              contributionYear: this.penaltyDataSource[index].penaltyContributionYear,
              invoiceDate: this.penaltyInvoiceDataSource[index].invoiceDate,
              totalContributionAmount: this.penaltyDataSource[index].amountRequiredToPay,
              penaltyPercent: this.penaltyDataSource[index].percentAmount,
              totalPenaltyAmount: this.penaltyInvoiceDataSource[index].invoiceAmount,
              currency: this.penaltyInvoiceDataSource[index].currencyCode,
              penaltyAmountPaid: this.penaltyInvoiceDataSource[index].amountPaid,
              penaltyNarration: this.penaltyInvoiceDataSource[index].invoiceDescription,
              paymentDate: this.penaltyInvoiceDataSource[index].datePosted,
              isPosted: this.penaltyInvoiceDataSource[index].isPosted
            }
          );
        }
      } else {
        this.toastr.error(serverResponse.message);
        this.logServerErrors(this.router.url, serverResponse.message, 'LOAD_PENALTIES');
      }
      this.spinner.hide();
    }, error => {
        this.spinner.hide();
        this.logServerErrors(this.router.url, error, 'LOAD_PENALTIES');
        this.toastr.error("Please click the refresh button near the search field.", 'Something went wrong');
    });
  }

  viewInvoice() {
    this.toastr.info('Clicked');
  }

  onParamsActionsClick(e, data) {
    let item = e.itemData;

    if (e.itemData.text === 'view') {
      this.toastr.info('view', 'Clicked');
    }
    if (e.itemData.text === 'test') {
      this.toastr.info('test', 'Clicked');
    }

  }

  onPopUpClose() {
    this.showpenaltyInvoicePopDialog = false;
  }


  toolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
   {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.loadPenalties.bind(this)
        }
      }
    );
  }

  getMonthName(param: number): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[+param-1];
  }

}
