import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Headers } from "@angular/http";
import { SharedClassComponent } from "../configurations/shared-class/shared-class.component";
import { AppSettings } from "../app-settings";
import { map, timeout } from "rxjs/operators";
import notify from "devextreme/ui/notify";

@Component({
  selector: "app-user-self-registration",
  templateUrl: "./user-self-registration.component.html",
  styleUrls: ["./user-self-registration.component.scss"],
})
export class UserSelfRegistrationComponent
  extends SharedClassComponent
  implements OnInit
{
  regType;
  copyRightBanner = "";
  isRegCompleted = false;
  maxDate: Date = new Date();
  investmentCustomerPhoneNumber: any;
  invCustRegData = {
    requestType: "CUSTOMER_CREATE",
    firstName: null,
    middleName: null,
    lastName: null,
    linkId: null,
    username: null,
    password: null,
    designation: null,
    userGroup: null,
    mobileNo: null,
    telephoneNo: null,
    email: null,
  };
  cityPattern = "^[^0-9]+$";
  namePattern: any = /^[^0-9]+$/;
  phonePattern: any = /^(1|)?(\d{3})(\d{3})(\d{6})$/;
  countries: string[];
  phoneRules: any = {
    X: /[02-9]/,
  };
  endPoint = "users/verify";
  registrationEndPoint = "employer/create";
  newUserAccountForm: FormGroup;
  registrationTypeForm: FormGroup;
  invCustRegistrationForm: FormGroup;
  otpVerificationForm: FormGroup;
  customerVerificationForm: FormGroup;

  hideEmpRegForm = true;
  hideInvestmentReg = true;
  hideInvCustVerifyForm = false;
  hideEmployerReg = true;
  hideRegTypeOption = false;
  hideOTPform = true;
  hideCreateAccountForm = true;
  hideCreateUserForm = true;
  hideCreateInvestmentUserForm = true;
  hideInvCustRegForm = true;
  hideEmployerVerifyForm = true;

  empUsername;
  empNumber;
  empSystemId;
  empPassword;
  empPhoneNumer = null;
  empEmail = null;
  customerPhoneNumer = null;
  customerEmail = null;

  showAlertDialog = false;
  alertReason = "";

  btntext = "Resend OTP";
  disableResendOTPbtn = false;

  timeout = 60000;
  resendOTPcounter = 0;
  sendToNumber;

  investmentRegType = [
    {
      userType: "SALES_CUSTOMER",
      displayText: "Sales Customer",
    },
    {
      userType: "RENT_CUSTOMER",
      displayText: "Rent Customer",
    },
    {
      userType: "INVESTMENT_CUSTOMER",
      displayText: "Investment Customer",
    },
  ];
  headerTitle = "ZSSF Employer Portal - Self Registration";

  countryCodes = [
    {
      countryCode: "TZ",
      phonePrefix: "255",
    },
    {
      countryCode: "KEN",
      phonePrefix: "254",
    },
    {
      countryCode: "UGA",
      phonePrefix: "256",
    },
    {
      countryCode: "BDI",
      phonePrefix: "257",
    },
    {
      countryCode: "RWA",
      phonePrefix: "250",
    },
    {
      countryCode: "MWI",
      phonePrefix: "265",
    },
    {
      countryCode: "ZWE",
      phonePrefix: "263",
    },
    {
      countryCode: "ZMB",
      phonePrefix: "260",
    },
  ];
  password = "";
  passwordPattern =
    "(?=^.{6,}$)((?=.*d)|(?=.*W+))(?![.\n])(?=.*[A-Z])(?=.*[0-9]).*$";

  accountForm: FormGroup;

  // individual contributor
  hideINDReg = true;
  hideINDVerifyForm = true;
  indPhoneNumer = null;
  indEmail = null;
  hideINDRegForm = true;
  passwordRegex =
    "(?=[^A-Z]*[A-Z])(?=.*[!@#$%^&*])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{8,}";
  // passwordRegex = '(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[0-9]).*$';
  usernameClone = null;
  hideEmployerEnrollment: boolean = false;

  sectorList: any;
  districtList: any;
  natureBusinessList: any;
  branchList: any;
  districtListNew: any;
  regionList: any;

  ngOnInit() {
    this.regType = [
      {
        id: 1,
        text: "Register As Employer",
      },
      {
        id: 3,
        text: "Register As Individual Contributor",
      },
      {
        id: 2,
        text: "Register As Investment Customer",
      },
      // {
      //   id: 4,
      //   text: "Employer Enrollemnt",
      // },
    ];
    this.invCustRegistrationForm = new FormGroup({
      firstName: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.min(2)])
      ),
      middleName: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.min(2)])
      ),
      lastName: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.min(2)])
      ),
      designation: new FormControl("", Validators.compose([])),
      fullName: new FormControl("", Validators.compose([])),
      email: new FormControl("", Validators.compose([])),
      mobileNo: new FormControl("", Validators.compose([])),
      telephoneNo: new FormControl("", Validators.compose([])),
      phoneCode: new FormControl(
        this.countryCodes[0].phonePrefix,
        Validators.compose([])
      ),
      username: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.passwordRegex),
        ])
      ),
      confirmPassword: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
    this.customerVerificationForm = new FormGroup({
      customerID: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      invRegType: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
    this.copyRightBanner = this.appInfo.getCopyRightText();
    this.maxDate = new Date(
      this.maxDate.setFullYear(this.maxDate.getFullYear() - 21)
    );
    this.countries = this.appInfo.getCountries();

    this.accountForm = new FormGroup({
      username: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.passwordRegex),
        ])
      ),
      confirmPassword: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });

    this.newUserAccountForm = new FormGroup({
      employerNumber: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      organizationName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      firstName: new FormControl("", Validators.compose([Validators.required])),
      middleName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      lastName: new FormControl("", Validators.compose([Validators.required])),
      position: new FormControl("", Validators.compose([Validators.required])),
      email: new FormControl("", Validators.compose([])),
      mobileNumber: new FormControl("", Validators.compose([])),
      phoneCode: new FormControl(
        this.countryCodes[0].phonePrefix,
        Validators.compose([])
      ),
      username: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.passwordRegex),
        ])
      ),
      confirmPassword: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });

    this.registrationTypeForm = new FormGroup({
      registerAs: new FormControl(1, Validators.compose([Validators.required])),
    });

    this.otpVerificationForm = new FormGroup({
      otpCode: new FormControl(null, Validators.compose([Validators.required])),
    });

   
    

    this.getDistricts();
    this.getSectors();
    this.businessNature();
    this.branch();
    this.getRegion();



  }

  usernameValueChanged(data) {
    try {
      this.usernameClone = `${data.value}`.trim();
      this.usernameClone = this.usernameClone.replace(/\s/g, "");
      this.invCustRegistrationForm
        .get("username")
        .patchValue(this.usernameClone);
      this.newUserAccountForm.get("username").patchValue(this.usernameClone);
    } catch (error) {
      console.log(error);
    }
  }

  passwordComparison = () => {
    return this.password;
  };

  onBackPressed() {
    this.hideRegTypeOption = false;
    this.hideEmployerEnrollment = false;
    this.hideINDReg = true;
    this.hideINDRegForm = true;
    this.hideEmpRegForm = true;
    this.hideInvestmentReg = true;
    this.hideInvCustVerifyForm = true;
    this.hideEmployerVerifyForm = true;
    this.invCustRegistrationForm.reset();
    this.customerVerificationForm.reset();
    this.newUserAccountForm.reset();
    this.otpVerificationForm.reset();
 
  }

  resendOTP(regType, userType) {
    this.otpVerificationForm.reset();
    // this.smsService.sendOTP(`${this.sendToNumber}`);
    const linkId = this.customerVerificationForm.get("customerID").value;
    if (regType === 3) {
      userType = this.customerVerificationForm.get("invRegType").value;
    }
    this.requestOTP(linkId, regType, userType);
    this.disableResendOTPbtn = true;
    this.resendOTPcounter += 1;
    if (this.resendOTPcounter > 3) {
      this.disableResendOTPbtn = true;
      // tslint:disable-next-line: max-line-length
      notify(
        `You have resend OPT code ${this.resendOTPcounter} times, system can not send more OTP code to your number`,
        "warning",
        this.timeout
      );
    }
    setTimeout(() => {
      this.disableResendOTPbtn = false;
      this.timeout += 6000000;
    }, this.timeout);
    notify(
      `You will be able to resend OTP after ${this.timeout / 1000} seconds`,
      "info",
      this.timeout
    );
  }

  registrationTypeSubmit() {
    if (this.registrationTypeForm.invalid) {
      this.toastr.error("Please choose registration type to contrinue!");
      return;
    }

    this.hideEmployerEnrollment = false;
    // register as employer
    if (this.registrationTypeForm.get("registerAs").value === 1) {
      this.hideInvCustVerifyForm = true;
      this.hideINDVerifyForm = true;

      this.hideRegTypeOption = true;
      this.hideEmpRegForm = true;
      this.hideEmployerVerifyForm = false;
      this.hideEmployerReg = false;
      this.headerTitle = "ZSSF Employer Portal - Self Registration";
    }
    // register as investment customer
    if (this.registrationTypeForm.get("registerAs").value === 2) {
      this.hideEmployerVerifyForm = true;
      this.hideINDVerifyForm = true;

      this.hideRegTypeOption = true;
      this.hideInvestmentReg = false;
      this.hideInvCustVerifyForm = false;
      this.headerTitle = "ZSSF Customers Portal - Self Registration";
    }

    // register as individual contributor
    if (this.registrationTypeForm.get("registerAs").value === 3) {
      this.hideEmployerVerifyForm = true;
      this.hideInvCustVerifyForm = true;

      this.hideRegTypeOption = true;
      this.hideINDReg = false;
      this.hideINDVerifyForm = false;
      this.headerTitle = "ZSSF Customers Portal - Self Registration";
    }

    if (this.registrationTypeForm.get("registerAs").value === 4) {
      // this.router.navigate(["employer-create"])
      this.hideEmployerEnrollment = true;
    }
  }

  requestOTP(linkId, regType, userType) {
    const data = {
      requestType: "OTP_REQUEST",
      userType,
      linkId,
    };
    const endPoint = "portal/request";
    this.spinner.show();
    this.apiCall(data, endPoint).subscribe(
      (res) => {
        const serverResponse = res.json();
        if (serverResponse.code === 2000) {
          if (regType === 1) {
            this.hideEmployerVerifyForm = true;
            this.hideOTPform = false;
          } else if (regType === 2) {
            this.hideInvCustVerifyForm = true;
            this.hideOTPform = false;
          } else if (regType === 3) {
            this.hideINDVerifyForm = true;
            this.hideOTPform = false;
          }
        } else {
          this.toastr.error(serverResponse.message);
          this.logServerErrors(
            this.router.url,
            serverResponse.message,
            "OTP_REQUEST"
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.logServerErrors(this.router.url, error, "OTP_REQUEST");
        this.toastr.error("Something went wrong, please try again!!!");
      }
    );
  }

  validateOTP(regType) {
    if (this.otpVerificationForm.invalid) {
      this.toastr.error("Please Enter OTP code to contrinue");
      return;
    }
    const linkId = this.customerVerificationForm.get("customerID").value;
    // const regData = this.securityService.decryptString(sessionStorage.getItem(AppSettings.regDetailsKey));
    const data = {
      requestType: "OTP_VERIFY",
      linkId,
      otp: this.otpVerificationForm.get("otpCode").value,
    };
    this.spinner.show();
    const endPoint = "portal/request";
    this.apiCall(data, endPoint).subscribe(
      (res) => {
        const serverResponse = res.json();
        if (serverResponse.code === 2000) {
          this.hideOTPform = true;
          this.isRegCompleted = false;
          this.hideRegTypeOption = true;
          if (regType === 1) {
            // this.hideCreateUserForm = false;
            // this.hideEmpRegForm = true;
            this.hideEmpRegForm = false;
          } else if (regType === 2) {
            this.hideInvCustRegForm = false;
            // this.hideCreateInvestmentUserForm = true;
          } else if (regType === 3) {
            this.hideINDRegForm = false;
          }
        } else {
          this.toastr.error(serverResponse.message);
          this.logServerErrors(
            this.router.url,
            serverResponse.message,
            "OTP_VERIFY"
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.logServerErrors(this.router.url, error, "OTP_VERIFY");
        this.toastr.error("Something went wrong, please try again!!!");
      }
    );
  }
  hideDialog() {
    this.showAlertDialog = false;
  }

  onIndividualNumberValidation() {
    if (this.customerVerificationForm.get("customerID").invalid) {
      this.toastr.error(
        "Please enter your registration number for verification"
      );
      return;
    }
    const data = {
      requestType: "CUSTOMER_VERIFY",
      linkId: this.customerVerificationForm.get("customerID").value,
      userType: "INDIVIDUAL_CONTRIBUTOR",
    };
    this.spinner.show();
    this.apiCall(data, "portal/request").subscribe(
      (res) => {
        const serverResponse = res.json();
        this.spinner.hide();
        if (serverResponse.code == 2000) {
          if (
            (serverResponse.data.MobilePhone === null ||
              serverResponse.data.MobilePhone === "") &&
            (serverResponse.data.EmailAddress === null ||
              serverResponse.data.EmailAddress === "")
          ) {
            this.showAlertDialog = true;
            this.alertReason =
              "No phone mobile number or an email address have been registered for your customer number, kindly visit ZSSF office for more help.";
            return;
          }

          this.invCustRegistrationForm
            .get("firstName")
            .patchValue(serverResponse.data.FirstName);

          this.invCustRegistrationForm
            .get("middleName")
            .patchValue(serverResponse.data.MiddleName);

          this.invCustRegistrationForm
            .get("lastName")
            .patchValue(serverResponse.data.SurName);

          if (
            serverResponse.data.MobilePhone !== null &&
            serverResponse.data.MobilePhone !== ""
          ) {
            try {
              this.invCustRegistrationForm
                .get("mobileNo")
                .patchValue(serverResponse.data.MobilePhone);
              this.indPhoneNumer = this.replacePhoneNumbers(
                serverResponse.data.MobilePhone
              );
            } catch (error) {
              this.indPhoneNumer = null;
            }
          }
          if (
            serverResponse.data.EmailAddress !== null &&
            serverResponse.data.EmailAddress !== ""
          ) {
            try {
              this.indEmail = this.hideEmail(serverResponse.data.EmailAddress);
              this.invCustRegistrationForm
                .get("email")
                .patchValue(serverResponse.data.EmailAddress);
            } catch (error) {
              this.indEmail = null;
            }
          }
          this.requestOTP(
            this.customerVerificationForm.get("customerID").value,
            3,
            "INDIVIDUAL_CONTRIBUTOR"
          );
        } else {
          this.toastr.error(serverResponse.message);
          this.logServerErrors(
            this.router.url,
            serverResponse.message,
            "CUSTOMER_VERIFY_INDIVIDUAL_CONTRIBUTOR"
          );
        }
      },
      (error) => {
        this.logServerErrors(
          this.router.url,
          error,
          "CUSTOMER_VERIFY_INDIVIDUAL_CONTRIBUTOR"
        );
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong, please try again or check your internet connection"
        );
      }
    );
  }

  onEmployerNumberValidation() {
    if (this.customerVerificationForm.get("customerID").invalid) {
      this.toastr.error("Please enter your employer number for verification");
      return;
    }
    const data = {
      requestType: "CUSTOMER_VERIFY",
      linkId: this.customerVerificationForm.get("customerID").value,
      userType: "EMPLOYER",
    };
    this.hideInvestmentReg = true;
    this.spinner.show();
    this.apiCall(data, "portal/request").subscribe(
      (res) => {
        this.spinner.hide();
        const serverResponse = res.json();
        if (serverResponse.code === 2000) {
          if (
            serverResponse.data.TelephoneOffice === null &&
            serverResponse.data.EmailAddress === null
          ) {
            this.showAlertDialog = true;
            this.alertReason =
              "No phone mobile number or an email address have been registered for your employer number, kindly visit ZSSF office for more help.";
            return;
          }
          if (serverResponse.data.TelephoneOffice !== null) {
            try {
              this.empPhoneNumer = this.replacePhoneNumbers(
                serverResponse.data.TelephoneOffice
              );
              this.newUserAccountForm
                .get("mobileNumber")
                .patchValue(serverResponse.data.TelephoneOffice);
            } catch (error) {
              this.empPhoneNumer = null;
            }
          }
          if (serverResponse.data.EmailAddress !== null) {
            //  this.empEmail = serverResponse.data.EmailAddress;
            try {
              this.empEmail = this.hideEmail(serverResponse.data.EmailAddress);
              this.newUserAccountForm
                .get("email")
                .patchValue(serverResponse.data.EmailAddress);
            } catch (error) {
              this.empEmail = serverResponse.data.EmailAddress;
            }
          }
          if (serverResponse.data.EmployerName !== null) {
            this.newUserAccountForm
              .get("organizationName")
              .patchValue(serverResponse.data.EmployerName);
          }
          this.requestOTP(
            this.customerVerificationForm.get("customerID").value,
            1,
            "EMPLOYER"
          );
          this.newUserAccountForm
            .get("employerNumber")
            .patchValue(this.customerVerificationForm.get("customerID").value);
        } else {
          this.toastr.error(serverResponse.message);
          this.logServerErrors(
            this.router.url,
            serverResponse.message,
            "CUSTOMER_VERIFY_EMPLOYER"
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.logServerErrors(
          this.router.url,
          error,
          "CUSTOMER_VERIFY_EMPLOYER"
        );
        this.toastr.error(
          "Something went wrong, please try again or check your internet connection"
        );
      }
    );
  }

  apiCall(data: any, path) {
    const postData = {
      header: {
        userId: "501",
        token: "bC-6pxhT%a_PgYYnhPRCs~PmS_EaKpf@HSzY$HqL",
        channelCode: AppSettings.channelCode,
        channelSecurityCode: AppSettings.channelSecurityCode,
      },
      data,
    };

    const postDataClone = {
      requestUrl: `${AppSettings.apiName}` + path,
      requestBody: postData,
    };
    const dataHash = this.authService.md5hash(
      JSON.stringify(postDataClone) + AppSettings.XPR_TOS_PST_TEST
    );
    const headers = new Headers({
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "x-auth, content-type",
      "Access-Control-Allow-Methods": "POST",
      "X-TPS-OCT-PLAC-7": dataHash,
    });
    return this.http
      .post(AppSettings.phpInterceptor, JSON.stringify(postDataClone), {
        headers,
      })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  finish() {
    this.isRegCompleted = false;
    this.hideRegTypeOption = true;
    this.hideINDReg = true;
    this.hideINDRegForm = true;
    this.hideEmpRegForm = true;
    this.hideInvCustVerifyForm = true;
    this.hideInvCustRegForm = true;
    this.hideEmployerReg = true;
    this.newUserAccountForm.reset();
    this.invCustRegistrationForm.reset();
    this.otpVerificationForm.reset();
    this.router.navigate(["/logn"]);
  }

  replacePhoneNumbers(phoneNumber) {
    const count = phoneNumber.length;
    const mobileNumber = [];
    for (let i = 0; i < count; i++) {
      if (i < 8) {
        mobileNumber[i] = phoneNumber[i].replace(/^\d+$/, "*");
      } else {
        mobileNumber[i] = phoneNumber[i];
      }
    }
    let hiddenPhoneNumber = "";
    for (let i = 0; i < count; i++) {
      hiddenPhoneNumber += mobileNumber[i];
    }
    return hiddenPhoneNumber;
  }

  getCountryCodes() {
    const countryCodes = [
      {
        countryCode: "TZA",
        phonePrefix: "255",
      },
      {
        countryCode: "KEN",
        phonePrefix: "254",
      },
      {
        countryCode: "UGA",
        phonePrefix: "256",
      },
      {
        countryCode: "BDI",
        phonePrefix: "257",
      },
      {
        countryCode: "RWA",
        phonePrefix: "250",
      },
      {
        countryCode: "MWI",
        phonePrefix: "265",
      },
      {
        countryCode: "ZWE",
        phonePrefix: "263",
      },
      {
        countryCode: "ZMB",
        phonePrefix: "260",
      },
    ];

    return countryCodes;
  }

  investmentCustomerIdVerify() {
    if (this.customerVerificationForm.invalid) {
      this.toastr.error(
        "Please enter your customer ID",
        "Customer ID required"
      );
      return;
    }
    this.spinner.show();
    const data = {
      requestType: "CUSTOMER_VERIFY",
      linkId: this.customerVerificationForm.get("customerID").value,
      userType: this.customerVerificationForm.get("invRegType").value,
    };
    this.hideEmployerReg = true;
    this.hideINDReg = true;
    this.apiCall(data, "portal/request").subscribe(
      (res) => {
        const serverResponse = res.json();
        this.spinner.hide();
        if (serverResponse.code == 2000) {
          if (
            (serverResponse.data.mobileNo === null ||
              serverResponse.data.mobileNo === "") &&
            (serverResponse.data.email === null ||
              serverResponse.data.email === "")
          ) {
            this.showAlertDialog = true;
            this.alertReason =
              "No phone mobile number or an email address have been registered for your customer number, kindly visit ZSSF office for more help.";
            return;
          }
          this.invCustRegistrationForm
            .get("fullName")
            .patchValue(serverResponse.data.customerName);
          if (
            serverResponse.data.mobileNo !== null &&
            serverResponse.data.mobileNo !== ""
          ) {
            this.invCustRegistrationForm
              .get("mobileNo")
              .patchValue(serverResponse.data.mobileNo);
            this.customerPhoneNumer = this.replacePhoneNumbers(
              serverResponse.data.mobileNo
            );
          }
          if (
            serverResponse.data.email !== null &&
            serverResponse.data.email !== ""
          ) {
            try {
              this.invCustRegistrationForm
                .get("email")
                .patchValue(serverResponse.data.email);
              this.customerEmail = this.hideEmail(serverResponse.data.email);
            } catch (error) {
              this.customerEmail = null;
            }
          }
          this.requestOTP(
            this.customerVerificationForm.get("customerID").value,
            2,
            this.customerVerificationForm.get("invRegType").value
          );
        } else {
          this.toastr.error(serverResponse.message);
          this.logServerErrors(
            this.router.url,
            serverResponse.message,
            "CUSTOMER_VERIFY_" +
              this.customerVerificationForm.get("invRegType").value
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.logServerErrors(
          this.router.url,
          error,
          "CUSTOMER_VERIFY_" +
            this.customerVerificationForm.get("invRegType").value
        );
        this.toastr.error(
          "Something went wrong, please try again or check your internet connection"
        );
      }
    );
  }

  submitRegistrationDetails() {
    if (this.newUserAccountForm.invalid) {
      this.toastr.error("Please fill all registration details correctly");
      return;
    }

    const data = {
      requestType: "CUSTOMER_CREATE",
      firstName: this.newUserAccountForm.get("firstName").value,
      middleName: this.newUserAccountForm.get("middleName").value,
      lastName: this.newUserAccountForm.get("lastName").value,
      linkId: this.newUserAccountForm.get("employerNumber").value,
      username: `${this.newUserAccountForm.get("username").value}`.trim(),
      password: this.newUserAccountForm.get("password").value,
      designation: this.newUserAccountForm.get("position").value,
      userGroup: "EMPLOYER",
      accountName: this.newUserAccountForm.get("organizationName").value,
      mobileNo: `${this.newUserAccountForm.get("mobileNumber").value}`,
      telephoneNo: "",
      email: this.newUserAccountForm.get("email").value,
    };
    this.spinner.show();
    this.apiCall(data, "portal/request").subscribe(
      (response) => {
        const serverResponse = response.json();

        if (serverResponse.code == 2000) {
          this.hideEmpRegForm = true;
          // this.hideEmployerReg = true;
          this.isRegCompleted = true;
          this.toastr.success(serverResponse.message);
        } else {
          this.toastr.error(serverResponse.message);
          this.logServerErrors(
            this.router.url,
            serverResponse.message,
            "CUSTOMER_CREATE_EMPLOYER"
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.logServerErrors(
          this.router.url,
          error,
          "CUSTOMER_CREATE_EMPLOYER"
        );
        this.toastr.error("Something went wrong", "Please try again");
        this.spinner.hide();
      }
    );
  }

  submitIndividualRegistrationDetails() {
    if (this.invCustRegistrationForm.invalid) {
      this.toastr.error("Please fill in all the form fields required");
      return;
    }

    const data = {
      requestType: "CUSTOMER_CREATE",
      firstName: this.invCustRegistrationForm.get("firstName").value,
      middleName: this.invCustRegistrationForm.get("middleName").value,
      lastName: this.invCustRegistrationForm.get("lastName").value,
      linkId: this.customerVerificationForm.get("customerID").value,
      username: `${this.invCustRegistrationForm.get("username").value}`.trim(),
      password: this.invCustRegistrationForm.get("password").value,
      designation: "",
      userGroup: "INDIVIDUAL_CONTRIBUTOR",
      accountName: `${this.invCustRegistrationForm.get("firstName").value} ${
        this.invCustRegistrationForm.get("middleName").value
      } ${this.invCustRegistrationForm.get("lastName").value}`,
      mobileNo: this.invCustRegistrationForm.get("mobileNo").value,
      telephoneNo: "",
      email: this.invCustRegistrationForm.get("email").value,
    };

    this.spinner.show();
    this.apiCall(data, "portal/request").subscribe(
      (response) => {
        const serverResponse = response.json();

        if (serverResponse.code == 2000) {
          this.hideINDRegForm = true;
          this.isRegCompleted = true;
          this.toastr.success(serverResponse.message);
        } else {
          this.toastr.error(serverResponse.message);
          this.logServerErrors(
            this.router.url,
            serverResponse.message,
            "CUSTOMER_CREATE_INDIVIDUAL_CONTRIBUTOR"
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.logServerErrors(
          this.router.url,
          error,
          "CUSTOMER_CREATE_INDIVIDUAL_CONTRIBUTOR"
        );
        this.spinner.hide();
        this.toastr.error("Something went wrong", "Please try again");
      }
    );
  }

  investmentCustomerRegistration() {
    if (this.invCustRegistrationForm.invalid) {
      this.toastr.error("Please fill in all the form fields required");
      return;
    }

    const data = {
      requestType: "CUSTOMER_CREATE",
      firstName: this.invCustRegistrationForm.get("firstName").value,
      middleName: this.invCustRegistrationForm.get("middleName").value,
      lastName: this.invCustRegistrationForm.get("lastName").value,
      linkId: this.customerVerificationForm.get("customerID").value,
      username: `${this.invCustRegistrationForm.get("username").value}`.trim(),
      password: this.invCustRegistrationForm.get("password").value,
      designation: "",
      userGroup: this.customerVerificationForm.get("invRegType").value,
      mobileNo: this.invCustRegistrationForm.get("mobileNo").value,
      telephoneNo: "",
      email: this.invCustRegistrationForm.get("email").value,
    };
    // this.customerPhoneNumer = this.replacePhoneNumbers(this.invCustRegData.mobileNo);

    this.spinner.show();
    this.apiCall(data, "portal/request").subscribe(
      (response) => {
        const serverResponse = response.json();

        if (serverResponse.code == 2000) {
          this.hideInvCustRegForm = true;
          this.isRegCompleted = true;
          this.toastr.success(serverResponse.message);
        } else {
          this.toastr.error(serverResponse.message);
          this.logServerErrors(
            this.router.url,
            serverResponse.message,
            "CUSTOMER_CREATE_" +
              this.customerVerificationForm.get("invRegType").value
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.logServerErrors(
          this.router.url,
          error,
          "CUSTOMER_CREATE_" +
            this.customerVerificationForm.get("invRegType").value
        );
        this.spinner.hide();
      }
    );
  }

  hideEmail(email) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  }



  getSectors() {
    const data = {
      requestType: "SECTOR_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.sectorList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getDistricts() {
    const data = {
      requestType: "DISTRICT_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.districtList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  businessNature() {
    const data = {
      requestType: "NATURE_OF_BUSINESS_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.natureBusinessList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  branch() {
    const data = {
      requestType: "BRANCHES",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.branchList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  filterDistricts(e) {
    this.districtListNew = this.districtList.filter(
      (res) => res.RegionID == e.value
    );
  }

  getRegion() {
    const data = {
      requestType: "REGION_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.regionList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
}
