import { Component, OnInit } from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-notification-info",
  templateUrl: "./notification-info.component.html",
  styleUrls: ["./notification-info.component.scss"],
})
export class NotificationInfoComponent
  extends SharedClassComponent
  implements OnInit
{
  notificationID: any;
  notificationInfo: any = [];

  ngOnInit() {
    this.notificationID = this.route.snapshot.params["id"];
    this.getNotificationsDetails();
  }

  getNotificationsDetails() {
    const data = {
      requestType: "COMPENSATION_CLAIM_EMPLOYER_NOTIFICATION_GET",
      notificationID: this.notificationID,
    };

    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.notificationInfo = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
}
