import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-feedback-reply',
  templateUrl: './feedback-reply.component.html',
  styleUrls: ['./feedback-reply.component.scss']
})
export class FeedbackReplyComponent extends SharedClassComponent implements OnInit {
  dataSource: any[];
  title = 'User Feedback Follow Up';
  ngOnInit() {
    this.apiCall();
  }

  apiCall() {
    const data = {
      customerNumber: this.authService.getUserDetails().linkId
    };
    this.spinner.show();
    this.utilities.helperApiCall(data, 'users/feedback/customer').subscribe(res => {
      if (res.statusCode === 2000) {
        this.dataSource = res.data;
      } else {
        this.toastr.error(res.statusMessage);
      }
      this.spinner.hide();
    }, error => {
      this.toastr.error('Something went wrong fetching the results, please try again');
      this.spinner.hide();
    });
  }

  onViewUserFeedback(e) {
    sessionStorage.removeItem(AppSettings.feedbackKey);
    sessionStorage.setItem(AppSettings.feedbackKey, this.securityService.encryptString(e));
    this.router.navigate(['user/feedback/view']);
  }


  toolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.apiCall.bind(this)
        }
      }
    );
  }
}
