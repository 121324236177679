import {createAction, props} from '@ngrx/store';
import {InvoiceModel} from '../models/invoiceModel';

export const getInvoiceAction = createAction('[Invoice] - get Invoice');

export const CreateInvoiceAction = createAction('[Invoice] - get Invoice', props<InvoiceModel>());

export const BeginGetInvoiceAction = createAction('[Invoice] - get Invoice');

export const SuccessGetInvoiceAction = createAction(
  '[Invoice] - get Invoice',
  props<{ payload: InvoiceModel[] }>()
);

export const BeginCreateInvoiceAction = createAction(
  '[Invoice] - get Invoice',
  props<{ payload: InvoiceModel }>()
);

export const SuccessCreateInvoiceAction = createAction(
  '[Invoice] - get Invoice',
  props<{ payload: InvoiceModel }>()
);

export const ErrorInvoiceAction = createAction('[Invoice] - get Invoice', props<Error>());
