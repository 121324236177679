import { Component, OnInit } from '@angular/core';
import {SharedClassComponent} from '../../../configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent extends SharedClassComponent implements OnInit {
  generateControlNumberEndpoint = 'fumis_bill/create';
  hideQrCode = true;
  qrCodeString = '';
  controlNumber: any;
  billDescription = '';
  employerNumber: any;
  payableBanks = '';
  invoiceNumber: any;
  invoiceID: any;
  invoiceDetailID: any;
  invoiceTrackNumber: any;
  created: any;
  totalAmountContributed: any;
  employerName: any;
  employerPhone: any;
  employerEmail: any;
  paymentType: any;
  invoiceDescription: any;
  contributionYear: any;
  contributionMonth: any;
  invoiceCurrency: any;
  amountInWords = '';
  billExpireDate = '';
  now = new Date();
  currentYear = this.now.getFullYear();
  title = 'Contribution Invoice';
  openInvoiceEditPopUp = false;
  invoiceForm: FormGroup;
  months: any;
  years: any;
  curDate = new Date();
  isInvoicePostedForPayment = false;
  isInvoiceCreated = true;
  isInvoicePrinted = false;
  isMultipleContibutionInvoice = false;

  hidePrintInvoiceButton = true;
  hideGenerateControlNumberButton = false;
  hideEditInvoiceButton = false;
  hideCancelInvoiceButton = false;
  hideNoContributionMadeForInvoiceBanner =  true;

  membersContributionRouterLink = '/contribution/upload';
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.isMultipleContibutionInvoice = params.multi;
    });
    this.isInvoiceCreated = this.invoiceService.getIsInvoiceCreated();
    if (!this.isInvoiceCreated) {
      this.hideNoContributionMadeForInvoiceBanner = false;
      this.hidePrintInvoiceButton = true;
      this.hideGenerateControlNumberButton = true;
      this.hideEditInvoiceButton = true;
      this.hideCancelInvoiceButton = true;
    }
    this.controlNumber = '';
    this.employerNumber = this.authService.getUserDetails().linkId;
    this.invoiceNumber = this.invoiceService.getInvoiceNumber();
    this.invoiceID = this.invoiceService.getInvoiceID();
    this.invoiceDetailID = this.invoiceService.getInvoiceDetailID();
    this.invoiceTrackNumber = this.invoiceService.getInvoiceTrackNumber();
    this.created = this.invoiceService.getCreated();
    this.totalAmountContributed = this.invoiceService.getTotalAmountContributed();
    this.employerName = this.invoiceService.getEmployerName();
    this.employerPhone = this.invoiceService.getEmployerPhone();
    this.employerEmail = this.invoiceService.getEmployerEmail();
    this.paymentType = this.invoiceService.getPaymentType();
    this.invoiceDescription = this.invoiceService.getInvoiceDescription();
    this.contributionYear = this.invoiceService.getContributionYear();
    this.contributionMonth = this.invoiceService.getContributionMonth();
    this.invoiceCurrency = this.invoiceService.getInvoiceCurrency();

    this.months = this.contributionService.getMonthsDataset();
    this.years = this.contributionService.getYears();

    this.invoiceForm = new FormGroup({
      employerName: new FormControl(null, Validators.compose([Validators.required])),
      employerPhone: new FormControl(null, Validators.compose([])),
      employerEmail: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
      contributionYear: new FormControl(null, Validators.compose([Validators.required])),
      contributionMonth: new FormControl(null, Validators.compose([Validators.required])),
      invoiceDescription: new FormControl(null, Validators.compose([Validators.required, Validators.max(250)]))
    });
  }

  generateControlNumber() {
    const result = confirm(
      'You\'re about to generate the invoice\'s control number, no further invoice\'s modifications are allowed after control number is generated and you can not cancel the invoice afterwards!',
      'Generate invoice\'s Control number'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        const data = {
          employerNumber: this.contributionService.getEmployerNumber(),
          invoices: [
            {invoiceId: this.invoiceService.getInvoiceID()}
          ]
        };

        this.utilities.postServiceCall(data, this.generateControlNumberEndpoint).subscribe(res => {
          const serveRes = res.json();

          if (serveRes.code == 2000) {
            // tslint:disable-next-line: max-line-length
            this.qrCodeString = `controlNumber:${serveRes.data.controlNumber};invoiceNumber:${this.invoiceNumber};totalAmount:${serveRes.data.totalAmount};currency:${serveRes.data.currency};expireDate:${serveRes.data.billExpireDate};`;
            this.hideQrCode = false;
            this.toastr.success(serveRes.message, 'Control Number Generation');
            this.isInvoicePostedForPayment = true;
            this.hidePrintInvoiceButton = false;
            this.hideGenerateControlNumberButton = true;
            this.hideEditInvoiceButton = true;
            this.hideCancelInvoiceButton = true;
            this.controlNumber = serveRes.data.controlNumber;
            this.invoiceCurrency = serveRes.data.currency;
            this.amountInWords = serveRes.data.totalAmountWords;
            this.billExpireDate = serveRes.data.billExpireDate;
            this.employerPhone = serveRes.data.employerMobile;
            this.billDescription = serveRes.data.billDescription;
            this.totalAmountContributed = serveRes.data.totalAmount;
            this.payableBanks = serveRes.data.banks;
          }
        });
      }
    });
  }

  printInvoiceReceipt() {
    this.isInvoicePrinted = true;
    let popUpWindow;
    let innerContents = document.getElementById('print-container2').innerHTML;
    innerContents = innerContents.replace('./assets/images/zssf.png', './../assets/images/zssf.png');
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
           <style>
            body{
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
}
.logo__block{
  border-bottom: 1px dotted #4d88bb;
}
.logo__block p, .footer, p {
  color: rgb(0 172 238);
}
.invoice__head__child_contacts{
  margin: 19px;
}
.invoice{
    background-color: rgba(0, 0, 0, 0.055);
    padding: 2rem;
    width: 1024px;
    height: 100vh;
  }
  .invoice__head,
  .invoice__how_to_pay{
    display: flex;
    justify-content: space-between;
  }
  .logo__block, .footer{
      text-align: center;
  }
table tbody tr td{
  padding: .5rem;
}

@media print {

  @page {
    size: A4;
    margin: 0mm;
  }

  html, body {
    width: 1024px;
  }

  body {
    margin: 0 auto;
    line-height: 1em;
    word-spacing:2px;
    letter-spacing:0.2px;
    /* font: 14px "Times New Roman", Times, serif; */
    font-size: 14px;
    background:rgba(0, 0, 0, 0.055);
    color:black;
    width: 100%;
    float: none;
  }
  .footer{
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }
  .invoice{
    width: 1024px;
    background-color: transparent;
  }

  /* avoid page-breaks inside a listingContainer*/
  .listingContainer{
    page-break-inside: avoid;
  }
  h1 {
      font: 28px "Times New Roman", Times, serif;
    }

    h2 {
      font: 24px "Times New Roman", Times, serif;
    }

    h3 {
      font: 20px "Times New Roman", Times, serif;
    }

    /* Improve colour contrast of links */
    a:link, a:visited {
      color: #781351
    }

    /* URL */
    a:link, a:visited {
      background: transparent;
      color:#333;
      text-decoration:none;
    }

    a[href]:after {
      content: "" !important;
    }
    a[href^="http://"] {
      color:#000;
    }

    #header {
      height:75px;
      font-size: 24pt;
      color:black
    }
  }
           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();

    // const printContainer: HTMLElement = document.querySelector('#print-container');

    // document.body.appendChild(printContainer);
  }

  cancelInvoice() {
    const result = confirm(
      'Are You sure You want to cancel this invoice ?',
      'Invoice Contribution'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.invoiceNumber = null;
        this.invoiceID = null;
        this.invoiceDetailID = null;
        this.invoiceTrackNumber = null;
        this.created = null;
        this.totalAmountContributed = null;
        this.employerName = null;
        this.employerPhone = null;
        this.employerEmail = null;
        this.paymentType = null;
        this.invoiceDescription = null;
        this.contributionYear = null;
        this.contributionMonth = null;
        this.isInvoicePostedForPayment = false;
        this.isInvoicePrinted = false;
        this.isInvoiceCreated = false;
        this.invoiceService.setIsInvoiceCreated(false);
        this.router.navigate(['/home']);
      }
    });

  }
  closePopUp() {
    this.openInvoiceEditPopUp = false;
  }

  onEditInvoice() {
  if (this.invoiceForm.invalid) {
    this.toastr.error('Please fill out all form data', 'Alert');
    return;
  }
  this.employerName = this.invoiceForm.get('employerName').value;
  this.employerPhone = this.invoiceForm.get('employerPhone').value;
  this.employerEmail = this.invoiceForm.get('employerEmail').value;
  this.invoiceDescription = this.invoiceForm.get('invoiceDescription').value;
  this.contributionYear = this.invoiceForm.get('contributionYear').value;
  this.contributionMonth = this.invoiceForm.get('contributionMonth').value;

  this.openInvoiceEditPopUp = false;
  }

  openPopUpDialog() {
    this.invoiceForm.get('employerName').patchValue(this.employerName);
    this.invoiceForm.get('employerPhone').patchValue(this.employerPhone);
    this.invoiceForm.get('employerEmail').patchValue(this.employerEmail);
    this.invoiceForm.get('invoiceDescription').patchValue(this.invoiceDescription);
    this.invoiceForm.get('contributionYear').patchValue(this.contributionYear);
    this.invoiceForm.get('contributionMonth').patchValue(this.contributionMonth);
    this.openInvoiceEditPopUp = true;
  }

  makeNewContribution() {
    if (!this.isInvoicePostedForPayment) {
      const result = confirm(
        'Are You sure You want to cancel this invoice ?',
        'Invoice Contribution'
      );
      result.then(dialogResult => {
        if (dialogResult) {
          this.router.navigate(['/contribution/upload']);
        }
      });
    } else {
      // this.router.navigate(['/contribution/upload']);
    }
  }


}
