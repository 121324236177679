// // LIVE
export class AppSettings {
  public static channelId = "3001";
  public static channelCode = "PT001";
  public static channelSecurityCode = "password";

  public static ip = "192.168.10.6";

  // public static apiName = 'zssfapi/'; // test
  public static apiName = "zssflive/"; // live
  //https://zlx.realtech.app

  // LIVE PHP INTERCEPTOR

  // public static phpInterceptor = 'https://test-folder-outside.zssf.or.tz/portal/';
  //public static phpInterceptor = 'http://41.79.186.26:8088/zssflive/portal/request/'
  //public static phpInterceptor = 'https://test-folder-outside.zssf.realtech.app/';
  // public static phpInterceptor = "https://test-folder-outside.zssf.realtech.app/portal/";
  //public static phpInterceptor = "https://zssfapi.realtech.app/portal/";
  //public static phpInterceptor = "https://zlx.realtech.app";

  
  public static errorLogInterceptor =
    "https://test-folder-outside.zssf.or.tz/portal/error_log.php";

  //public static feedbackUri = 'microservice/api/v1/';  // live

  public static feedbackUri = "microservice/api/v1/"; // test

  // public static phpInterceptor =
  //   "https://test-folder-outside.zssf.or.tz/portal/";

  public static phpInterceptor =
    "https://test-folder-outside.zssf.realtech.app/portal/";
  public static phpInterceptorCompesation =
    "https://test-folder-outside.zssf.realtech.app/online/";

  //public static feedbackUri = 'microservice/api/v1/';  // test

  // public static phpInterceptor = 'https://test-folder-outside.zssf.or.tz/portal/';

  // public static errorLogInterceptor = 'https://test-folder-outside.zssf.or.tz/portal/error_log.php';
  // public static errorLogInterceptor = 'https://portal.zssf.or.tz/errorlog/';
  // public static phpInterceptor = 'https://portal.zssf.or.tz/online/';

  // UAT PHP INTERCEPTOR
  // public static phpInterceptor = 'https://portal.zssf.or.tz/interceptor/';

  // UAT PHP INTERCEPTOR - LOCAL
  //public static phpInterceptor = 'http://localhost/interceptor/';

  public static prodEnv = true; // for live
  // public static prodEnv = false; // for test

  // Local keys for sessionStorage
  public static isLoggedInKey = "$2y$18$6MWbDSFZ7m";
  public static sessionKey = "$2y$18$pt36boBQmFt84";
  public static userDataKey = "$2y$18$/d5.3e4OtdLll";
  public static localStorageUserRoleKey =
    "$2y$12$hykQ8O/j8.ju8PeLXaUNuesdu.HuEh8ff81iAxOZalUNLodLxtE1u";
  public static localStorageEmployerDetails =
    "$2y$12$x75rm3quceQuXIK7QHvnQO/xDdn..XxEPdvrVghdm6MHHoCJPLWP2";

  // Storage keys for sessionStorage
  public static sIsLoggedInKey = "84UOnQC7Lfud5nlWIXb";
  public static sTokenKey = "SeRu/m/dfa$pt36boBQmFt84U";
  public static sUserDataKey = "Lfud5nl/d5.3e4OtdLllt2";
  public static sessionStorageUserRoleKey =
    "$2y$18$ltOZKVhVNdx.4B2lJXcD3uqepXovBK79UwwfqowYv0JuVECRDzvTe";
  public static sessionStorageEmployerDetails =
    "$2y$12$ehTUbwywR0ZY8Z//ccsvu.5yPJilPP6PgmdtMqvZAcmK1.b1HnTga";

  public static rememberMeKey = "36boBQm$pt36Lfud5nl";

  public static invoiceStorageKey =
    "4B2lJXcD3u8$pt36boBQmqepXoJilPPvBK79UwwfqowYv";

  public static contributionBatchRequestKey =
    "$2y$12$u4YNancLjwpR35nal0oGy.tJyLo.WImQKljezmfFw5gHzg5vpIlSS";

  public static contributionBatchMonthKey =
    "$2y$12$QeqTFXgeTegT3XkGRlSdZe93a7NWs8ctMdJosz/qIFu7yQe2WivrS";

  public static batchContributionInvoiceResultDataKey =
    "$2y$12$bEWOUyvLtbFXUzQ3Y4RLLuskddHAzJUl8NC1KcvdLrU8iqYYqNQFi";

  public static invoiceDetailsKey = "pt36boBQmqepXoJilPPvBK79Uwwfqo";

  public static invoiceBillsItems = "ancLjwpR35nal0oGy.tJyLo.WImQKljezmf";

  public static contributionInvoiceIdKey =
    "hklhlDSjk986BU?>&UYY9867$%#$%^&*^UHLUHLG";

  public static XPR_TOS_PST_TEST = "985243$#@$^@1ERF";

  public static OTPkey = "XcD3uoJilPPvBK79qepXovBFXUzQ3Y4KQuXIK7QHvn";

  public static regDetailsKey = "8PeLXaUNuesdu.HuEh8ff81iAxO";

  public static feedbackKey = "RLLuskddHAzJUl8N$pt36boBQmqepXoJilPPvBK79";

  public static invoiceContributionTotalMembers =
    "bEWOfgh56y56tbFXUz657546trhfdhgreKcvdtghw4556u56dfgbgdzf4354*&^hgjgFi";

  public static CAIH = "CAIH";
}

// TEST

// export class AppSettings {
//   public static channelId = "3001";
//   public static channelCode = "PT001";
//   public static channelSecurityCode = "password";

//   public static ip = "192.168.10.6";

//   // public static apiName = "etx/bx/"; // test
//   public static apiName = "zssfapi/"; // test
//   //public static apiName = 'zssflive/'; // live

//   // LIVE PHP INTERCEPTOR

//   // public static phpInterceptor = 'https://test-folder-outside.zssf.or.tz/portal/';
//   //public static phpInterceptor = 'http://41.79.186.26:8088/zssflive/portal/request/'
//   // public static phpInterceptor = "https://zssf.amet.co.tz/portal/";

//   public static phpInterceptor =
//     "https://test-folder-outside.zssf.realtech.app/portal/";
//   public static phpInterceptorCompesation =
//     "https://test-folder-outside.zssf.or.tz/portal/test/";



//   public static errorLogInterceptor =
//     "https://test-folder-outside.zssf.realtech.app/portal/error_log.php";

//   public static feedbackUri = "microservice/api/v1/"; // live

//   // public static errorLogInterceptor = 'https://test-folder-outside.zssf.or.tz/portal/error_log.php';
//   // public static errorLogInterceptor = 'https://portal.zssf.or.tz/errorlog/';
//   // public static phpInterceptor = 'https://portal.zssf.or.tz/online/';

//   // UAT PHP INTERCEPTOR
//   // public static phpInterceptor = 'https://portal.zssf.or.tz/interceptor/';

//   // UAT PHP INTERCEPTOR - LOCAL
//   //public static phpInterceptor = 'http://localhost/interceptor/';

//   //public static prodEnv = true; // for live
//   public static prodEnv = false; // for test

//   // Local keys for sessionStorage
//   public static isLoggedInKey = "$2y$18$6MWbDSFZ7m";
//   public static sessionKey = "$2y$18$pt36boBQmFt84";
//   public static userDataKey = "$2y$18$/d5.3e4OtdLll";
//   public static localStorageUserRoleKey =
//     "$2y$12$hykQ8O/j8.ju8PeLXaUNuesdu.HuEh8ff81iAxOZalUNLodLxtE1u";
//   public static localStorageEmployerDetails =
//     "$2y$12$x75rm3quceQuXIK7QHvnQO/xDdn..XxEPdvrVghdm6MHHoCJPLWP2";

//   // Storage keys for sessionStorage
//   public static sIsLoggedInKey = "84UOnQC7Lfud5nlWIXb";
//   public static sTokenKey = "SeRu/m/dfa$pt36boBQmFt84U";
//   public static sUserDataKey = "Lfud5nl/d5.3e4OtdLllt2";
//   public static sessionStorageUserRoleKey =
//     "$2y$18$ltOZKVhVNdx.4B2lJXcD3uqepXovBK79UwwfqowYv0JuVECRDzvTe";
//   public static sessionStorageEmployerDetails =
//     "$2y$12$ehTUbwywR0ZY8Z//ccsvu.5yPJilPP6PgmdtMqvZAcmK1.b1HnTga";

//   public static rememberMeKey = "36boBQm$pt36Lfud5nl";

//   public static invoiceStorageKey =
//     "4B2lJXcD3u8$pt36boBQmqepXoJilPPvBK79UwwfqowYv";

//   public static contributionBatchRequestKey =
//     "$2y$12$u4YNancLjwpR35nal0oGy.tJyLo.WImQKljezmfFw5gHzg5vpIlSS";

//   public static contributionBatchMonthKey =
//     "$2y$12$QeqTFXgeTegT3XkGRlSdZe93a7NWs8ctMdJosz/qIFu7yQe2WivrS";

//   public static batchContributionInvoiceResultDataKey =
//     "$2y$12$bEWOUyvLtbFXUzQ3Y4RLLuskddHAzJUl8NC1KcvdLrU8iqYYqNQFi";

//   public static invoiceDetailsKey = "pt36boBQmqepXoJilPPvBK79Uwwfqo";

//   public static invoiceBillsItems = "ancLjwpR35nal0oGy.tJyLo.WImQKljezmf";

//   public static contributionInvoiceIdKey =
//     "hklhlDSjk986BU?>&UYY9867$%#$%^&*^UHLUHLG";

//   public static XPR_TOS_PST_TEST = "985243$#@$^@1ERF";

//   public static OTPkey = "XcD3uoJilPPvBK79qepXovBFXUzQ3Y4KQuXIK7QHvn";

//   public static regDetailsKey = "8PeLXaUNuesdu.HuEh8ff81iAxO";

//   public static feedbackKey = "RLLuskddHAzJUl8N$pt36boBQmqepXoJilPPvBK79";

//   public static invoiceContributionTotalMembers =
//     "bEWOfgh56y56tbFXUz657546trhfdhgreKcvdtghw4556u56dfgbgdzf4354*&^hgjgFi";

//   public static CAIH = "CAIH";
// }
