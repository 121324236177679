import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppSettings } from '../app-settings';
import { confirm } from 'devextreme/ui/dialog';

import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss']
})
export class ServiceProviderComponent extends SharedClassComponent implements OnInit {
  title = 'Published Invoices';
  invoiceGeneratedByEmployerDataSource = [];
  invoiceGeneratedByIndividualDataSource = [];
  endPointCallInvoiceGenerated = 'employer/invoices';
  portalRqUrl = 'portal/request';
  selectedRows = [];
  allMode: string;
  checkBoxesMode: string;
  hideEmpDataGrid = false;


  ngOnInit() {
    this.allMode = 'page';
    this.checkBoxesMode = 'always';
    this.observerCall();
    this.appInfo.setTitle(this.title);

    if (this.authService.getUserDetails().userGroup === 'INDIVIDUAL_CONTRIBUTOR') {
      this.hideEmpDataGrid = true;
      this.getIndividualContributorInvoices()
    }

    if(this.authService.getUserDetails().userGroup === 'EMPLOYER') {
      this.hideEmpDataGrid = false;
      this.getListOfGeneratedInvoices();
    }
  }

  deleteInvoice(e) {
    const result = confirm(
      'Do you want to delete selected invoice ? Click Yes to continue',
      'Discard Changes'
    );

    result.then(dialogResult => {
      if (dialogResult) {
        const data = {
          requestType: 'INVOICE_DELETE',
          invoiceRef: e.id
        };

        this.spinner.show();
        this.utilities.postServiceCall(data, this.portalRqUrl).subscribe(res => {
          const serverResp = res.json();
          this.spinner.hide();
          if (serverResp.code == 2000) {
            this.toastr.success(serverResp.message, 'Invoice Delete');
            if (this.authService.getUserDetails().userGroup === 'INDIVIDUAL_CONTRIBUTOR') {
              this.hideEmpDataGrid = true;
              this.getIndividualContributorInvoices()
            }

            if(this.authService.getUserDetails().userGroup === 'EMPLOYER') {
              this.hideEmpDataGrid = false;
              this.getListOfGeneratedInvoices();
            }
          } else {
            this.toastr.error(serverResp.message, 'Invoice Delete');
            this.logServerErrors(this.router.url, serverResp.message, 'Invoice Delete');
          }
        }, err => {
          this.spinner.hide();
          this.logServerErrors(this.router.url, err, 'Invoice Delete');
          this.toastr.success('Something went wrong while processing the request.', 'Request Failed');
        });
      }
  });

  }

  getListOfGeneratedInvoices() {
    // const data = {
    //   employerNumber: this.authService.getUserDetails().linkId
    // };

    const data = {
      requestType:"INVOICES_LIST",
      userGroup:"EMPLOYER",
      linkId: this.authService.getUserDetails().linkId
    }
    this.spinner.show();
    this.utilities.postServiceCall(data, this.portalRqUrl).subscribe(res => {
    const serverResponse = res.json();
    if (serverResponse.code == 2000) {
      this.invoiceGeneratedByEmployerDataSource = serverResponse.data;
    } else {
      this.invoiceGeneratedByEmployerDataSource = [];
      this.logServerErrors(this.router.url, serverResponse.message, 'INVOICES_LIST_EMPLOYER');
    }
    this.spinner.hide();
  }, error => {
    this.logServerErrors(this.router.url, error, 'INVOICES_LIST_EMPLOYER');
  });
}

getIndividualContributorInvoices() {
  const data = {
    requestType:'INVOICES_LIST',
    userGroup:'INDIVIDUAL_CONTRIBUTOR',
    linkId: this.authService.getUserDetails().linkId
  };
  this.spinner.show();
  this.utilities.postServiceCall(data, this.portalRqUrl).subscribe(res => {
  const serverResponse = res.json();
  if (serverResponse.code == 2000) {
    this.invoiceGeneratedByIndividualDataSource = serverResponse.data;
  } else {
    this.invoiceGeneratedByIndividualDataSource = [];
    this.logServerErrors(this.router.url, serverResponse.message, 'INVOICES_LIST_INDIVIDUAL_CONTRIBUTOR');
  }
  this.spinner.hide();
}, error => {
  this.logServerErrors(this.router.url, error, 'INVOICES_LIST_INDIVIDUAL_CONTRIBUTOR');
});
}

getControlNumber(e) {

  const data = [{
    invoiceId: ''
  }]

  if (this.authService.getUserDetails().userGroup === 'INDIVIDUAL_CONTRIBUTOR') {
      data[0].invoiceId = e.InvoiceID
  } else {
    data[0].invoiceId = e.id
  }
  sessionStorage.removeItem(AppSettings.invoiceBillsItems);
  sessionStorage.setItem(AppSettings.invoiceBillsItems, this.securityService.encryptString(data));
  this.router.navigate(['/multiple-contribution-invoice']);
}

generateControlNumber() {
  if (this.selectedRows === undefined) {
    this.toastr.info('Please select invoices with status \"No Control Number\" only');
    return;
  }
  // this.router.navigate(['/multiple-contribution-invoice'], {queryParams: {invoiceId: el.id}, queryParamsHandling: 'merge'});
  const inVoiceData = [];
  const invoiceWithControlNumber = [];
  const invoiceWithoutControlNumber = [];

  this.invoiceGeneratedByEmployerDataSource.forEach(el => {
    if (el.isPosted == 2 || el.isPosted == 1) {
      invoiceWithControlNumber.push(el.id);
    }
});

  // tslint:disable-next-line: prefer-for-of
  for (let index = 0; index < invoiceWithControlNumber.length; index++) {
  if (this.selectedRows.includes(invoiceWithControlNumber[index])) {
    this.toastr.error('Please select invoices with status \"No Control Number\" only');
    return;
  }
}

  if (this.selectedRows !== undefined) {
    this.selectedRows.forEach(element => {
      inVoiceData.push({
        invoiceId: element
      });
    });
  }
  sessionStorage.removeItem(AppSettings.invoiceBillsItems);
  sessionStorage.setItem(AppSettings.invoiceBillsItems, this.securityService.encryptString(inVoiceData));

  this.router.navigate(['/multiple-contribution-invoice']);
}

printPaymentReceipt(el) {
  this.toastr.success('Payment receipt clicked');
  // this.router.navigate(['/multiple-contribution-invoice'], {queryParams: {invoiceId: el.id}, queryParamsHandling: 'merge'});
}

viewInvoiceDetails(el) {
  this.toastr.success('View invoice clicked');
}

toolBarPreparing(e, refresh_action) {
  e.toolbarOptions.items.unshift(
       {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
        onClick: refresh_action.bind(this)
      }
    }
  );
}
//WHERE TO PLACE INVOICE MODULE-TO EACH
}
