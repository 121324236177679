import { Component, OnInit } from '@angular/core';
import { merge } from 'jquery';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends SharedClassComponent implements OnInit {
  tab_paneldata: any = [
    {
      ID: 1,
      icon: 'fa fa-file-text',
      name: 'All Invoices',
    },
    {
      ID: 2,
      icon: 'fa fa-check',
      name: 'Paid Invoices',
    },
    {
      ID: 3,
      icon: 'fa fa-hourglass-start',
      name: 'Unpaid Invoice',
    },
  ];
  noData = [];
  customerInvoices = [];
  totalInvoices = 0;

  ngOnInit() {
    this.observerCall();
    this.getCustomerInvoices();
  }

  getCustomerInvoices() {
    const data = {
      requestType: 'CUSTOMER_INVOICES',
      linkId: this.authService.getUserDetails().linkId,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data, 'portal/request').subscribe(
      res => {
      this.spinner.hide();
      const serveResponse = res.json();

      if (serveResponse.code == 2000) {
        const arr = serveResponse.data;
        if (arr === null || arr === undefined) {
          this.toastr.info('No invoice');
          return;
        }
        if (Array.isArray(arr)) {
          // this.toastr.info('Array');
          this.customerInvoices = [];
          arr.forEach(element => {
            this.customerInvoices.push(element);
          });
          this.totalInvoices = this.customerInvoices.length;
          return;
        }
        if (!Array.isArray(arr)) {
          // this.toastr.info('Object');
          this.customerInvoices = [];
          this.customerInvoices.push(arr);
          this.totalInvoices = 1;
          return;
        }

    } else {
      this.toastr.error(serveResponse.message);
      this.logServerErrors(this.router.url, serveResponse.message, 'CUSTOMER_INVOICES');
    }
    },
    (error) => {
      this.spinner.hide();
      this.logServerErrors(this.router.url, error, 'CUSTOMER_INVOICES');
      // this.toastr.error('Something went wrong, please try again');
    }
  );
  }

  previewCustomerInvoice(data) {
    this.router.navigate(['customers/invoices/detail'], {queryParams: {invoiceId: data.invoiceId}, queryParamsHandling: 'merge'});
  }

  makeInvoicePayment(data) {
    this.router.navigate(['customers/bills/payment'], {queryParams: {invoiceId: data.invoiceId}, queryParamsHandling: 'merge'});
    // this.toastr.success('Payment initiated', data.invoiceId);
  }

  onToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getCustomerInvoices.bind(this)
        }
      }
    );
  }
}
