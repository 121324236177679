import { Component, OnInit } from '@angular/core';

import {  DxContextMenuModule, DxMenuModule } from 'devextreme-angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';
@Component({
  selector: 'app-system-privileges',
  templateUrl: './system-privileges.component.html',
  styleUrls: ['./system-privileges.component.scss']
})
export class SystemPrivilegesComponent extends SharedClassComponent implements OnInit {

  title:string= 'System Priviledges';
  model_name:string='priviledges';
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  paramsDataSets:any;
  param_id:any;
  response:any;

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);

    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      name: new FormControl('', Validators.compose([Validators.required])),
      auth: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([Validators.required])),
      priviledgeId: new FormControl('', Validators.compose([]))
    });
    this.onGetParamsdetails();

    // Controls the datagrid height and max rows to display
    this.observerCall();

    this.setDataGridRefreshTimeInterval();

  }
}
