import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-disease-form",
  templateUrl: "./disease-form.component.html",
  styleUrls: ["./disease-form.component.scss"],
})
export class DiseaseFormComponent
  extends SharedClassComponent
  implements OnInit
{
  //@ViewChild('videoPlayer','') videoplayer: any;
  returnedList = [
    { val: 1, name: "Yes" },
    { val: 0, name: "No" },
  ];
  employmentTypesList = [{ name: "FULL TIME" }, { name: "PART TIME" }];
  searchKeyDataSource = [
    { val: 1, name: "Employer Number" },
    { val: 2, name: "Employer Name" },
  ];
  witnesses: any = [];
  fileDatasource: any = [];
  witnessForm: FormGroup;
  NotificationDiseaseForm: FormGroup;
  file: any;
  districtList: any = [];
  districtListNew: any = [];
  regionList: any = [];
  data: any;
  fileUpload: any;
  base64file: any;
  fileDescription: any;
  fileExtension: any;
  incidentOutcomeList: any = [];
  treatmenttypesList: any = [];
  paymentModes: any = [];
  bankList: any = [];
  previewUrl: string; // URL for previewing the PDF
  fileName: string; // File name for download
  accident = false;

  fileData = "";
  employerNumberValid = false;
  shehiaListNew: any;
  shehiaList: any;
  sectorList: any;
  nationalityDataSource: any;
  isSubmitButtonShown = false;
  activateMemberNumberSearch = false;
  activateMemberNameSearch = false;
  searchKeySelected = false;
  searchForm: FormGroup;
  showAlertDialog = false;
  memberInfo: any = [];
  showAlertDialogEmployer = true;
  employerDatasource: any = [];
  activateEmployerNameSearch = false;
  activateEmployerNumberSearch = false;
  employerDetails: any = [];
  employerNumber: any;
  membersList: any;
  memberDetails: any = [];
  showDepartmentTextbox = false;
  declarationValued = false;
  showSearchOptions = false;
  employerInfoSearchResult: any = [];
  showAlertDialogEmployerx = false;
  employerNumberSearch = false;
  employerNameSearch = false;
  showAlertDialogEmp = false;
  showOtherDetails = false;
  inputTimer: any;
  fetchForm: FormGroup;
  showOtherDetais: boolean = false;
  ngOnInit() {
    this.getDistricts();
    this.getRegion();
    this.shehia();
    this.getSectors();
    this.nationality();

    this.witnessForm = new FormGroup({
      fullname: new FormControl("", Validators.compose([])),
      address: new FormControl("", Validators.compose([])),
      phone: new FormControl(
        "",
        Validators.compose([Validators.pattern(/^0\d{9}$/)])
      ),
      position: new FormControl("", Validators.compose([])),
    });

    this.NotificationDiseaseForm = new FormGroup({
      empNo: new FormControl("", Validators.compose([Validators.required])),
      empName: new FormControl("", Validators.compose([])),
      memberNo: new FormControl("", Validators.compose([Validators.required])),
      fullName: new FormControl("", Validators.compose([])),
      diagnosisDate: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      diseaseDiagnosed: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      diseaseReportingDate: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      diseasePremises: new FormControl("", Validators.compose([])),
      moreDetailsDisease: new FormControl("", Validators.compose([])),
      hospitalName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      practitionalName: new FormControl("", Validators.compose([])),
      practitionalPhone: new FormControl("", Validators.compose([])),

      //  sector: new FormControl('', Validators.compose([])),
      //  telephone: new FormControl('', Validators.compose([])),
      //  email: new FormControl('', Validators.compose([])),
      //  shehia: new FormControl('', Validators.compose([])),
      //  district: new FormControl('', Validators.compose([])),
      //  region: new FormControl('', Validators.compose([])),
      //  address: new FormControl('', Validators.compose([])),
      //  CountryName: new FormControl('', Validators.compose([])),

      //  fname: new FormControl('', Validators.compose([])),
      //  mName: new FormControl('', Validators.compose([])),
      //  lname: new FormControl('', Validators.compose([])),
      //  dob: new FormControl('', Validators.compose([])),
      //  gender: new FormControl('', Validators.compose([])),
      //  maritalStatus: new FormControl('', Validators.compose([])),
      //  memberAddress: new FormControl('', Validators.compose([])),
    });

    this.searchForm = new FormGroup({
      searchValue: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      searchCretiliaValue: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.fetchForm = new FormGroup({
      empNo: new FormControl("", Validators.compose([Validators.required])),
    });

    this.witnesses = [];

    const userDetails = this.authService.getUserDetails();

    if (userDetails.linkId) {
      this.employerNoSearch(userDetails.linkId);
    }
  }

  addWitness() {
    if (this.witnessForm.invalid) {
      this.toastr.error("Invalid form", "Error");
      return;
    }
    this.witnesses.push({
      fullName: this.witnessForm.get("fullname").value,
      phone: this.witnessForm.get("phone").value,
      address: this.witnessForm.get("address").value,
      position: this.witnessForm.get("position").value,
    });
    this.witnessForm.reset();
  }

  onChange1(event: any) {
    if (event.target.files.length > 0) {
      this.fileUpload = event.target.files[0];

      // this.NotificationDiseaseForm.patchValue({
      //   fileSource: file
      // });
      const extension = event.target.files[0].name.split(".").pop();
    }
  }

  onChange(event) {
    const file = event.target.files[0];
    this.fileExtension = event.target.files[0].name.split(".").pop();
    this.fileDescription = event.target.files[0].name.split(".")[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64file = reader.result;
    };
  }

  register() {
    const errorMessages = {
      empNo: "Employer number is required",
      memberNo: "Member number is required",
      diagnosisDate: "Diagnosed date is required",
      diseaseDiagnosed: "Disease Diagnosed is required",
      diseaseReportingDate: "Reporting date is required",
      hospitalName: "Hospitals name is required",
    };

    for (const controlName in this.NotificationDiseaseForm.controls) {
      const control = this.NotificationDiseaseForm.get(controlName);
      if (control.invalid) {
        let errorMessage = errorMessages[controlName];

        // // Handle email validation
        // if (controlName === 'emailAddress' && control.hasError('emailAddress')) {
        //   errorMessage = errorMessages.emailAddress;
        // }

        // // Handle mobile number validation
        // if (controlName === 'telephoneNumber' && control.hasError('telephoneNumber')) {
        //   errorMessage = errorMessages.telephoneNumber;
        // }

        this.toastr.error(errorMessage, "", { timeOut: 6000 });
        return;
      }
    }

    const diagnosisDate =
      new Date(
        this.NotificationDiseaseForm.get("diagnosisDate").value
      ).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(
          this.NotificationDiseaseForm.get("diagnosisDate").value
        ).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(
          this.NotificationDiseaseForm.get("diagnosisDate").value
        ).getDate()
      ).slice(-2);
    //const anticipatedDate = new Date(this.NotificationDiseaseForm.get('AntireturnedDate').value).getFullYear() + "-"+  ("0"+(new Date(this.NotificationDiseaseForm.get('AntireturnedDate').value).getMonth()+1)).slice(-2) + "-" +("0" + new Date(this.NotificationDiseaseForm.get('AntireturnedDate').value).getDate()).slice(-2);
    const diseaseReportingDate =
      new Date(
        this.NotificationDiseaseForm.get("diseaseReportingDate").value
      ).getFullYear() +
      "-" +
      (
        "0" +
        (new Date(
          this.NotificationDiseaseForm.get("diseaseReportingDate").value
        ).getMonth() +
          1)
      ).slice(-2) +
      "-" +
      (
        "0" +
        new Date(
          this.NotificationDiseaseForm.get("diseaseReportingDate").value
        ).getDate()
      ).slice(-2);
    //const DOB = new Date(this.NotificationDiseaseForm.get('DOB').value).getFullYear() + "-"+  ("0"+(new Date(this.NotificationDiseaseForm.get('DOB').value).getMonth()+1)).slice(-2) + "-" +("0" + new Date(this.NotificationDiseaseForm.get('DOB').value).getDate()).slice(-2);
    //const dateLastWorked = new Date(this.NotificationDiseaseForm.get('dateLastWorked').value).getFullYear() + "-"+  ("0"+(new Date(this.NotificationDiseaseForm.get('dateLastWorked').value).getMonth()+1)).slice(-2) + "-" +("0" + new Date(this.NotificationDiseaseForm.get('dateLastWorked').value).getDate()).slice(-2);
    //  if(this.NotificationDiseaseForm.invalid)
    //  {
    //   this.toastr.error("input all required fields");
    //   return;
    //  }

    const reportingDateValue = new Date(
      this.NotificationDiseaseForm.get("diseaseReportingDate").value
    );
    const incDateValue = new Date(
      this.NotificationDiseaseForm.get("diagnosisDate").value
    );

    const differenceInMs = Math.abs(
      incDateValue.getTime() - reportingDateValue.getTime()
    );
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    if (differenceInDays > 7) {
      this.toastr.error(
        "Seven days have passed since the disease happened",
        "",
        { timeOut: 6000 }
      );
      return;
    }

    const data = {
      requestType: "COMPENSATION_CLAIM_EMPLOYER_NOTIFICATION_ONLINE",
      employerNumber: this.NotificationDiseaseForm.get("empNo").value,
      memberNumber: this.NotificationDiseaseForm.get("memberNo").value,
      accidentDateTime: null,
      accidentReportingDate: null,
      bodyInjuries: "",
      accidentStreet: "",
      accidentWard: "",
      accidentShehia: "",
      isAccicdentOnPremise: "",
      accidentPremiseDepartment: "",
      acidentAtivityInvolved: "",
      accidentDescription: "",
      diseaseDiagnosisDate: diagnosisDate,
      diseaseDiagnosed:
        this.NotificationDiseaseForm.get("diseaseDiagnosed").value,
      diseaseReportingDate: diseaseReportingDate,
      diseasePremiseDepartment:
        this.NotificationDiseaseForm.get("diseasePremises").value,
      diseaseDescription:
        this.NotificationDiseaseForm.get("moreDetailsDisease").value,
      hospitalNameOfDiagnosis:
        this.NotificationDiseaseForm.get("hospitalName").value,
      medicalPractionerName:
        this.NotificationDiseaseForm.get("practitionalName").value,
      medicalPractionerPhone:
        this.NotificationDiseaseForm.get("practitionalPhone").value,
      memberRepresentativeName: "",
      memberRepresentativePhone: "",
      memberRepresentativePhysicalAddress: "",
      dateOfDeath: null,
      causeOfDeath: "",
      deathReportingDate: null,
      deathStreet: "",
      deathWard: "",
      deathShehia: null,
      isDeathOnPremise: "",
      deathPremiseDepartment: "",
      deathAtivityInvolved: "",
      deathDesription: "",
      hospitalNameOfDeathComfirmation: "",
      deathComfirmPractionerName: "",
      deathComfirmPractionerPhone: "",
      employerDeclaration: this.declarationValued,
      notificationType: 2,
    };

    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        this.spinner.hide();
        const serverResponse = res.json();
        this.spinner.hide();
        if (serverResponse.code == 2000) {
          this.toastr.success(serverResponse.message);
          this.NotificationDiseaseForm.reset();
          setTimeout(() => {
            const id = serverResponse.data.notificationID;
            this.router.navigate(["notification-info", id]);
          }, 3000);
        } else {
          this.toastr.error(serverResponse.message);
          // this.toastr.error(serverResponse.message, 'Failed to create invoice');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, please try again");
      }
    );
  }

  validateInput(value: any, inputType: string) {
    switch (inputType) {
      case "employerNumber":
        if (value.length < 5) {
          this.employerNumberValid = false;
        } else {
          this.employerNumberValid = true;
        }
    }
  }

  getPDFDataUrl() {
    return "data:application/pdf;base64," + this.fileData;
  }

  isPDF(fileData: string): boolean {
    // Implement the logic to determine if the file is a PDF (e.g., by checking the file extension)
    // Return true if it is a PDF, false otherwise
    return true; // Replace with your logic
  }

  downloadFile(): void {
    const element = document.createElement("a");
    element.href = "data:application/pdf;base64," + this.fileData;
    element.download = "download.pdf"; // Set the appropriate filename and extension
    element.click();
  }

  previewFile(): void {
    const byteCharacters = atob(this.fileData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileBlob = new Blob([byteArray], { type: "application/pdf" });

    this.previewUrl = URL.createObjectURL(fileBlob);

    this.openPreview();
  }

  openPreview(): void {
    const newTab = window.open();
    newTab.document.body.innerHTML = `<embed src="${this.previewUrl}" width="100%" height="100%" type="application/pdf">`;
  }

  filterDistricts(e) {
    this.districtListNew = this.districtList.filter(
      (res) => res.RegionID == e.value
    );
  }

  filterShehia(e) {
    this.shehiaListNew = this.shehiaList.filter(
      (res) => res.DistrictID == e.value
    );
  }

  getDistricts() {
    const data = {
      requestType: "DISTRICT_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.districtList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getRegion() {
    const data = {
      requestType: "REGION_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.regionList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  shehia() {
    const data = {
      requestType: "SHEHIA",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.shehiaList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getSectors() {
    const data = {
      requestType: "SECTOR_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.sectorList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  nationality() {
    const data = {
      requestType: "NATIONALITY",
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.nationalityDataSource = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  declaration(e) {
    const ischecked = (<HTMLInputElement>e.target).checked;

    if (ischecked) {
      this.isSubmitButtonShown = true;
      this.declarationValued = true;
    } else {
      this.isSubmitButtonShown = false;
      this.declarationValued = false;
    }
  }

  searchMember(e) {
    // if(this.typeSelected == false && this.modeSelected == false) {
    //   this.toastr.error("Select application type and mode to continue");
    //   return
    // }
    if (e) {
      this.searchForm.reset();
      this.memberInfo = [];
      //this.memberDatasource = []
      this.activateMemberNameSearch = false;
      this.activateMemberNumberSearch = false;
      this.searchKeySelected = false;
      this.showAlertDialog = true;
    }
  }

  search(e) {
    // const data = {
    //   "requestType": "MEMBER_GET",
    //   "memberNumber": this.searchForm.get("fullName").value,
    // };
    if (this.activateMemberNameSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        membersName: "%" + this.searchForm.get("searchValue").value + "%",
        memberNumber: "",
      };
    }

    if (this.activateMemberNumberSearch) {
      this.data = {
        requestType: "SEARCH_MEMBERS_LIST",
        membersName: "",
        memberNumber: "%" + this.searchForm.get("searchValue").value + "%",
      };
    }

    this.utilities.postServiceCallNew(this.data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.memberInfo = response.data;
          this.activateMemberNameSearch = false;
          this.activateMemberNumberSearch = false;
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  previewDetails(e) {
    const data = {
      requestType: "MEMBER_GET",
      memberNumber: e.data.MemberNumber,
    };

    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.memberDetails = response.data;
          this.showAlertDialog = false;
          // this.names = response.data.FullName
          // this.mothersName = response.data.MothersName
          // //this.gender = response.data.Gender
          // this.physicalAddress = response.data.PhysicalAddress
          // this.postalAddress = response.data.PostalAddress
          // this.memberNumber = response.data.MemberNumber
          // this.lastEmployer = response.data.EmployerName
          // this.employerID = response.data.EmployerID
          // this.DateofBirth = response.data.DateofBirth
          // this.NumberOfContributions = response.data.NumberOfContributions
          // this.lastContributionYear = response.data.LastContributionYear
          // this.lastContributionMonth = response.data.LastContributionMonth
          // this.numOfContributions = response.data.NumberOfContributions
          // this.memberPhoto ='data:image/jpeg;base64,'+response.data.MemberPhoto
          // this.memberSignature = 'data:image/jpeg;base64,'+response.data.MemberSignature
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  searchEmployerPopup(e) {
    if (e) {
      this.searchForm.reset();
      this.employerInfoSearchResult = [];

      this.showAlertDialogEmp = true;
    }
  }

  onInputChange(event: any) {
    const newValue = event.trim();

    // Clear the previous input timer, if any
    clearTimeout(this.inputTimer);

    // Set a new input timer for 3 seconds (3000 milliseconds)
    this.inputTimer = setTimeout(() => {
      this.handleSearch(newValue);
    }, 2000);
  }

  handleSearch(e) {
    // const data = {
    //   "requestType": "MEMBER_GET",
    //   "memberNumber": this.searchForm.get("fullName").value,
    // };

    if (this.employerNameSearch) {
      this.data = {
        requestType: "COMPENSATION_CLAIM_SEARCH_EMPLOYERS",
        employersName: "%" + e + "%",
        employerNumber: "",
      };
    }

    if (this.employerNumberSearch) {
      this.data = {
        requestType: "COMPENSATION_CLAIM_SEARCH_EMPLOYERS",
        employersName: "",
        employerNumber: "%" + e + "%",
      };
    }

    this.utilities.postServiceCallNew(this.data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.employerInfoSearchResult = response.data;

          // this.searchForm.reset()
        } else {
          this.toastr.error(response.message, "Error");
        }
      },
      (error) => {
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  previewDetailsEmployer(e) {
    this.employerNoSearch(e.data.EmployerNumber);
  }

  employerNoSearch(EmployerNumber) {
    const data = {
      requestType: "EMPLOYERS_GET",
      employerNumber: EmployerNumber,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.employerDetails = response.data;
          //this.employerNumber = response.data.EmployerNumber
          this.showAlertDialogEmp = false;
          this.showOtherDetails = true;
          this.getMembers(response.data.EmployerNumber);
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  selectedSearchCretiria(e) {
    if (e.value == 1) {
      this.searchForm.get("searchValue").reset();
      this.employerNumberSearch = true;
    } else {
      this.employerNumberSearch = false;
    }

    if (e.value == 2) {
      this.searchForm.get("searchValue").reset();
      this.employerNameSearch = true;
    } else {
      this.employerNameSearch = false;
    }

    //employerNameSearch
  }

  getMembers(employerNumber: any) {
    const data = {
      requestType: "COMPENSATION_CLAIM_SEARCH_MEMBERS",
      employerNumber: employerNumber,
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.membersList = response.data;
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  checkSelectedValue(e) {
    if (e.value == 1) {
      this.showDepartmentTextbox = true;
    } else {
      this.showDepartmentTextbox = false;
    }
  }

  searchEmployer() {
    const serchedValue = `${this.fetchForm.get("empNo").value}`.trim();

    if (
      serchedValue == null ||
      serchedValue == "" ||
      serchedValue == undefined ||
      serchedValue.length < 1
    ) {
      this.searchForm.reset();
      this.employerInfoSearchResult = [];

      this.showAlertDialogEmp = true;
      return;
    }

    if (this.fetchForm.invalid) {
      this.toastr.error("Please enter member number");
      return;
    }

    this.NotificationDiseaseForm.reset();
    const data = {
      requestType: "EMPLOYERS_GET",
      employerNumber: `${this.fetchForm.get("empNo").value}`.trim(),
    };
    this.spinner.show();
    this.utilities.postServiceCallNew(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.code == 2000) {
          this.employerDetails = response.data;
          //this.employerNumber = response.data.EmployerNumber
          this.showAlertDialogEmp = false;
          this.showOtherDetails = true;
          //this.showSearch = false
          this.getMembers(response.data.EmployerNumber);
        } else {
          this.toastr.error(response.message, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
}
